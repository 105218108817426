import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { FaChartBar, FaUsers, FaCalendarAlt, FaChalkboardTeacher, FaQuestionCircle, FaCog, FaSignOutAlt } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import authService from '../../services/authService';

// Alt bileşenler
import Dashboard from './Dashboard.tsx';
import UserManagement from './UserManagement.tsx';
import ConferenceManagement from './ConferenceManagement.tsx';

// Stil dosyası
import '../styles/AdminDashboard.css';

const AdminPanel: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('overview');
  const [loading, setLoading] = useState(true);

  // Admin erişim kontrolü
  useEffect(() => {
    const checkAdminAccess = async () => {
      const currentUser = authService.getCurrentUser();
      
      // Admin erişim kontrolü - rol bazlı kontrol yapılmalı
      console.log("Kullanıcı rolü:", currentUser?.role);
      if (!currentUser || currentUser.role?.toLowerCase() !== 'admin') {
        console.log("Admin erişimi reddedildi. Kullanıcı rolü:", currentUser?.role);
        navigate('/login'); // Admin değilse login sayfasına yönlendir
        return;
      }
      
      setLoading(false);
    };

    checkAdminAccess();
  }, [navigate]);

  const handleLogout = () => {
    authService.logout();
    navigate('/login');
  };

  // Aktif sekmeye göre içerik gösterme
  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Dashboard />;
      case 'users':
        return <UserManagement />;
      case 'conferences':
        return <ConferenceManagement />;
      case 'sessions':
        return <div>Oturum yönetim modülü yapım aşamasındadır.</div>;
      case 'questions':
        return <div>Soru yönetim modülü yapım aşamasındadır.</div>;
      case 'settings':
        return <div>Sistem ayarları modülü yapım aşamasındadır.</div>;
      default:
        return <Dashboard />;
    }
  };

  return (
    <Container fluid className="admin-dashboard">
      <Row>
        {/* İçerik Alanı - Önce gösterilecek */}
        <Col md={10} className="content-area">
          <div className="content-header">
            <h2>
              {activeTab === 'overview' && 'Genel Bakış'}
              {activeTab === 'users' && 'Kullanıcı Yönetimi'}
              {activeTab === 'conferences' && 'Konferans Yönetimi'}
              {activeTab === 'sessions' && 'Oturum Yönetimi'}
              {activeTab === 'questions' && 'Soru Yönetimi'}
              {activeTab === 'settings' && 'Sistem Ayarları'}
            </h2>
          </div>

          <div className="content-body">
            {renderContent()}
          </div>
        </Col>

        {/* Sağ Menü - Sonra gösterilecek */}
        <Col md={2} className="sidebar">
          <div className="d-flex flex-column align-items-center mt-4 mb-4">
            <h3 className="text-white">Admin Panel</h3>
          </div>
          <Nav className="flex-column">
            <Nav.Link 
              className={activeTab === 'overview' ? 'active' : ''} 
              onClick={() => setActiveTab('overview')}
            >
              <FaChartBar /> İstatistikler
            </Nav.Link>
            <Nav.Link 
              className={activeTab === 'users' ? 'active' : ''} 
              onClick={() => setActiveTab('users')}
            >
              <FaUsers /> Kullanıcılar
            </Nav.Link>
            <Nav.Link 
              className={activeTab === 'conferences' ? 'active' : ''} 
              onClick={() => setActiveTab('conferences')}
            >
              <FaCalendarAlt /> Konferanslar
            </Nav.Link>
            <Nav.Link 
              className={activeTab === 'sessions' ? 'active' : ''} 
              onClick={() => setActiveTab('sessions')}
            >
              <FaChalkboardTeacher /> Oturumlar
            </Nav.Link>
            <Nav.Link 
              className={activeTab === 'questions' ? 'active' : ''} 
              onClick={() => setActiveTab('questions')}
            >
              <FaQuestionCircle /> Sorular
            </Nav.Link>
            <Nav.Link 
              className={activeTab === 'settings' ? 'active' : ''} 
              onClick={() => setActiveTab('settings')}
            >
              <FaCog /> Ayarlar
            </Nav.Link>
            <Nav.Link onClick={handleLogout}>
              <FaSignOutAlt /> Çıkış Yap
            </Nav.Link>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default AdminPanel; 