import api from './api.js';

// Mock veriler - API endpoint'leri hazır olmadığında kullanılacak
const mockData = {
  statistics: {
    totalUsers: 185,
    totalConferences: 42,
    totalSessions: 128,
    totalQuestions: 864,
    activeUsers: 76,
    newUsersThisMonth: 15
  },
  users: [
    { id: 1, name: 'Admin Kullanıcı', email: 'admin@example.com', role: 'admin', createdAt: '2023-05-15' },
    { id: 2, name: 'Ahmet Yılmaz', email: 'ahmet@example.com', role: 'user', createdAt: '2023-06-20' },
    { id: 3, name: 'Ayşe Demir', email: 'ayse@example.com', role: 'user', createdAt: '2023-07-10' }
  ],
  logs: [
    { id: 1, userId: 1, action: 'Giriş yapıldı', timestamp: '2023-08-15T08:30:00Z', ipAddress: '192.168.1.1' },
    { id: 2, userId: 1, action: 'Yeni konferans oluşturuldu', timestamp: '2023-08-15T09:15:00Z', ipAddress: '192.168.1.1' },
    { id: 3, userId: 2, action: 'Profil güncellendi', timestamp: '2023-08-14T14:20:00Z', ipAddress: '192.168.1.2' },
    { id: 4, userId: 3, action: 'Oturuma katılım gerçekleşti', timestamp: '2023-08-14T10:05:00Z', ipAddress: '192.168.1.3' },
    { id: 5, userId: 1, action: 'Konferans ayarları değiştirildi', timestamp: '2023-08-13T16:45:00Z', ipAddress: '192.168.1.1' }
  ],
  settings: {
    siteTitle: 'İnterEdu Konferans Sistemi',
    maintenanceMode: false,
    defaultLanguage: 'tr',
    allowPublicRegistration: true,
    maxUploadSize: 10, // MB
    sessionTimeout: 60 // dakika
  }
};

const dashboardService = {
  // İstatistik verileri
  getStatistics: async () => {
    try {
      console.log('Fetching dashboard statistics');
      
      // Backend'de endpoint mevcut olmayabilir, mock veri kullanıyoruz
      try {
        const response = await api.get('/dashboard/statistics');
        console.log('Received statistics from API:', response.data);
        return response.data;
      } catch (apiError) {
        console.warn('API endpoint for statistics not available, using mock data');
        console.warn('API error details:', apiError.message);
        
        // API endpoint'i yoksa mock veri döndür
        return mockData.statistics;
      }
    } catch (error) {
      console.error('İstatistikler alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Kullanıcı verileri
  getAllUsers: async () => {
    try {
      console.log('Fetching all users for dashboard');
      try {
        // Önce yeni dashboard endpoint'ini dene
        const response = await api.get('/dashboard/users');
        console.log('Received users from dashboard API:', response.data);
        return response.data;
      } catch (dashboardError) {
        console.warn('Dashboard API for users not available, trying alternate endpoint');
        
        try {
          // Alternatif olarak yeni proxy endpoint'i dene
          const response = await api.get('/users/all');
          console.log('Received users from alternate API:', response.data);
          return response.data;
        } catch (proxyError) {
          console.warn('Alternate API for users failed, trying original endpoint');
          
          // Son çare olarak orijinal endpoint'i dene
          try {
            const response = await api.get('/users');
            console.log('Received users from original API:', response.data);
            return response.data;
          } catch (apiError) {
            console.warn('All API endpoints for users failed, using mock data');
            return mockData.users;
          }
        }
      }
    } catch (error) {
      console.error('Kullanıcılar alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Konferans verileri
  getAllConferences: async () => {
    try {
      console.log('Fetching all conferences for dashboard');
      try {
        // Önce yeni dashboard endpoint'ini dene
        const response = await api.get('/dashboard/conferences');
        console.log('Received conferences from dashboard API:', response.data);
        return response.data;
      } catch (dashboardError) {
        console.warn('Dashboard API for conferences not available, trying alternate endpoint');
        
        try {
          // Alternatif olarak yeni proxy endpoint'i dene
          const response = await api.get('/conferences/all');
          console.log('Received conferences from alternate API:', response.data);
          return response.data;
        } catch (proxyError) {
          console.warn('Alternate API for conferences failed, trying original endpoint');
          
          // Son çare olarak orijinal endpoint'i dene
          const response = await api.get('/conferences');
          console.log('Received conferences from original API:', response.data);
          return response.data;
        }
      }
    } catch (error) {
      console.error('Konferanslar alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Oturum verileri
  getAllSessions: async () => {
    try {
      console.log('Fetching all sessions for dashboard');
      try {
        // Önce yeni dashboard endpoint'ini dene
        const response = await api.get('/dashboard/sessions');
        return response.data;
      } catch (dashboardError) {
        console.warn('Dashboard API for sessions not available, trying alternate endpoint');
        
        try {
          // Alternatif olarak yeni proxy endpoint'i dene
          const response = await api.get('/sessions/all');
          return response.data;
        } catch (proxyError) {
          console.warn('Alternate API for sessions failed, trying original endpoint');
          
          // Son çare olarak orijinal endpoint'i dene
          const response = await api.get('/sessions');
          return response.data;
        }
      }
    } catch (error) {
      console.error('Oturumlar alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Soru verileri
  getAllQuestions: async () => {
    try {
      console.log('Fetching all questions for dashboard');
      try {
        // Önce yeni dashboard endpoint'ini dene
        const response = await api.get('/dashboard/questions');
        console.log('Received questions from dashboard API:', response.data);
        return response.data;
      } catch (dashboardError) {
        console.warn('Dashboard API for questions not available, trying alternate endpoint');
        
        try {
          // Alternatif olarak yeni proxy endpoint'i dene
          const response = await api.get('/questions/all');
          console.log('Received questions from alternate API:', response.data);
          return response.data;
        } catch (proxyError) {
          console.warn('Alternate API for questions failed, trying original endpoint');
          
          // Son çare olarak orijinal endpoint'i dene
          const response = await api.get('/questions');
          console.log('Received questions from original API:', response.data);
          return response.data;
        }
      }
    } catch (error) {
      console.error('Sorular alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Konuşmacı verileri
  getAllSpeakers: async () => {
    try {
      const response = await api.get('/speakers');
      return response.data;
    } catch (error) {
      console.error('Konuşmacılar alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Sistem logları
  getSystemLogs: async (limit = 10) => {
    try {
      console.log(`Fetching system logs with limit: ${limit}`);
      
      // Backend'de endpoint mevcut olmayabilir, mock veri kullanıyoruz
      try {
        const response = await api.get(`/logs?limit=${limit}`);
        console.log('Received logs from API:', response.data);
        return response.data;
      } catch (apiError) {
        console.warn('API endpoint for logs not available, using mock data');
        console.warn('API error details:', apiError.message);
        
        // API endpoint'i yoksa, sınırlı sayıda mock log döndür
        return mockData.logs.slice(0, limit);
      }
    } catch (error) {
      console.error('Sistem logları alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Kullanıcı ekleme
  createUser: async (userData) => {
    try {
      const response = await api.post('/users', userData);
      return response.data;
    } catch (error) {
      console.error('Kullanıcı eklenirken hata oluştu:', error);
      throw error;
    }
  },

  // Kullanıcı güncelleme
  updateUser: async (userId, userData) => {
    try {
      const response = await api.put(`/users/${userId}`, userData);
      return response.data;
    } catch (error) {
      console.error('Kullanıcı güncellenirken hata oluştu:', error);
      throw error;
    }
  },

  // Kullanıcı silme
  deleteUser: async (userId) => {
    try {
      const response = await api.delete(`/users/${userId}`);
      return response.data;
    } catch (error) {
      console.error('Kullanıcı silinirken hata oluştu:', error);
      throw error;
    }
  },

  // Sistem ayarlarını alma
  getSystemSettings: async () => {
    try {
      try {
        const response = await api.get('/settings');
        return response.data;
      } catch (apiError) {
        console.warn('API endpoint for settings not available, using mock data');
        return mockData.settings;
      }
    } catch (error) {
      console.error('Sistem ayarları alınırken hata oluştu:', error);
      throw error;
    }
  },

  // Sistem ayarlarını güncelleme
  updateSystemSettings: async (settings) => {
    try {
      const response = await api.put('/settings', settings);
      return response.data;
    } catch (error) {
      console.error('Sistem ayarları güncellenirken hata oluştu:', error);
      throw error;
    }
  }
};

export default dashboardService; 