import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import authService from '../services/authService';

const EmailVerificationPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [verificationStatus, setVerificationStatus] = useState({
    loading: true,
    success: false,
    message: 'E-posta adresiniz doğrulanıyor...'
  });

  useEffect(() => {
    const verifyEmail = async () => {
      try {
        console.log('Starting email verification process with token:', token);
        
        // Önce doğrulanma durumunu kontrol etmek için bir istek atalım
        const response = await authService.verifyEmail(token);
        console.log('Verification response received:', response);
        
        // Backend'den olumlu bir yanıt alındı
        setVerificationStatus({
          loading: false,
          success: true,
          message: response.message || 'E-posta adresiniz başarıyla doğrulandı. Artık giriş yapabilirsiniz.'
        });
        
      } catch (error) {
        console.error('Verification error:', error);
        
        // Olası backend mesajını göster veya varsayılan hata mesajını kullan
        setVerificationStatus({
          loading: false,
          success: false,
          message: error.message || 'E-posta doğrulama başarısız oldu. Bağlantı geçersiz veya süresi dolmuş olabilir.'
        });
        
        // Login sayfasındaki kullanıcıya aynı e-posta ile oturum açma işlemi denemesi için bilgi vermek amaçlı
        sessionStorage.setItem('verification_failed', 'true');
        sessionStorage.setItem('verification_email', 'user_email_here'); // İdeal olarak bunu backend'den almalıyız
      }
    };

    if (token) {
      verifyEmail();
    } else {
      setVerificationStatus({
        loading: false,
        success: false,
        message: 'Geçersiz doğrulama bağlantısı. Doğrulama token\'ı bulunamadı.'
      });
    }
  }, [token]);

  // Doğrulama durumunu manuel olarak kontrol etme fonksiyonu
  const checkVerificationStatus = () => {
    // Bu fonksiyon kullanıcının doğrulanma durumunu kontrol etmek için bir istek daha atabilir
    // Örneğin kullanıcı giriş yapmayı deneyebilir
    navigate('/login');
  };

  const containerStyle = {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0E2C5E',
    color: 'white',
    fontFamily: 'Arial, sans-serif'
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    padding: '30px',
    textAlign: 'center',
    maxWidth: '500px',
    width: '90%',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(255, 255, 255, 0.2)'
  };

  const iconStyle = {
    fontSize: '64px',
    margin: '20px 0'
  };

  const buttonStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    border: 'none',
    padding: '12px 25px',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '20px',
    transition: 'background-color 0.3s',
    textDecoration: 'none',
    display: 'inline-block'
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h1>E-posta Doğrulama</h1>
        
        {verificationStatus.loading ? (
          <div>
            <div style={iconStyle}>⏳</div>
            <p>{verificationStatus.message}</p>
          </div>
        ) : verificationStatus.success ? (
          <div>
            <div style={iconStyle}>✅</div>
            <p>{verificationStatus.message}</p>
            <Link to="/login" style={buttonStyle}>Giriş Yap</Link>
          </div>
        ) : (
          <div>
            <div style={iconStyle}>❌</div>
            <p>{verificationStatus.message}</p>
            <p style={{ marginTop: '20px' }}>
              Doğrulama bağlantısının süresi dolmuş olabilir. Yeni bir doğrulama bağlantısı göndermek için:
            </p>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <Link to="/resend-verification" style={buttonStyle}>Yeni Doğrulama Bağlantısı İste</Link>
              <button 
                onClick={checkVerificationStatus} 
                style={{...buttonStyle, backgroundColor: 'rgba(255, 255, 255, 0.1)'}}
              >
                Doğrulama Durumunu Kontrol Et
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationPage; 