import React, { useEffect } from 'react';
import { FaExclamationTriangle, FaTrash, FaTimes } from 'react-icons/fa';
import './styles/DeleteConfirmationModal.css';

interface DeleteConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onConfirm: () => void;
  title?: string;
  message?: string;
  confirmText?: string;
  cancelText?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  show,
  onHide,
  onConfirm,
  title = "Silme Onayı",
  message = "Bu öğeyi silmek istediğinizden emin misiniz? Bu işlem geri alınamaz.",
  confirmText = "Sil",
  cancelText = "İptal"
}) => {
  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        onHide();
      }
    };
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [show, onHide]);

  if (!show) return null;

  const handleConfirm = () => {
    onConfirm();
    onHide();
  };

  return (
    <div className="custom-modal-container">
      <div className="custom-modal-overlay" onClick={onHide}></div>
      <div className="custom-modal-content">
        <div className="custom-modal-header">
          <h3>
            <FaExclamationTriangle className="warning-icon" />
            {title}
          </h3>
          <button className="custom-close-button" onClick={onHide}>×</button>
        </div>
        <div className="custom-modal-body">
          <p>{message}</p>
        </div>
        <div className="custom-modal-footer">
          <button 
            className="custom-cancel-button"
            onClick={onHide}
            type="button"
          >
            <FaTimes className="button-icon" />
            {cancelText}
          </button>
          <button 
            className="custom-delete-button"
            onClick={handleConfirm}
            type="button"
          >
            <FaTrash className="button-icon" />
            {confirmText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal; 