import React, { useState, useMemo, useCallback, useEffect } from "react";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import { useParams } from "react-router-dom";
import useFetchQuestions from "./moderator/hooks/useFetchQuestions";
import useFetchSpeakers from "./moderator/hooks/useFetchSpeakers";
import QuestionCard from "./moderator/components/QuestionCard.tsx";
import EditQuestionModal from "./moderator/components/EditQuestionModal.tsx";
import QuestionTable from "./moderator/components/QuestionTable.tsx";
import { exportToExcel } from "./moderator/utils/exportToExcel";
import { FaDownload, FaQrcode, FaCopy } from "react-icons/fa";
import QRCode from "qrcode.react";
import questionService from "../services/questionService";
import tokenService from "../services/tokenService";
import socketService from "../services/socketService";
import "./styles/moderator.css";
import speakerService from "../services/speakerService";
import sessionService from "../services/sessionService";
import UpdatedQRModal from "./UpdatedQRModal.js";
import DeleteConfirmationModal from "./common/DeleteConfirmationModal.tsx";
import { Socket } from "socket.io-client";

interface Speaker {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
}

const ModeratorPanel: React.FC = () => {
  const [sessionId, setSessionId] = useState<string | null>(null);
  const {
    speakers = [],
    isLoading: speakersLoading,
    error: speakersError,
  } = useFetchSpeakers(sessionId);
  const [isSocketConnected, setIsSocketConnected] = useState(false);
  const [speakerFilter, setSpeakerFilter] = useState<{ value: string; label: string } | null>(null);
  const [participantToken, setParticipantToken] = useState<string | null>(null);
  const [socketError, setSocketError] = useState<string | null>(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const { token } = useParams<{ token: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [viewMode, setViewMode] = useState("card");
  const [moderatorInfo, setModeratorInfo] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [sessionName, setSessionName] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState<number | null>(null);
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    const fetchModeratorInfoAndQuestions = async () => {
      if (!token) {
        setError("Token bulunamadı.");
        return;
      }

      try {
        setIsLoading(true);
        const tokenInfo = await tokenService.validateToken(token);
        if (!tokenInfo.valid || tokenInfo.role !== "moderator") {
          setError("Geçersiz veya yetkisiz token.");
          return;
        }

        const speakerData = await speakerService.getSpeakerInfoById(
          tokenInfo.userId
        );
        setModeratorInfo(speakerData);

        setSessionId(tokenInfo.sessionId);

        const sessionData = await sessionService.getSessionById(
          tokenInfo.sessionId
        );
        setSessionName(sessionData.name);

        const fetchedQuestions = await questionService.getQuestionsBySession(
          tokenInfo.sessionId
        );
        setQuestions(fetchedQuestions);

        const pToken = await tokenService.getParticipantToken(
          tokenInfo.sessionId
        );
        setParticipantToken(pToken);
      } catch (error) {
        console.error("Bilgi alma hatası:", error);
        setError("Oturum bilgileri yüklenirken bir hata oluştu.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchModeratorInfoAndQuestions();

    // Socket bağlantısı ve olay dinleyicileri - SessionId kontrolü ekleyelim
    if (sessionId) {
      console.log(`${sessionId} oturumu için socket bağlantısı kuruluyor...`);
      const socket = socketService.connect(sessionId);

      if (socket) {
        socket.on('newQuestion', (newQuestion) => {
          console.log('Yeni soru alındı (ModeratorPanel):', newQuestion);
          
          // Bütün veriyi seri hale getirip parse ederek derin kopya oluştur
          let safeQuestion;
          try {
            // JSON.stringify ve parse ile derin kopya ve normalizasyon
            safeQuestion = JSON.parse(JSON.stringify(newQuestion));
            
            // speakers içeriğini kontrol et ve konuşmacı bilgilerini zenginleştir
            if (safeQuestion.speakers) {
              // speakers string ise parse et
              if (typeof safeQuestion.speakers === 'string') {
                try {
                  safeQuestion.speakers = JSON.parse(safeQuestion.speakers);
                } catch (e) {
                  console.error('Speakers string parse hatası:', e);
                  safeQuestion.speakers = [];
                }
              }
              
              // Dizi değilse dizi yap
              if (!Array.isArray(safeQuestion.speakers)) {
                if (typeof safeQuestion.speakers === 'object' && safeQuestion.speakers !== null) {
                  safeQuestion.speakers = [safeQuestion.speakers];
                } else {
                  safeQuestion.speakers = [];
                }
              }
              
              // Konuşmacı ID'lerini tam konuşmacı nesnelerine dönüştür
              safeQuestion.speakers = enrichSpeakersData(safeQuestion.speakers, speakers);
            } else {
              safeQuestion.speakers = [];
            }
            
            console.log('İşlenmiş yeni soru:', safeQuestion);
          } catch (e) {
            console.error('Soru verisi işlenirken hata:', e);
            safeQuestion = {
              ...newQuestion,
              speakers: enrichSpeakersData(newQuestion.speakers, speakers)
            };
          }
          
          // Yeni soruyu ekle
          setQuestions(prevQuestions => [...prevQuestions, safeQuestion]);
        });

        socket.on('updateQuestion', (updatedQuestion) => {
          console.log('Soru güncellendi (ModeratorPanel) - Ham veri:', updatedQuestion);
          
          // Derin kopya ve normalizasyon
          let normalizedQuestion;
          try {
            // JSON.stringify ve parse ile derin kopya
            normalizedQuestion = JSON.parse(JSON.stringify(updatedQuestion));
            
            // Temel alanları normalleştir
            normalizedQuestion.id = Number(normalizedQuestion.id);
            normalizedQuestion.text = normalizedQuestion.text || normalizedQuestion.question_text || '';
            normalizedQuestion.asker = normalizedQuestion.asker || normalizedQuestion.participant_name || '';
            normalizedQuestion.status = normalizedQuestion.status || 'Beklemede';
            
            // speakers içeriğini kontrol et
            if (normalizedQuestion.speakers) {
              // speakers string ise parse et
              if (typeof normalizedQuestion.speakers === 'string') {
                try {
                  normalizedQuestion.speakers = JSON.parse(normalizedQuestion.speakers);
                } catch (e) {
                  console.error('Speakers string parse hatası:', e);
                  normalizedQuestion.speakers = [];
                }
              }
              
              // speakers [Object] şeklinde geliyorsa ve içinde prototype olmayan özellikleri varsa
              if (normalizedQuestion.speakers && typeof normalizedQuestion.speakers === 'object' && 
                  !Array.isArray(normalizedQuestion.speakers) && Object.keys(normalizedQuestion.speakers).length > 0) {
                normalizedQuestion.speakers = [normalizedQuestion.speakers];
              }
              
              // Dizi değilse dizi yap
              if (!Array.isArray(normalizedQuestion.speakers)) {
                normalizedQuestion.speakers = [];
              }
              
              // Konuşmacı ID'lerini tam konuşmacı nesnelerine dönüştür
              normalizedQuestion.speakers = enrichSpeakersData(normalizedQuestion.speakers, speakers);
            } else {
              normalizedQuestion.speakers = [];
            }
            
            console.log('Normalize edilmiş soru:', normalizedQuestion);
          } catch (e) {
            console.error('Soru verisi normalleştirilirken hata:', e);
            normalizedQuestion = {
              ...updatedQuestion,
              id: Number(updatedQuestion.id || 0),
              text: updatedQuestion.text || updatedQuestion.question_text || '',
              asker: updatedQuestion.asker || updatedQuestion.participant_name || '',
              status: updatedQuestion.status || 'Beklemede',
              speakers: enrichSpeakersData(updatedQuestion.speakers, speakers)
            };
          }
          
          // speakers dizisi boş geliyorsa, var olan speaker bilgilerini koru
          setQuestions(prevQuestions => {
            return prevQuestions.map(q => {
              if (q.id === normalizedQuestion.id) {
                console.log(`Soru ${q.id} güncelleniyor`);
                
                // Güncellenen soruda speakers dizisi boşsa mevcut speakers dizisini kullan
                const mergedSpeakers = 
                  (normalizedQuestion.speakers.length === 0 && Array.isArray(q.speakers) && q.speakers.length > 0) 
                    ? q.speakers 
                    : normalizedQuestion.speakers;
                
                console.log('Merge edilmiş speakers:', mergedSpeakers);
                
                return { 
                  ...normalizedQuestion, 
                  speakers: mergedSpeakers 
                };
              }
              return q;
            });
          });
        });

        socket.on('deleteQuestion', (deletedQuestionId) => {
          console.log('Soru silindi (ModeratorPanel):', deletedQuestionId);
          setQuestions(prevQuestions => prevQuestions.filter(q => q.id !== deletedQuestionId));
        });

        setIsSocketConnected(true);
        setSocket(socket);

        return () => {
          console.log('Socket event listeners temizleniyor...');
          socket.off('newQuestion');
          socket.off('updateQuestion');
          socket.off('deleteQuestion');
          socketService.disconnect();
        };
      }
    }
  }, [sessionId]);

  // Yeni useEffect, mevcut soruların konuşmacı bilgilerini loglama
  useEffect(() => {
    if (questions.length > 0) {
      console.log('Güncel sorular state:', questions);
      
      // Konuşmacı bilgisi hatalı olan soruları bulalım
      const questionsWithoutSpeakers = questions.filter(q => !q.speakers || q.speakers.length === 0);
      if (questionsWithoutSpeakers.length > 0) {
        console.log('Konuşmacı bilgisi eksik olan sorular:', questionsWithoutSpeakers);
      }
    }
  }, [questions]);

  const handleStatusChange = useCallback(
    async (questionId: number, newStatus: string) => {
      try {
        const updatedQuestion = await questionService.updateQuestionStatus(
          questionId,
          newStatus
        );
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === questionId ? { ...q, status: newStatus } : q
          )
        );
      } catch (error) {
        console.error("Soru durumu güncellenirken hata oluştu:", error);
      }
    },
    [setQuestions]
  );

  const handleDeleteQuestion = useCallback(async (questionId: number) => {
    setQuestionToDelete(questionId);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (!questionToDelete) return;
    
    try {
      await questionService.deleteQuestion(questionToDelete);
      setQuestions((prevQuestions) => prevQuestions.filter((q) => q.id !== questionToDelete));
      setQuestionToDelete(null);
    } catch (error) {
      console.error("Soru silinirken hata oluştu:", error);
      setError("Soru silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, [questionToDelete]);

  const handleEditQuestion = useCallback((question: Question) => {
    setEditingQuestion(question);
  }, []);

  const statusMapping = {
    Beklemede: "Pending",
    Onaylandı: "Approved",
    Reddedildi: "Rejected",
  };

  const handleSaveEdit = useCallback(
    async (editedQuestion: Question) => {
      try {
        const response = await questionService.updateQuestion(
          editedQuestion.id,
          {
            session_id: sessionId,
            question_text: editedQuestion.text,
            participant_name: editedQuestion.asker,
            status:
              statusMapping[editedQuestion.status] || editedQuestion.status,
            speakers: editedQuestion.speakers.map((s) => s.id),
          }
        );
        if (response.status === 200) {
          setQuestions((prevQuestions) =>
            prevQuestions.map((q) =>
              q.id === editedQuestion.id ? editedQuestion : q
            )
          );
          setEditingQuestion(null);
        } else {
          throw new Error("Failed to update question");
        }
      } catch (error) {
        console.error("Error updating question:", error);
        alert("Soru güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
      }
    },
    [sessionId, setQuestions]
  );

  const filteredQuestions = useMemo(() => {
    if (!questions) return [];
    return questions.filter((question) => {
      if (!question) return false;
      
      const questionText = question.text || "";
      
      if (!question.speakers) {
        question.speakers = [];
      }
      
      const speakerMatch =
        !speakerFilter || 
        speakerFilter === null ||
        speakerFilter.value === "all" ||
        (question.speakers &&
          question.speakers.some(
            (s) => s && s.id && speakerFilter && speakerFilter.value && 
            s.id.toString() === speakerFilter.value
          ));

      const statusFilterValid = statusFilter !== undefined && statusFilter !== null;
      const statusMatch = !statusFilterValid || statusFilter === "all" || question.status === statusFilter;
      
      const searchTermValid = searchTerm !== undefined && searchTerm !== null;
      const searchMatch = !searchTermValid || questionText.toLowerCase().includes(searchTerm.toLowerCase());

      return searchMatch && speakerMatch && statusMatch;
    });
  }, [questions, searchTerm, speakerFilter, statusFilter]);

  const handleDownloadExcel = () => {
    exportToExcel(filteredQuestions);
  };

  const speakerOptions = useMemo(() => {
    return [
      { value: "all", label: "Tüm Konuşmacılar" },
      ...speakers.map((speaker) => ({
        value: speaker.id.toString(),
        label: speaker.name,
      })),
    ];
  }, [speakers]);

  const participantLink = `${window.location.origin}/s/${participantToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(participantLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  // Konuşmacı ID'leri ile konuşmacı nesnelerini eşleştiren yardımcı fonksiyon ekliyorum
  const enrichSpeakersData = (speakerIds, speakersList) => {
    console.log('Konuşmacı ID\'leri:', speakerIds);
    console.log('Mevcut konuşmacı listesi:', speakersList);
    
    if (!speakerIds || !Array.isArray(speakerIds) || speakerIds.length === 0) {
      console.log('Boş konuşmacı dizisi. Boş dizi döndürülüyor.');
      return [];
    }

    if (!speakersList || !Array.isArray(speakersList) || speakersList.length === 0) {
      console.log('Konuşmacı listesi boş. Varsayılan isimler oluşturuluyor.');
      return speakerIds.map(id => {
        const speakerId = typeof id === 'object' && id !== null ? id.id : id;
        return { id: speakerId, name: `Konuşmacı ${speakerId}` };
      });
    }

    // ID'leri tam konuşmacı nesnelerine dönüştür
    return speakerIds.map(id => {
      // ID string olabilir, sayı olabilir veya obje olabilir
      const speakerId = 
        typeof id === 'object' && id !== null 
          ? (id.id || id.speakerId || id.value || id) 
          : id;
      
      // Konuşmacı ID'sini normalize edelim
      const normalizedSpeakerId = String(speakerId).trim();
      
      console.log(`Aranan konuşmacı ID: ${normalizedSpeakerId}, Tip: ${typeof speakerId}`);
      
      // speakers listesinde eşleşen konuşmacıyı bul
      const matchingSpeaker = speakersList.find(s => {
        if (!s) return false;
        
        // ID'leri normalize ederek karşılaştır
        const speakerIdMatch = String(s.id).trim() === normalizedSpeakerId;
        console.log(`Konuşmacı ${s.id} (${s.name}) - Eşleşme: ${speakerIdMatch}`);
        
        return speakerIdMatch;
      });
      
      if (matchingSpeaker) {
        console.log(`${normalizedSpeakerId} ID'li konuşmacı bulundu: ${matchingSpeaker.name}`);
        return {
          id: matchingSpeaker.id,
          name: matchingSpeaker.name,
          email: matchingSpeaker.email || ''
        };
      } else {
        console.log(`${normalizedSpeakerId} ID'li konuşmacı bulunamadı, varsayılan isim oluşturuluyor`);
        // Eşleşen konuşmacı yoksa varsayılan bir isim oluştur
        return {
          id: speakerId,
          name: `Konuşmacı ${speakerId}`
        };
      }
    });
  };

  const openQuestionInNewWindow = (question: Question) => {
    // Implement the logic to open the question in a new window
    console.log("Question clicked:", question);
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <Container
      fluid
      className="speaker-panel justify-content-center min-vh-100"
    >
      <Row>
        {!isSocketConnected && (
          <Alert variant="warning">
            Socket.IO bağlantısı kurulamadı. Gerçek zamanlı güncellemeler
            alınamayabilir.
          </Alert>
        )}
        <Col>
          <Card className="admin-panel conference-card">
            <Card.Header className="p-4">
              <Row className="align-items-center">
                <Col md={4} className="text-center text-md-start mb-3 mb-md-0">
                  <h2 className="font-weight-bold mb-0">
                    {moderatorInfo ? moderatorInfo.name : "Yükleniyor..."}
                  </h2>
                  <h6>(Moderatör)</h6>
                </Col>
                <Col md={4} className="text-center mb-3 mb-md-0">
                  <h3>{sessionName}</h3>
                </Col>
                <Col
                  md={4}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <div className="view-switch ms-2">
                    <button
                      className={viewMode === "table" ? "active" : ""}
                      onClick={() => setViewMode("table")}
                    >
                      Tablo
                    </button>
                    <button
                      className={viewMode === "card" ? "active" : ""}
                      onClick={() => setViewMode("card")}
                    >
                      Kart
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {questions.length === 0 ? (
                <div className="text-center">
                  <h4 className="mb-4">Henüz soru bulunmamaktadır.</h4>
                  <p>Oturum kodunu paylaşarak soru almaya başlayabilirsiniz.</p>
                  <p>(Detaylar için QR Koda tıklayınız.)</p>
                  <div
                    className="d-flex justify-content-center mb-4" 
                    onClick={() => setShowQrModal(true)}
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="d-block d-sm-none">
                      <QRCode value={participantLink} size={200} /> {/* Small screen */}
                    </div>
                    <div className="d-none d-sm-block d-md-none">
                      <QRCode value={participantLink} size={300} /> {/* Medium screen */}
                    </div>
                    <div className="d-none d-md-block">
                      <QRCode value={participantLink} size={400} /> {/* Large screen */}
                    </div>
                  </div>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      value={participantLink}
                      readOnly
                    />
                    <Button variant="outline-secondary" onClick={handleCopy}>
                      <FaCopy /> {copied ? "Kopyalandı!" : "Kopyala"}
                    </Button>
                  </InputGroup>
                </div>
              ) : (
                <>
                  <Row className="mb-3 filter-select__control">
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <InputGroup>
                        <Form.Control
                          placeholder="Soru ara..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <Select
                        options={speakerOptions}
                        value={speakerFilter}
                        onChange={setSpeakerFilter}
                        placeholder="Konuşmacı Filtrele"
                      />
                    </Col>
                    <Col md={4} style={{ marginBottom: "10px" }}>
                      <Form.Control
                        as="select"
                        value={statusFilter}
                        onChange={(e) => setStatusFilter(e.target.value)}
                      >
                        <option value="all">Tüm Durumlar</option>
                        <option value="Beklemede">Beklemede</option>
                        <option value="Onaylandı">Onaylandı</option>
                        <option value="Reddedildi">Reddedildi</option>
                      </Form.Control>
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowQrModal(true)}
                      className="me-2"
                      disabled={!participantToken}
                    >
                      <FaQrcode /> QR Kodu Göster
                    </Button>
                    <Button
                      variant="outline-info"
                      onClick={handleDownloadExcel}
                    >
                      <FaDownload /> Excel'e Aktar
                    </Button>
                  </div>
                  {viewMode === "table" ? (
                    <QuestionTable
                      questions={filteredQuestions}
                      onStatusChange={handleStatusChange}
                      onEdit={handleEditQuestion}
                      isSpeaker={false}
                    />
                  ) : (
                    <Row  xs={1} sm={1} md={2} xl={3} className="g-4">
                      {filteredQuestions.map((question) => (
                        <Col key={question.id} md={6} lg={4}>
                          <QuestionCard
                            question={question}
                            onStatusChange={handleStatusChange}
                            onEdit={handleEditQuestion}
                            onDelete={handleDeleteQuestion}
                            onView={(q) => openQuestionInNewWindow(q)}
                            isSpeaker={false}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {editingQuestion && (
        <EditQuestionModal
          show={!!editingQuestion}
          onHide={() => setEditingQuestion(null)}
          question={editingQuestion}
          onSave={handleSaveEdit}
          speakers={speakers}
          sessionId={sessionId}
          isSpeakerPanel={false}
        />
      )}

      <UpdatedQRModal
        show={showQrModal}
        onHide={() => setShowQrModal(false)}
        participantToken={participantToken}
      />

      <DeleteConfirmationModal
        show={!!questionToDelete}
        onHide={() => setQuestionToDelete(null)}
        onConfirm={confirmDelete}
        title="Soru Silme Onayı"
        message="Bu soruyu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
      />
    </Container>
  );
};

export default ModeratorPanel;