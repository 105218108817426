import React, { useState, useEffect } from 'react';
import { FaEdit, FaTimes, FaSave } from 'react-icons/fa';
import Select from 'react-select';
import '../../styles/moderator.css';
import './styles/EditQuestionModal.css';
import * as COLORS from '../../styles/colors';

interface Speaker {
  id: string;
  name: string;
}

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
  session_id?: string;
}

interface EditQuestionModalProps {
  show: boolean;
  onHide: () => void;
  question: Question;
  onSave: (question: Question) => void;
  speakers: Speaker[];
  sessionId: string;
  isSpeakerPanel: boolean;
}

const EditQuestionModal: React.FC<EditQuestionModalProps> = ({ 
  show, 
  onHide, 
  question, 
  onSave, 
  speakers = [],
  sessionId, 
  isSpeakerPanel 
}) => {
  const initialQuestion = {
    ...question,
    speakers: Array.isArray(question.speakers) ? question.speakers : []
  };
  
  const [editedQuestion, setEditedQuestion] = useState<Question>(initialQuestion);

  useEffect(() => {
    const safeQuestion = {
      ...question,
      speakers: Array.isArray(question.speakers) ? question.speakers : [],
      session_id: sessionId
    };
    setEditedQuestion(safeQuestion);
  }, [question, sessionId]);

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && show) {
        onHide();
      }
    };
    window.addEventListener('keydown', handleEsc);
    
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [show, onHide]);

  const handleSave = () => {
    onSave(editedQuestion);
    onHide();
  };

  const speakerOptions = Array.isArray(speakers) ? speakers.filter(s => s && s.id).map(speaker => ({
    value: String(speaker.id),
    label: speaker.name || 'İsimsiz'
  })) : [];

  const selectedSpeakerValues = Array.isArray(editedQuestion.speakers) ? 
    editedQuestion.speakers.filter(s => s && s.id).map(speaker => ({
      value: String(speaker.id),
      label: speaker.name || 'İsimsiz'
    })) : [];

  if (!show) return null;

  return (
    <div className="custom-modal-container">
      <div className="custom-modal-overlay" onClick={onHide}></div>
      <div className="custom-modal-content question-edit-modal">
        <div className="custom-modal-header">
          <h3>
            <FaEdit className="edit-icon" />
            Soru Düzenle
          </h3>
          <button className="custom-close-button" onClick={onHide}>×</button>
        </div>
        <div className="custom-modal-body">
          <div className="form-group">
            <label htmlFor="question-text">Soru</label>
            <textarea
              id="question-text"
              className="custom-form-control"
              rows={3}
              value={editedQuestion.text || ''}
              onChange={(e) => setEditedQuestion({ ...editedQuestion, text: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="asker-name">Soran</label>
            <input
              type="text"
              id="asker-name"
              className="custom-form-control"
              value={editedQuestion.asker || ''}
              onChange={(e) => setEditedQuestion({ ...editedQuestion, asker: e.target.value })}
            />
          </div>
          {!isSpeakerPanel && (
            <div className="form-group">
              <label htmlFor="speakers-select">Konuşmacı(lar)</label>
              <Select
                id="speakers-select"
                isMulti
                options={speakerOptions}
                value={selectedSpeakerValues}
                onChange={(selectedOptions) => {
                  const newSpeakers = selectedOptions ? selectedOptions.map((option: any) => ({
                    id: option.value,
                    name: option.label
                  })) : [];
                  setEditedQuestion({ ...editedQuestion, speakers: newSpeakers });
                }}
                classNamePrefix="custom-select"
              />
            </div>
          )}
        </div>
        <div className="custom-modal-footer">
          <button 
            className="custom-cancel-button"
            onClick={onHide}
            type="button"
          >
            <FaTimes className="button-icon" />
            İptal
          </button>
          <button 
            className="custom-save-button"
            onClick={handleSave}
            type="button"
          >
            <FaSave className="button-icon" />
            Kaydet
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditQuestionModal;