import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './styles/login.css';
import { FaGoogle } from "react-icons/fa";
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import authService from '../services/authService.js';
import { jwtDecode } from 'jwt-decode';
import { Link } from 'react-router-dom';
import { GOOGLE_CLIENT_ID } from './moderator/utils/goglcli';

interface LoginComponentProps {
  onLogin: (userData: any) => void;
}

// SVG logoyu inline olarak kullanan bileşen
const Logo: React.FC = () => (
  <div className="L1logo-container">
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg" className="L1logo">
      <path d="M24.9386 28.0416C19.1925 33.6002 15.2597 40.2473 13.1329 47.2961C15.7281 47.7435 18.2137 48.9293 20.2191 50.8691C25.4321 55.912 25.4321 64.0883 20.2191 69.1311L29.6582 60.0001L34.3777 55.4346L43.8169 46.3036L53.256 37.1726L57.9755 32.6071L67.4147 23.4761C69.42 21.5363 70.6458 19.1318 71.1083 16.6213C62.5443 14.2032 53.4067 14.2032 44.8428 16.6213C37.5562 18.6787 30.6847 22.4831 24.9386 28.0416Z" fill="#7ED957"/>
      <path d="M62.6949 82.8271L72.1341 91.9581C77.347 97.001 85.7993 97.001 91.0123 91.9581C96.7585 86.3996 100.691 79.7525 102.818 72.7037C105.318 64.4193 105.318 55.58 102.818 47.2956C100.223 47.743 97.7372 48.9288 95.7319 50.8687L86.2927 59.9997L81.5732 64.5652L72.1341 73.6961L62.6949 82.8271Z" fill="#7ED957"/>
      <path d="M48.5362 50.869C43.3233 55.9118 43.3233 64.0882 48.5362 69.131L57.9753 78.262L67.4145 69.131L76.8536 60L81.5732 55.4345L91.0123 46.3035C96.2253 41.2607 96.2253 33.0843 91.0123 28.0415C85.7993 22.9987 77.347 22.9987 72.134 28.0415L62.6949 37.1725L57.9753 41.738L48.5362 50.869Z" fill="#7ED957"/>
      <path d="M24.9385 73.6962C19.7255 78.739 19.7255 86.9153 24.9385 91.9581C30.6847 97.5167 37.556 101.321 44.8427 103.378C53.4067 105.797 62.5442 105.797 71.1082 103.378C70.6456 100.868 69.4199 98.4635 67.4146 96.5236L57.9754 87.3927L53.2559 82.8272L43.8168 73.6962C38.6038 68.6533 30.1515 68.6533 24.9385 73.6962Z" fill="#0097B2"/>
      <path d="M13.1327 47.296C10.633 55.5804 10.633 64.4196 13.1327 72.704C15.7279 72.2566 18.2135 71.0709 20.2189 69.131C25.4319 64.0881 25.4319 55.9119 20.2189 50.869C18.2135 48.9291 15.7279 47.7434 13.1327 47.296Z" fill="#0097B2"/>
    </svg>
  </div>
);

// Mesaj Modal bileşeni
interface MessageModalProps {
  isOpen: boolean;
  onClose: () => void;
  message: string;
  type: 'success' | 'error';
  needsVerification?: boolean;
  verificationEmail?: string;
  onResendVerification?: () => void;
}

const MessageModal: React.FC<MessageModalProps> = ({ 
  isOpen, 
  onClose, 
  message, 
  type,
  needsVerification,
  onResendVerification 
}) => {
  // Modal dışına tıklandığında kapat
  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if ((e.target as HTMLDivElement).className.includes('L1modal-overlay')) {
      onClose();
    }
  };

  return (
    <div 
      className={`L1modal-overlay ${isOpen ? 'L1show' : ''}`} 
      onClick={handleOutsideClick}
    >
      <div className={`L1message-modal ${type === 'success' ? 'L1success-modal' : 'L1error-modal'}`}>
        <span className={`L1modal-close ${type === 'success' ? 'L1success-close' : ''}`} onClick={onClose}>×</span>
        
        <div className="L1modal-header">
          <h3>{type === 'success' ? 'Başarılı' : 'Bilgi'}</h3>
        </div>
        
        <div className="L1modal-body">
          <p>{message}</p>
        </div>
        
        <div className="L1modal-footer">
          <button 
            type="button" 
            className={`L1rounded-button ${type === 'success' ? 'L1success-button' : ''}`}
            onClick={onClose}
            style={{ maxWidth: '150px', margin: '0 auto' }}
          >
            Tamam
          </button>
          
          {needsVerification && onResendVerification && (
            <div className="L1resend-verification">
              <p className="L1small-text">E-posta doğrulama bağlantısı almadınız mı? <a href="#" className="L1resend-link" onClick={(e) => {
                e.preventDefault();
                onResendVerification();
              }}>Yeniden gönder</a></p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const LoginComponent: React.FC<LoginComponentProps> = ({ onLogin }) => {
  const [isActive, setIsActive] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState<'success' | 'error'>('error');
  const [rememberMe, setRememberMe] = useState(false);
  const [needsVerification, setNeedsVerification] = useState(false);
  const [verificationEmail, setVerificationEmail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tab = searchParams.get('tab');
    const action = searchParams.get('action');
    
    if (tab === 'register') {
      setIsActive(true);
    }
    
    if (action === 'forgotPassword') {
      setIsForgotPassword(true);
    }
  }, [location]);

  useEffect(() => {
    setMessage('');
    setNeedsVerification(false);
    setShowModal(false);
  }, [isActive, isForgotPassword]);

  // Hata/başarı mesajı durumunda modal'ı göster
  useEffect(() => {
    if (message) {
      setShowModal(true);
    }
  }, [message, needsVerification]);

  const toggleForm = () => {
    setIsActive(!isActive);
    setIsForgotPassword(false);
    setMessage('');
    setNeedsVerification(false);
    setEmail('');
    setPassword('');
    setName('');
  };

  const showForgotPassword = () => {
    setIsActive(false);
    setIsForgotPassword(true);
    setMessage('');
  };

  const backToLogin = () => {
    setIsForgotPassword(false);
    setIsActive(false);
    setMessage('');
  };

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setMessageType('error');
    setNeedsVerification(false);
    
    if (!email || !password) {
      setMessage('Email and password are required');
      return;
    }
    
    try {
      setIsLoggingIn(true);
      const result = await authService.login(email, password, rememberMe);
      console.log('Login successful:', result);
      onLogin(result);
    } catch (error: any) {
      console.error('Login error:', error);
      
      if (error.response && error.response.data && error.response.data.needsVerification) {
        setNeedsVerification(true);
        setVerificationEmail(error.response.data.email || email);
        setMessage('Lütfen hesabınızı doğrulamak için e-posta adresinizi kontrol edin.');
      } else if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred');
      }
    } finally {
      setIsLoggingIn(false);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    setMessage('');
    setMessageType('error');
    
    if (!name || !email || !password) {
      setMessage('All fields are required');
      return;
    }
    
    try {
      setIsRegistering(true);
      const result = await authService.register(name, email, password);
      setNeedsVerification(true);
      setVerificationEmail(email);
      setMessageType('success');
      setMessage('Kayıt başarılı. Lütfen e-posta adresinize gönderilen doğrulama bağlantısına tıklayarak hesabınızı aktifleştirin.');
    } catch (error) {
      setMessageType('error');
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('An unexpected error occurred');
      }
    } finally {
      setIsRegistering(false);
    }
  };

  const handleForgotPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      setMessageType('error');
      setMessage('Lütfen e-posta adresinizi girin.');
      return;
    }
    
    try {
      setIsResettingPassword(true);
      console.log('Şifre sıfırlama isteği gönderiliyor, e-posta:', email);
      await authService.forgotPassword(email);
      console.log('Şifre sıfırlama isteği başarıyla gönderildi');
      
      // Başarı mesajı göster - artık alert() yerine modal kullanıyoruz
      setMessageType('success');
      setMessage(`Şifre sıfırlama bağlantısı ${email} adresine gönderildi. Lütfen e-postanızı kontrol edin.`);
      
      // 3 saniye sonra giriş formuna geri dön
      setTimeout(() => {
        setIsForgotPassword(false);
        setShowModal(false);
      }, 3000);
      
    } catch (error) {
      console.error('Şifre sıfırlama hatası:', error);
      setMessageType('error');
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('Şifre sıfırlama işlemi başarısız oldu. Lütfen e-posta adresinizi kontrol edin.');
      }
    } finally {
      setIsResettingPassword(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      await authService.resendVerificationEmail(verificationEmail);
      setMessageType('success');
      setMessage('Doğrulama e-postası yeniden gönderildi. Lütfen gelen kutunuzu kontrol edin.');
    } catch (error) {
      setMessageType('error');
      if (error instanceof Error) {
        setMessage(error.message);
      } else {
        setMessage('Doğrulama e-postası gönderilirken bir hata oluştu.');
      }
    }
  };

  const handleGoogleLogin = async (credentialResponse: any) => {
    try {
      const result = await authService.googleLogin(credentialResponse.credential);
      onLogin(result);
    } catch (error) {
      console.error('Google login failed', error);
      setMessageType('error');
      setMessage('Google ile giriş başarısız oldu.');
    }
  };

  const handleGoogleRegister = async (credentialResponse: any) => {
    try {
      const result = await authService.googleRegister(credentialResponse.credential);
      onLogin(result); // Kayıt başarılı olursa kullanıcıyı direkt giriş yaptırıyoruz
    } catch (error) {
      console.error('Google register failed', error);
      setMessageType('error');
      setMessage('Google ile kayıt başarısız oldu.');
    }
  };

  const handleRememberMe = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  }, []);

  const clearMessage = () => {
    setMessage('');
    setShowModal(false);
  };

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <section className="L1section">
        <div className={`L1container ${isActive ? 'L1active' : ''} ${isForgotPassword ? 'L1forgotPassword' : ''}`}>
          <div className="L1user L1signinBox">
            <div className="L1imgBox L1imgBox3">
              <Logo />
            </div>
            <div className="L1formBox">
              <form onSubmit={handleLogin}>
                <h2>GİRİŞ YAP</h2>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Login Failed');
                    setMessageType('error');
                    setMessage('Google ile giriş başarısız oldu.');
                  }}
                />
                <div className="L1sign-in-separator">veya E-posta ile Giriş Yap</div>
                <input type="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Şifre" required value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className="L1remember-forgot">
                  <label className="L1remember-me">
                    <input 
                      type="checkbox" 
                      checked={rememberMe}
                      onChange={handleRememberMe}
                    /> 
                    Beni hatırla
                  </label>
                  <a href="#" className="L1forgot-password" onClick={(e) => {
                    e.preventDefault();
                    showForgotPassword();
                  }}>
                    Şifrenizi mi unuttunuz?
                  </a>
                </div>
                <button className="L1rounded-button" type="submit" disabled={isLoggingIn}>
                  {isLoggingIn ? (
                    <>
                      <span className="L1spinner"></span>
                      <span>Giriş Yapılıyor...</span>
                    </>
                  ) : 'Giriş Yap'}
                </button>
                
                <p className="L1signup">
                  Hesabınız yok mu? <a href="#" onClick={(e) => { e.preventDefault(); toggleForm(); }}>Kayıt Ol.</a>
                </p>
              </form>
            </div>
          </div>

          <div className="L1user L1signupBox">
            <div className="L1formBox">
              <form onSubmit={handleRegister}>
                <h2>Hesap Oluştur</h2>
                <GoogleLogin
                  onSuccess={handleGoogleLogin}
                  onError={() => {
                    console.log('Register Failed');
                    setMessageType('error');
                    setMessage('Google ile kayıt başarısız oldu.');
                  }}
                />
                <div className="L1sign-in-separator">veya E-posta ile Kayıt Ol</div>
                <input type="text" placeholder="Ad Soyad" required value={name} onChange={(e) => setName(e.target.value)} />
                <input type="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <input type="password" placeholder="Şifre" required value={password} onChange={(e) => setPassword(e.target.value)} />
                <button className="L1rounded-button" type="submit" disabled={isRegistering}>
                  {isRegistering ? (
                    <>
                      <span className="L1spinner"></span>
                      <span>Kayıt Yapılıyor...</span>
                    </>
                  ) : 'Kayıt Ol'}
                </button>
                <p className="L1signup">
                  ZATEN BİR HESABINIZ VAR MI? <a href="#" onClick={(e) => { e.preventDefault(); toggleForm(); }}>GİRİŞ YAP.</a>
                </p>
              </form>
            </div>
            <div className="L1imgBox L1imgBox1">
              <Logo />
            </div>
          </div>
          <div className="L1user L1forgotPasswordBox">
            <div className="L1formBox">
              <form onSubmit={handleForgotPassword}>
                <h2>ŞİFREMİ UNUTTUM</h2>
                <p style={{color:"#555"}}>E-posta adresinizi girin ve size şifrenizi sıfırlamanız için bir bağlantı göndereceğiz.</p>
                <input type="email" name="email" placeholder="E-posta" required value={email} onChange={(e) => setEmail(e.target.value)} />
                <button className="L1rounded-button" type="submit" disabled={isResettingPassword}>
                  {isResettingPassword ? (
                    <>
                      <span className="L1spinner"></span>
                      <span>Gönderiliyor...</span>
                    </>
                  ) : 'Sıfırlama Bağantısı Gönder'}
                </button>
                <p className="L1signup">
                 ŞİFRENİZİ HATIRLADINIZ MI? <a href="#" onClick={backToLogin}>GİRİŞ'E DÖN</a>
                </p>
              </form>
            </div>
            <div className="L1imgBox L1imgBox2">
              <Logo />
            </div>
          </div>
        </div>

        {/* Mesaj Modal */}
        <MessageModal 
          isOpen={showModal} 
          onClose={clearMessage}
          message={message}
          type={messageType}
          needsVerification={needsVerification}
          verificationEmail={verificationEmail}
          onResendVerification={handleResendVerification}
        />
      </section>
    </GoogleOAuthProvider>
  );
};

export default LoginComponent;