import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FaMicrophone, FaHandPaper, FaTrash, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { openQuestionInNewWindow } from '../utils/SingleQuestionDisplay';
import * as COLORS from '../../styles/colors';

interface Speaker {
  id: string;
  name: string;
}

interface Question {
  id: number | string;
  text: string;
  asker: string;
  is_answered?: number;
  wantsToAsk?: boolean;
  onlyTakeFloor?: boolean;
  speakers?: Speaker[];
}

interface QuestionCardSpeakerProps {
  question: Question;
  onStatusChange: (questionId: number | string, newStatus: string) => void;
  onEdit: (question: Question) => void;
  onView: (question: Question) => void;
  onDelete: (questionId: number | string) => void;
  handleIsAnsweredChange: (questionId: number | string, newStatus: number) => Promise<void>;
}

const QuestionCardSpeaker: React.FC<QuestionCardSpeakerProps> = ({ question, onStatusChange, onEdit, onView, onDelete, handleIsAnsweredChange }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [swipeProgress, setSwipeProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [currentX, setCurrentX] = useState(0);
  const [isIOS, setIsIOS] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  // is_answered durumunu doğrudan question prop'undan alıyoruz
  const [localIsAnswered, setLocalIsAnswered] = useState(question?.is_answered ?? 0);

  // iOS cihaz tespiti
  useEffect(() => {
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                       (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(isIOSDevice);
  }, []);

  // question prop'u değiştiğinde localIsAnswered'ı güncelliyoruz
  useEffect(() => {
    console.log('Question updated:', question); // Debugging için
    if (question && question.is_answered !== undefined) {
      setLocalIsAnswered(question.is_answered);
    } else {
      setLocalIsAnswered(0); // Varsayılan olarak beklemede (0) durumuna ayarla
    }
  }, [question]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsFlipped(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getStatusColor = (is_answered: number) => {
    switch (is_answered) {
      case 1:
        return '#10B981'; // Cevaplandı
      case 2:
        return '#EF4444'; // Reddedildi
      default:
        return '#F59E0B'; // Beklemede
    }
  };

  const getStatusText = (is_answered: number) => {
    switch (is_answered) {
      case 1:
        return 'Okundu';
      case 2:
        return 'Yoksayıldı';
      default:
        return 'Beklemede';
    }
  };

  const getStatusShadow = (is_answered: number) => {
    const color = getStatusColor(is_answered);
    return `0 4px 20px ${color}40, 0 7px 10px -5px ${color}33`;
  };

  const truncateText = (text: string | undefined, maxLength: number) => {
    if (!text) return 'Soru metni yok';
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(question.id);
  };

  const handleStatusChange = async (newStatus: number) => {
    try {
      await handleIsAnsweredChange(question.id, newStatus);
      setLocalIsAnswered(newStatus);
      console.log('Status updated:', newStatus); // Debugging için
    } catch (error) {
      console.error('Soru durumu güncellenirken hata oluştu:', error);
      // Hata durumunda kullanıcıya bilgi verebilirsiniz
      
    }
  };

  // Debugging için
  useEffect(() => {
    console.log('LocalIsAnswered:', localIsAnswered);
  }, [localIsAnswered]);

  const getCardFaceBackground = () => {
    if (isIOS) {
      // iOS için alternatif arka plan
      return {
        background: `linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))`,
        boxShadow: `0 4px 20px rgba(0, 0, 0, 0.25), 0 7px 10px -5px rgba(0, 0, 0, 0.2)`,
        border: '1px solid rgba(255, 255, 255, 0.2)',
      };
    }
    
    return {
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)', // Safari için
      backgroundImage: 'radial-gradient(circle at top right, rgba(255, 255, 255, 0.2) 0%, transparent 70%)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      boxShadow: getStatusShadow(localIsAnswered),
    };
  };

  const getCardBackBackground = () => {
    if (isIOS) {
      // iOS için alternatif arka plan
      return {
        background: `linear-gradient(180deg, rgba(60, 60, 80, 0.8), rgba(40, 40, 60, 0.8))`,
        boxShadow: `0 4px 20px rgba(0, 0, 0, 0.25), 0 7px 10px -5px rgba(0, 0, 0, 0.2)`,
        border: '1px solid rgba(255, 255, 255, 0.2)',
      };
    }
    
    return {
      backdropFilter: 'blur(30px)',
      WebkitBackdropFilter: 'blur(30px)', // Safari için
      background: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent)',
    };
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '700px',
    height: '550px',
    perspective: '1000px',
    margin: '0 auto',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
    minWidth: '280px',
  } as React.CSSProperties;

  // Kart iç stil hesaplaması - iOS için özel
  const getCardInnerStyle = () => {
    // iOS için düz geçiş efekti
    if (isIOS) {
      return {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'opacity 0.5s ease',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme efekti
    return {
      position: 'relative',
      width: '100%',
      height: '100%',
      transition: 'transform 0.8s',
      transformStyle: 'preserve-3d',
      transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
    } as React.CSSProperties;
  };

  // Ön yüz stil hesaplaması - iOS için özel
  const getCardFaceStyle = () => {
    const baseStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      padding: '30px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: 'white',
      ...getCardFaceBackground(),
    } as React.CSSProperties;

    // iOS için düz geçiş efekti - opacity ile
    if (isIOS) {
      return {
        ...baseStyle,
        backfaceVisibility: 'visible',
        opacity: isFlipped ? 0 : 1,
        zIndex: isFlipped ? 0 : 1,
        transition: 'opacity 0.5s ease, z-index 0.5s',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme
    return {
      ...baseStyle,
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      transform: 'rotateY(0deg)',
    } as React.CSSProperties;
  };

  // Arka yüz stil hesaplaması - iOS için özel
  const getCardBackStyle = () => {
    const backBase = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '20px', 
      padding: '30px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: 'white',
      overflowY: 'auto',
      ...getCardBackBackground(),
    } as React.CSSProperties;

    // iOS için düz geçiş efekti - opacity ile
    if (isIOS) {
      return {
        ...backBase,
        backfaceVisibility: 'visible',
        opacity: isFlipped ? 1 : 0,
        zIndex: isFlipped ? 1 : 0,
        transition: 'opacity 0.5s ease, z-index 0.5s',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme
    return {
      ...backBase,
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      transform: 'rotateY(180deg)',
    } as React.CSSProperties;
  };

  const baseButtonStyle = {
    padding: '15px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontWeight: '600',
    fontSize: '1rem',
    color: '#E5E7EB',
  } as React.CSSProperties;
  
  const buttonStyle = {
    ...baseButtonStyle,
    backgroundColor: 'rgba(59, 130, 246, 0.8)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    ...(isIOS ? { background: 'rgba(59, 130, 246, 0.9)' } : { backdropFilter: 'blur(5px)' }),
  } as React.CSSProperties;

  const buttonStyle2 = {
    ...baseButtonStyle,
    backgroundColor: isIOS ? 'rgba(50, 50, 70, 0.9)' : 'rgba(255, 255, 255, 0.2)',
    ...(isIOS ? {} : { backdropFilter: 'blur(35px)' }),
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties;

  const statusStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '8px 15px',
    borderRadius: '20px',
    fontSize: '0.9rem',
    fontWeight: '600',
    color: '#cdd6eb',
    background: getStatusColor(localIsAnswered),
  } as React.CSSProperties;

  const progressBarStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '5px',
    width: '98%',
    height: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
    overflow: 'hidden',
    opacity: isDragging ? 1 : 0,
    transition: 'opacity 0.3s ease',
  } as React.CSSProperties;

  const progressStyle = {
    height: '100%',
    width: `${Math.abs(swipeProgress)}%`,
    backgroundColor: swipeProgress > 0 ? '#10B981' : '#EF4444',
    transition: 'width 0.1s ease-out',
  } as React.CSSProperties;

  const switchContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    marginBottom: '5px',
  } as React.CSSProperties;

  const sliderStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '240px',
    height: '50px',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    padding: '5px',
    marginTop: '10px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties;

  const sliderThumbStyle = {
    width: '77px',
    height: '40px',
    borderRadius: '30px',
    backgroundColor: getStatusColor(localIsAnswered),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    transform: localIsAnswered === 1 
      ? 'translateX(70px)' 
      : localIsAnswered === 2
        ? 'translateX(-70px)'
        : 'translateX(0)',
    zIndex: 100,
  } as React.CSSProperties;

  const sliderTextStyle = {
    position: 'absolute',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    transition: 'opacity 0.3s ease',
  } as React.CSSProperties;

  const deleteIconStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '1.2rem',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  } as React.CSSProperties;

  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 768); // 768px ve üstünü büyük ekran olarak kabul ediyoruz
    };

    checkScreenSize(); // İlk yükleme kontrolü
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const handleDragStart = useCallback((clientX: number) => {
    setIsDragging(true);
    setStartX(clientX);
    setCurrentX(clientX);
  }, []);

  const handleDragMove = useCallback((clientX: number) => {
    if (isDragging) {
      setCurrentX(clientX);
      const diff = clientX - startX;
      if (cardRef.current) {
        setSwipeProgress(diff / cardRef.current.offsetWidth * 100);
      }
    }
  }, [isDragging, startX]);

  const handleDragEnd = useCallback(() => {
    setIsDragging(false);
    if (Math.abs(swipeProgress) > 40) {
      const newStatus = swipeProgress > 0 ? 1 : 2;
      handleStatusChange(newStatus);
    }
    setSwipeProgress(0);
  }, [swipeProgress, handleStatusChange]);

  const handleTouchStart = useCallback((e: React.TouchEvent) => {
    handleDragStart(e.touches[0].clientX);
  }, [handleDragStart]);

  const handleTouchMove = useCallback((e: React.TouchEvent) => {
    handleDragMove(e.touches[0].clientX);
  }, [handleDragMove]);

  const handleTouchEnd = useCallback(() => {
    handleDragEnd();
  }, [handleDragEnd]);

  const handleMouseDown = useCallback((e: React.MouseEvent) => {
    handleDragStart(e.clientX);
  }, [handleDragStart]);

  const handleMouseMove = useCallback((e: MouseEvent) => {
    handleDragMove(e.clientX);
  }, [handleDragMove]);

  const handleMouseUp = useCallback(() => {
    handleDragEnd();
  }, [handleDragEnd]);

  useEffect(() => {
    if (isDragging) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, handleMouseMove, handleMouseUp]);

  const [showSwipeHint, setShowSwipeHint] = useState(() => {
    // Local storage'dan hint gösterilip gösterilmediğini kontrol et
    return localStorage.getItem('swipeHintShown') !== 'true';
  });

  useEffect(() => {
    if (showSwipeHint) {
      const timer = setTimeout(() => {
        setShowSwipeHint(false);
        // Hint gösterildiğini local storage'a kaydet
        localStorage.setItem('swipeHintShown', 'true');
      }, 5000); // 5 saniye sonra ipucu kaybolur

      return () => clearTimeout(timer);
    }
  }, [showSwipeHint]);

  const swipeHintStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
    textAlign: 'center',
  } as React.CSSProperties;

  const arrowStyle = {
    fontSize: '1.5rem',
    margin: '0 10px',
  } as React.CSSProperties;

  // Söz isteme veya soru olup olmadığını belirle
  const isTakeFloorRequest = question.onlyTakeFloor || (question.text === 'Söz isteme talebi');

  return (
    <motion.div 
      style={cardStyle} 
      ref={cardRef}
      animate={controls}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onClick={() => !isDragging && setIsFlipped(!isFlipped)}
    >
      <div style={getCardInnerStyle()}>
        <div style={getCardFaceStyle()}>
          <div>
            <h3 style={{ margin: '35px 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }} className="truncate">
              {question.asker?.length > 50 ? question.asker.slice(0, 25) + '...' : question.asker}
            </h3>
            {!isTakeFloorRequest && (
              <p style={{ fontSize: '1.1rem', color: '#D1D5DB', maxHeight: '300px', overflow: 'auto', lineHeight: '1.6' }}>
                {truncateText(question.text, 500)}
              </p>
            )}
            {isTakeFloorRequest && (
              <p style={{ fontSize: '2rem', color: '#D1D5DB', textAlign: 'center', marginTop: '80px' }}>
                <FaHandPaper /> Söz İstiyor
              </p>
            )}
          </div>
          {question.wantsToAsk && 
            <FaMicrophone style={{ position: 'absolute', bottom: '100px', right: '-30px', fontSize: '18em', color: '#aaa6a638', zIndex: '-1' }} />
          }
          <div style={statusStyle}>{getStatusText(localIsAnswered)}</div>
          {isLargeScreen && (
            <button 
              style={buttonStyle2}
              onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = '#fff'; (e.currentTarget).style.color = 'black';}} 
              onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = 'rgba(255, 255, 255, 0.2)'; (e.currentTarget).style.color = '#E5E7EB';}}
              onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.BACKGROUND; (e.currentTarget).style.color = COLORS.BUTTON.SECONDARY.TEXT;}}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); openQuestionInNewWindow(question); }}
            >
              Soruyu Görüntüle
            </button>
          )}
          {showSwipeHint && (
            <div style={swipeHintStyle}>
              <FaArrowLeft style={arrowStyle} />
             
              Sağa Sola Sürükleyip Kartın üzerine tıklayabilirsiniz
              <FaArrowRight style={arrowStyle} />
            </div>
          )}
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
        <div style={getCardBackStyle()}>
          <h3 style={{ margin: '0 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }}>Soru Detayları</h3>
          <FaTrash
            style={deleteIconStyle}
            onClick={handleDelete}
            onMouseOver={(e) => e.currentTarget.style.color = '#DC2626'}
            onMouseOut={(e) => e.currentTarget.style.color = 'white'}
          />
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Soru Sahibi:</strong> {question.asker}</p>
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}>
            <strong>Durum:</strong> {getStatusText(localIsAnswered)}
          </p>
          {question.speakers && question.speakers.length > 0 && (
            <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Konuşmacı(lar):</strong> {question.speakers.map(s => s.name).join(', ')}</p>
          )}
          {isTakeFloorRequest && <p style={{ color: '#D1D5DB', fontSize: '1.1rem', fontWeight: 'bold' }}>🙋‍♂️ Söz İsteme Talebi</p>}
          {question.wantsToAsk && <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}>🎤 Soruyu kendisi sormak istiyor</p>}
          <div style={switchContainerStyle}>
            <div style={sliderStyle}>
              <span 
                style={{...sliderTextStyle, left: '20px', opacity: localIsAnswered === 2 ? 1 : 0.5, color: '#EF4444', cursor: 'pointer'} as React.CSSProperties}
                onClick={(e) => {
                  e.stopPropagation();
                  // Yoksay'a tıklandığında durum 2 (Yoksayıldı) olsun
                  handleStatusChange(2);
                }}
              >
                Yoksay
              </span>
              <div 
                style={sliderThumbStyle}
                onClick={(e) => {
                  e.stopPropagation();
                  // Durum kontrolü ve sıradaki duruma geçiş
                  if (localIsAnswered === 0) {
                    handleStatusChange(1); // Beklemede -> Okundu
                  } else if (localIsAnswered === 1) {
                    handleStatusChange(2); // Okundu -> Yoksayıldı
                  } else if (localIsAnswered === 2) {
                    handleStatusChange(1); // Yoksayıldı -> Okundu
                  }
                }}
              >
                <span style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'white' }}>
                  {localIsAnswered === 0 ? '?' : localIsAnswered === 1 ? '✓' : '✗'}
                </span>
              </div>
              <span 
                style={{...sliderTextStyle, right: '20px', opacity: localIsAnswered === 1 ? 1 : 0.5, color: '#10B981', cursor: 'pointer'} as React.CSSProperties}
                onClick={(e) => {
                  e.stopPropagation();
                  // Okundu'ya tıklandığında durum 1 (Okundu) olsun
                  handleStatusChange(1);
                }}
              >
                Okundu
              </span>
            </div>
          </div>
          <button 
            style={{...buttonStyle, marginTop: '20px', backgroundColor: COLORS.BUTTON.PRIMARY.ACTIVE, color: '#E5E7EB'}}
            
            onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.HOVER; (e.currentTarget).style.color = COLORS.BUTTON.PRIMARY.TEXT;}} 
            onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.ACTIVE; (e.currentTarget).style.color = COLORS.BUTTON.PRIMARY.TEXT;}}
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.COLORS?.PRIMARY?.DARKER || '#0b5ed7'; (e.currentTarget).style.color = COLORS.BUTTON?.SECONDARY?.TEXT || 'white';}}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); onEdit(question); }}
          >
            Düzenle
          </button>
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default QuestionCardSpeaker;