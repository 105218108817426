import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import authService from '../services/authService';

const ResendVerificationPage = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
    message: ''
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!email) {
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: 'Lütfen e-posta adresinizi girin.'
      });
      return;
    }

    try {
      setStatus({
        loading: true,
        success: false,
        error: false,
        message: 'Doğrulama e-postası gönderiliyor...'
      });
      
      await authService.resendVerificationEmail(email);
      
      setStatus({
        loading: false,
        success: true,
        error: false,
        message: 'Doğrulama e-postası başarıyla gönderildi. Lütfen gelen kutunuzu kontrol edin.'
      });
    } catch (error) {
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: error.message || 'Doğrulama e-postası gönderilirken bir hata oluştu.'
      });
    }
  };

  const containerStyle = {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0E2C5E',
    color: 'white',
    fontFamily: 'Arial, sans-serif'
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    padding: '30px',
    maxWidth: '500px',
    width: '90%',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(255, 255, 255, 0.2)'
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    marginTop: '10px',
    marginBottom: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '5px',
    color: 'white',
    fontSize: '16px'
  };

  const buttonStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    border: 'none',
    padding: '12px 25px',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
    transition: 'background-color 0.3s',
    width: '100%'
  };

  const linkStyle = {
    color: 'rgba(255, 255, 255, 0.7)',
    textDecoration: 'none',
    marginTop: '20px',
    display: 'inline-block'
  };

  const statusMessageStyle = {
    padding: '10px',
    marginTop: '15px',
    borderRadius: '5px',
    backgroundColor: status.error 
      ? 'rgba(255, 0, 0, 0.2)' 
      : status.success 
        ? 'rgba(0, 255, 0, 0.2)' 
        : 'transparent'
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h1>E-posta Doğrulama Bağlantısı İste</h1>
        <p>Hesabınızı doğrulamak için yeni bir doğrulama bağlantısı göndermek üzere e-posta adresinizi girin.</p>
        
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="email">E-posta Adresi</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={inputStyle}
              placeholder="ornek@email.com"
              disabled={status.loading || status.success}
            />
          </div>
          
          {status.message && (
            <div style={statusMessageStyle}>
              <p>{status.message}</p>
            </div>
          )}
          
          <button 
            type="submit" 
            style={buttonStyle}
            disabled={status.loading || status.success}
          >
            {status.loading ? 'Gönderiliyor...' : 'Doğrulama Bağlantısı Gönder'}
          </button>
        </form>
        
        <div style={{ marginTop: '20px', textAlign: 'center' }}>
          <Link to="/login" style={linkStyle}>Giriş sayfasına dön</Link>
        </div>
      </div>
    </div>
  );
};

export default ResendVerificationPage; 