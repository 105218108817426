import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Button,
  Form,
  InputGroup,
  Container,
  Row,
  Col,
  Spinner,
  Alert,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import {
  FaCheckSquare,
  FaTimesCircle,
  FaEdit,
  FaEye,
  FaMicrophone,
  FaHandPaper,
  FaDownload,
  FaQrcode,
  FaCopy,
} from "react-icons/fa";
import QRCode from "qrcode.react";
import questionService from "../services/questionService";
import socketService from "../services/socketService";
import speakerService from "../services/speakerService";
import tokenService from "../services/tokenService";
import { openQuestionInNewWindow } from "./moderator/utils/SingleQuestionDisplay";
import QuestionCard from "./moderator/components/QuestionCardSpeaker.tsx";
import EditQuestionModal from "./moderator/components/EditQuestionModal.tsx";
import QuestionTable from "./moderator/components/QuestionTable.tsx";
import { exportToExcel } from "./moderator/utils/exportToExcel";
import UpdatedQRModal from "./UpdatedQRModal.js";
import "./styles/moderator.css";
import sessionService from "../services/sessionService";
import DeleteConfirmationModal from "./common/DeleteConfirmationModal.tsx";

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Array<{ id: string; name: string }>;
  is_answered?: number;
}

const SpeakerPanel: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isAnsweredFilter, setIsAnsweredFilter] = useState<string>("all");
  const [editingQuestion, setEditingQuestion] = useState<Question | null>(null);
  const [viewMode, setViewMode] = useState("card");
  const [speakerInfo, setSpeakerInfo] = useState<{
    id: string;
    name: string;
  } | null>(null);
  const [showQrModal, setShowQrModal] = useState(false);
  const [participantToken, setParticipantToken] = useState<string | null>(null);
  const [sessionName, setSessionName] = useState<string>("");
  const [copied, setCopied] = useState(false);
  const [sessionId, setSessionId] = useState<string>(""); // sessionId state'ini tanımla
  const [socket, setSocket] = useState<any>(null);
  const [speakers, setSpeakers] = useState<Array<{ id: string; name: string; email: string; role: string }>>([]);
  const [questionToDelete, setQuestionToDelete] = useState<number | null>(null);

  const handleNewQuestion = useCallback((newQuestion: Question) => {
    console.log('Yeni soru alındı:', newQuestion);
    setQuestions(prevQuestions => {
      const updatedQuestions = [...prevQuestions, newQuestion];
      console.log('Güncellenmiş sorular:', updatedQuestions);
      return updatedQuestions;
    });
  }, []);

  const handleUpdateQuestion = useCallback((updatedQuestion: Question) => {
    console.log('Soru güncellendi:', updatedQuestion);
    setQuestions(prevQuestions => {
      // ID'yi her zaman sayıya dönüştürerek normalize edelim
      const normalizedId = Number(updatedQuestion.id);
      
      // Status değerinin İngilizce olma ihtimaline karşı düzeltme
      const normalizedStatus = 
        updatedQuestion.status === 'Approved' ? 'Onaylandı' : 
        updatedQuestion.status === 'Rejected' ? 'Reddedildi' : 
        updatedQuestion.status === 'Answered' ? 'Cevaplandı' : 
        updatedQuestion.status;
      
      // Konuşmacı bilgilerini normalize et
      const normalizedSpeakers = updatedQuestion.speakers?.map(speaker => ({
        id: speaker.id,
        name: speaker.name || `Konuşmacı ${speaker.id}`
      })) || [];
      
      // Status ve diğer alanların doğru olduğunu garantileyen güncellenmiş soru objesi
      const normalizedQuestion = {
        ...updatedQuestion,
        id: normalizedId,
        status: normalizedStatus,
        speakers: normalizedSpeakers
      };
      
      console.log('Normalized question:', normalizedQuestion);
      
      // Eğer soru reddedildiyse, listeden tamamen kaldır
      if (normalizedQuestion.status === 'Reddedildi') {
        console.log(`Soru ${normalizedId} reddedildi, listeden kaldırılıyor`);
        return prevQuestions.filter(q => Number(q.id) !== normalizedId);
      }
      
      // Sorunun listede olup olmadığını kontrol et - sayısal ID karşılaştırma
      const questionExists = prevQuestions.some(q => Number(q.id) === normalizedId);
      console.log(`Soru ID ${normalizedId} listede var mı: ${questionExists}`);
      
      // Eğer soru "Onaylandı" veya "Cevaplandı" ise ve listede yoksa, listeye ekle
      if ((normalizedQuestion.status === 'Onaylandı' || normalizedQuestion.status === 'Cevaplandı') && !questionExists) {
        console.log(`Soru ${normalizedId} onaylandı ve listeye ekleniyor`);
        return [...prevQuestions, normalizedQuestion];
      }
      
      // Normal durum güncellemesi ise devam et - ID karşılaştırmasını sayısal yap
      const updatedQuestions = prevQuestions.map(q => {
        if (Number(q.id) === normalizedId) {
          // Güncellenmiş soru için tüm alanları koru, eksik olanlar için mevcut değerleri kullan
          const updatedEntry = {
            ...q,
            id: normalizedId,
            text: normalizedQuestion.text !== undefined ? normalizedQuestion.text : q.text,
            asker: normalizedQuestion.asker !== undefined ? normalizedQuestion.asker : q.asker,
            status: normalizedQuestion.status || q.status,
            wantsToAsk: normalizedQuestion.wantsToAsk !== undefined ? normalizedQuestion.wantsToAsk : q.wantsToAsk,
            onlyTakeFloor: normalizedQuestion.onlyTakeFloor !== undefined ? normalizedQuestion.onlyTakeFloor : q.onlyTakeFloor,
            is_answered: normalizedQuestion.is_answered !== undefined ? normalizedQuestion.is_answered : q.is_answered,
            speakers: normalizedSpeakers.length > 0 ? normalizedSpeakers : (q.speakers || [])
          };
          
          console.log('Soru güncellendi:', q.id, '->', updatedEntry.id);
          return updatedEntry;
        }
        return q;
      });
      
      // Eğer soru onaylandı ama listeye eklenmedi ve listeye eklenemedi ise, yeni soru olarak ekleyelim
      const wasUpdated = updatedQuestions.some(q => Number(q.id) === normalizedId);
      if (!wasUpdated && (normalizedQuestion.status === 'Onaylandı' || normalizedQuestion.status === 'Cevaplandı')) {
        console.log(`Soru ${normalizedId} güncellendi ama listede bulunamadı, yeni soru olarak ekleniyor`);
        return [...updatedQuestions, normalizedQuestion];
      }
      
      console.log('Güncellenmiş sorular:', updatedQuestions);
      return updatedQuestions;
    });
  }, []);

  const handleDeleteQuestion = useCallback((deletedQuestionId: number) => {
    setQuestionToDelete(deletedQuestionId);
  }, []);

  const confirmDelete = useCallback(async () => {
    if (!questionToDelete) return;
    
    try {
      await questionService.deleteQuestion(questionToDelete);
      setQuestions(prevQuestions => {
        const updatedQuestions = prevQuestions.filter(q => q.id !== questionToDelete);
        console.log('Güncellenmiş sorular:', updatedQuestions);
        return updatedQuestions;
      });
      setQuestionToDelete(null);
    } catch (error) {
      console.error("Soru silinirken hata oluştu:", error);
      setError("Soru silinirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, [questionToDelete]);

  // Konuşmacı ID'leri ile konuşmacı nesnelerini eşleştiren yardımcı fonksiyon
  const enrichSpeakersData = (speakerIds, speakersList) => {
    console.log('Konuşmacı ID\'leri:', speakerIds);
    console.log('Mevcut konuşmacı listesi:', speakersList);
    
    if (!speakerIds || !Array.isArray(speakerIds) || speakerIds.length === 0) {
      console.log('Boş konuşmacı dizisi. Boş dizi döndürülüyor.');
      return [];
    }

    if (!speakersList || !Array.isArray(speakersList) || speakersList.length === 0) {
      console.log('Konuşmacı listesi boş. Varsayılan isimler oluşturuluyor.');
      return speakerIds.map(id => {
        const speakerId = typeof id === 'object' && id !== null ? id.id : id;
        return { id: speakerId, name: `Konuşmacı ${speakerId}` };
      });
    }

    // ID'leri tam konuşmacı nesnelerine dönüştür
    return speakerIds.map(id => {
      // ID string olabilir, sayı olabilir veya obje olabilir
      const speakerId = 
        typeof id === 'object' && id !== null 
          ? (id.id || id.speakerId || id.value || id) 
          : id;
      
      // Konuşmacı ID'sini normalize edelim
      const normalizedSpeakerId = String(speakerId).trim();
      
      console.log(`Aranan konuşmacı ID: ${normalizedSpeakerId}, Tip: ${typeof speakerId}`);
      
      // speakers listesinde eşleşen konuşmacıyı bul
      const matchingSpeaker = speakersList.find(s => {
        if (!s) return false;
        
        // ID'leri normalize ederek karşılaştır
        const speakerIdMatch = String(s.id).trim() === normalizedSpeakerId;
        console.log(`Konuşmacı ${s.id} (${s.name}) - Eşleşme: ${speakerIdMatch}`);
        
        return speakerIdMatch;
      });
      
      if (matchingSpeaker) {
        console.log(`${normalizedSpeakerId} ID'li konuşmacı bulundu: ${matchingSpeaker.name}`);
        return {
          id: matchingSpeaker.id,
          name: matchingSpeaker.name,
          email: matchingSpeaker.email || ''
        };
      } else {
        console.log(`${normalizedSpeakerId} ID'li konuşmacı bulunamadı, varsayılan isim oluşturuluyor`);
        return {
          id: speakerId,
          name: `Konuşmacı ${speakerId}`
        };
      }
    });
  };

  useEffect(() => {
    let socket: any;

    const fetchQuestionsAndConnectSocket = async () => {
      try {
        const tokenInfo = await tokenService.validateToken(token);
        if (!tokenInfo.valid || tokenInfo.role !== "speaker") {
          setError("Geçersiz veya yetkisiz token.");
          return;
        }

        const speakerData = await speakerService.getSpeakerInfoById(
          tokenInfo.userId
        );
        setSpeakerInfo(speakerData);

        // Tüm konuşmacıları al
        const allSpeakers = await speakerService.getSpeakersBySession(tokenInfo.sessionId);
        setSpeakers(allSpeakers.data || []);

        const fetchedQuestions = await questionService.getQuestionsForSpeaker(
          tokenInfo.sessionId,
          tokenInfo.userId
        );
        setQuestions(fetchedQuestions);

        const pToken = await tokenService.getParticipantToken(
          tokenInfo.sessionId
        );
        setParticipantToken(pToken);

        const sessionData = await sessionService.getSessionById(
          tokenInfo.sessionId
        );
        setSessionName(sessionData.name);
        setSessionId(sessionData.id);

        if (tokenInfo.sessionId) {
          socket = socketService.connect(tokenInfo.sessionId);

          socket.on('connect', () => {
            console.log('Socket bağlantısı başarılı');
          });

          socket.on('newQuestion', (newQuestion) => {
            console.log('Yeni soru alındı:', newQuestion);
            
            // Konuşmacı bilgilerini zenginleştir
            let safeQuestion = JSON.parse(JSON.stringify(newQuestion));
            
            if (safeQuestion.speakers) {
              if (typeof safeQuestion.speakers === 'string') {
                try {
                  safeQuestion.speakers = JSON.parse(safeQuestion.speakers);
                } catch (e) {
                  console.error('Speakers string parse hatası:', e);
                  safeQuestion.speakers = [];
                }
              }
              
              if (!Array.isArray(safeQuestion.speakers)) {
                if (typeof safeQuestion.speakers === 'object' && safeQuestion.speakers !== null) {
                  safeQuestion.speakers = [safeQuestion.speakers];
                } else {
                  safeQuestion.speakers = [];
                }
              }
              
              // Konuşmacı bilgilerini zenginleştir
              safeQuestion.speakers = safeQuestion.speakers.map(speaker => {
                const speakerId = typeof speaker === 'object' ? speaker.id : speaker;
                const matchingSpeaker = allSpeakers.data.find(s => String(s.id) === String(speakerId));
                return {
                  id: speakerId,
                  name: matchingSpeaker ? matchingSpeaker.name : `Konuşmacı ${speakerId}`
                };
              });
            } else {
              safeQuestion.speakers = [];
            }
            
            setQuestions(prevQuestions => [...prevQuestions, safeQuestion]);
          });

          socket.on('updateQuestion', (updatedQuestion) => {
            console.log('Soru güncellendi:', updatedQuestion);
            
            let normalizedQuestion = JSON.parse(JSON.stringify(updatedQuestion));
            
            if (normalizedQuestion.speakers) {
              if (typeof normalizedQuestion.speakers === 'string') {
                try {
                  normalizedQuestion.speakers = JSON.parse(normalizedQuestion.speakers);
                } catch (e) {
                  console.error('Speakers string parse hatası:', e);
                  normalizedQuestion.speakers = [];
                }
              }
              
              if (!Array.isArray(normalizedQuestion.speakers)) {
                if (typeof normalizedQuestion.speakers === 'object' && normalizedQuestion.speakers !== null) {
                  normalizedQuestion.speakers = [normalizedQuestion.speakers];
                } else {
                  normalizedQuestion.speakers = [];
                }
              }
              
              // Konuşmacı bilgilerini zenginleştir
              normalizedQuestion.speakers = normalizedQuestion.speakers.map(speaker => {
                const speakerId = typeof speaker === 'object' ? speaker.id : speaker;
                const matchingSpeaker = allSpeakers.data.find(s => String(s.id) === String(speakerId));
                return {
                  id: speakerId,
                  name: matchingSpeaker ? matchingSpeaker.name : `Konuşmacı ${speakerId}`
                };
              });
            } else {
              normalizedQuestion.speakers = [];
            }
            
            handleUpdateQuestion(normalizedQuestion);
          });

          socket.on('deleteQuestion', handleDeleteQuestion);

          console.log('Socket event listeners set up');
        }
      } catch (error) {
        console.error("Bilgi alma hatası:", error);
        setError("Oturum bilgileri yüklenirken bir hata oluştu.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchQuestionsAndConnectSocket();

    return () => {
      if (socket) {
        console.log('Cleaning up socket listeners');
        socket.off('newQuestion');
        socket.off('updateQuestion');
        socket.off('deleteQuestion');
        socketService.disconnect();
      }
    };
  }, [token, handleNewQuestion, handleUpdateQuestion, handleDeleteQuestion]);

  useEffect(() => {
    console.log('Questions state updated:', questions);
  }, [questions]);

  const handleStatusChange = useCallback(
    async (questionId: number, newStatus: string) => {
      try {
        console.log(
          `Changing status for question ${questionId} to ${newStatus}`
        );
        const updatedQuestion = await questionService.updateQuestionStatus(
          questionId,
          newStatus
        );
        console.log("Updated question:", updatedQuestion);
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === questionId ? { ...q, status: updatedQuestion.status } : q
          )
        );
      } catch (error) {
        console.error("Soru durumu güncellenirken hata oluştu:", error);
        setError(
          "Soru durumu güncellenirken bir hata oluştu. Lütfen tekrar deneyin."
        );
      }
    },
    []
  );

  const handleIsAnsweredChange = useCallback(async (questionId: number, newStatus: number) => {
    try {
      console.log(`Updating is_answered for question ${questionId} to ${newStatus}`);
      const updatedQuestion = await questionService.updateQuestionIsAnswered(questionId, newStatus);
      setQuestions((prevQuestions) =>
        prevQuestions.map((q) =>
          q.id === questionId ? { ...q, is_answered: updatedQuestion.is_answered } : q
        )
      );
    } catch (error) {
      console.error("Soru cevap durumu güncellenirken hata oluştu:", error);
      setError("Soru cevap durumu güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, []);

  const handleEditQuestion = useCallback((question: Question) => {
    setEditingQuestion(question);
  }, []);

  const handleSaveEdit = useCallback(async (editedQuestion: Question) => {
    try {
      const response = await questionService.updateQuestion(editedQuestion.id, {
        session_id: sessionId,
        question_text: editedQuestion.text,
        participant_name: editedQuestion.asker,
        status: editedQuestion.status,
        // speakers bilgisini göndermiyoruz çünkü speaker panelde bu değiştirilmiyor
      });
      
      if (response.status === 200) {
        setQuestions((prevQuestions) =>
          prevQuestions.map((q) =>
            q.id === editedQuestion.id ? {...q, ...editedQuestion} : q
          )
        );
        setEditingQuestion(null);
      } else {
        throw new Error("Failed to update question");
      }
    } catch (error) {
      console.error("Soru güncellenirken hata oluştu:", error);
      setError("Soru güncellenirken bir hata oluştu. Lütfen tekrar deneyin.");
    }
  }, [sessionId, setQuestions, setError]);

  const questionCounts = useMemo(() => {
    const counts = {
      all: questions.length,
      answered: questions.filter(q => q.is_answered === 1).length,
      unanswered: questions.filter(q => q.is_answered === 0).length,
      ignored: questions.filter(q => q.is_answered === 2).length,
    };
    return counts;
  }, [questions]);

  const isAnsweredOptions = [
    { value: "all", label: `Tüm Sorular (${questionCounts.all})` },
    { value: "answered", label: `Okundu (${questionCounts.answered})` },
    { value: "unanswered", label: `Beklemede (${questionCounts.unanswered})` },
    { value: "ignored", label: `Yoksayıldı (${questionCounts.ignored})` },
  ];

  const filteredQuestions = useMemo(() => {
    return questions.filter(
      (question) => {
        // Speakers dizisi için güvenlik kontrolü
        if (!question.speakers) {
          question.speakers = [];
        }
        
        // Onaylandı, Cevaplandı veya Beklemede durumundaki soruları göster
        const validStatus = question.status === 'Onaylandı' || 
                           question.status === 'Cevaplandı' || 
                           question.status === 'Beklemede' ||
                           !question.status; // Hiç status değeri yoksa da göster
                           
        if (!validStatus) {
          return false;
        }
        
        return (question.text?.toLowerCase().includes(searchTerm.toLowerCase()) ?? true) &&
          (isAnsweredFilter === "all" || 
          (isAnsweredFilter === "answered" && question.is_answered === 1) ||
          (isAnsweredFilter === "unanswered" && question.is_answered === 0) ||
          (isAnsweredFilter === "ignored" && question.is_answered === 2));
      }
    );
  }, [questions, searchTerm, isAnsweredFilter]);

  const downloadExcel = () => {
    exportToExcel(filteredQuestions);
  };

  const participantLink = `${window.location.origin}/s/${participantToken}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(participantLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  if (isLoading) {
    return (
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100vh" }}
      >
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </Container>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <Container fluid className="speaker-panel">
      <Row>
        <Col>
          <Card className="admin-panel conference-card">
            <Card.Header className="p-4">
              <Row className="align-items-center">
                <Col md={4} className="text-center text-md-start mb-3 mb-md-0">
                  <h2 className="font-weight-bold mb-0">
                    {speakerInfo ? speakerInfo.name : "Yükleniyor..."}
                  </h2>
                  <h6 className="text-sm text-gray-600">(Konuşmacı)</h6>
                </Col>

                <Col md={4} className="text-center mb-3 mb-md-0">
                  <h3 className="text-xl font-semibold">{sessionName}</h3>
                </Col>

                <Col
                  md={4}
                  className="d-flex justify-content-center justify-content-md-end"
                >
                  <div className="view-switch ms-2">
                    <button
                      className={viewMode === "table" ? "active" : ""}
                      onClick={() => setViewMode("table")}
                    >
                      Tablo
                    </button>
                    <button
                      className={viewMode === "card" ? "active" : ""}
                      onClick={() => setViewMode("card")}
                    >
                      Kart
                    </button>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {questions.length === 0 ? (
                <div className="text-center py-5">
                  <h4 className="mb-4">Henüz soru alınmamış</h4>
                  <p className="mb-4">
                    Oturum kodunu paylaşarak soru almaya başlayabilirsiniz.
                  </p>
                  <p className="mb-2 text-white">
                    (Detaylar için QR Koda tıklayınız.)
                  </p>
                  <div className="d-flex justify-content-center mb-4" 
                    onClick={() => setShowQrModal(true)}
                    style={{ cursor: 'pointer' }}>
                    
                    <div className="d-block d-sm-none">
                      <QRCode value={participantLink} size={200} /> {/* Small screen */}
                    </div>
                    <div className="d-none d-sm-block d-md-none">
                      <QRCode value={participantLink} size={300} /> {/* Medium screen */}
                    </div>
                    <div className="d-none d-md-block">
                      <QRCode value={participantLink} size={400} /> {/* Large screen */}
                    </div>
                  </div>
                  <InputGroup className="mb-3 w-75 mx-auto">
                    <Form.Control
                      type="text"
                      value={participantLink}
                      readOnly
                    />
                    <Button variant="outline-secondary" onClick={handleCopy}>
                      <FaCopy /> {copied ? "Kopyalandı!" : "Kopyala"}
                    </Button>
                  </InputGroup>
                </div>
              ) : (
                <>
                  <Row className="mb-3 filter-select__control">
                    <Col md={6} style={{ marginBottom: "10px" }}>
                      <InputGroup>
                        <Form.Control
                          placeholder="Soru ara..."
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md={6} style={{ marginBottom: "10px" }}>
                      <Select
                        options={isAnsweredOptions}
                        value={isAnsweredOptions.find(
                          (option) => option.value === isAnsweredFilter
                        )}
                        onChange={(selectedOption) =>
                          setIsAnsweredFilter(selectedOption?.value || "all")
                        }
                        placeholder="Duruma Göre Filtrele"
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end mb-3">
                    <Button
                      variant="outline-secondary"
                      onClick={() => setShowQrModal(true)}
                      className="me-2"
                      disabled={!participantToken}
                    >
                      <FaQrcode /> QR Kodu Göster
                    </Button>
                    <Button variant="outline-info" onClick={downloadExcel}>
                      <FaDownload /> Excel'e Aktar
                    </Button>
                  </div>
                  {viewMode === "table" ? (
                    <QuestionTable
                      questions={filteredQuestions}
                      onStatusChange={handleStatusChange}
                      onEdit={handleEditQuestion}
                      isSpeaker={true}
                    />
                  ) : (
                    <Row  xs={1} sm={1} md={2} xl={3} className="g-4">
                      {filteredQuestions.map((question) => (
                        <Col key={question.id} md={6} lg={4}>
                          <QuestionCard
                            question={question}
                            onStatusChange={handleStatusChange}
                            onEdit={handleEditQuestion}
                            onDelete={handleDeleteQuestion}
                            onView={(q) => openQuestionInNewWindow(q)}
                            handleIsAnsweredChange={handleIsAnsweredChange}
                          />
                        </Col>
                      ))}
                    </Row>
                  )}
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {editingQuestion && (
        <EditQuestionModal
          show={!!editingQuestion}
          onHide={() => setEditingQuestion(null)}
          question={editingQuestion}
          onSave={handleSaveEdit}
          speakers={[]} // Boş bir dizi gönderiyoruz
          sessionId={sessionId}
          isSpeakerPanel={true} // Bu prop'u ekliyoruz
        />
      )}

      <UpdatedQRModal
        show={showQrModal}
        onHide={() => setShowQrModal(false)}
        participantToken={participantToken}
      />

      <DeleteConfirmationModal
        show={!!questionToDelete}
        onHide={() => setQuestionToDelete(null)}
        onConfirm={confirmDelete}
        title="Soru Silme Onayı"
        message="Bu soruyu silmek istediğinizden emin misiniz? Bu işlem geri alınamaz."
      />
    </Container>
  );
};

export default SpeakerPanel;