import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Form, Button, Alert, Container, Nav, Badge } from 'react-bootstrap';
import { FaUser, FaEnvelope, FaCheckCircle, FaTimesCircle, FaCalendarAlt, FaLock, FaCog, FaBell, FaEdit, FaChevronRight, FaCrown, FaUsersCog, FaUserTie, FaUserAlt } from 'react-icons/fa';
import authService from '../services/authService';

const Profile: React.FC = () => {
  const [profile, setProfile] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [activeTab, setActiveTab] = useState('info');
  const [isEditing, setIsEditing] = useState(false);
  const [editedName, setEditedName] = useState('');
  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (error) {
      setShowError(true);
    }
  }, [error]);

  useEffect(() => {
    if (successMessage) {
      setShowSuccess(true);
    }
  }, [successMessage]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const token = sessionStorage.getItem('token');
        const userDataString = sessionStorage.getItem('user');
        let user = null;

        try {
          user = userDataString ? JSON.parse(userDataString) : null;
          console.log('User from sessionStorage:', user);
        } catch (error) {
          console.error('Error parsing user data:', error);
        }

        if (!token || !user) {
          setError('Oturum açık değil. Lütfen tekrar giriş yapın.');
          return;
        }

        const userData = await authService.getProfileInfo();
        
        // Kullanıcı rolünü sessionStorage'dan al
        if (user && user.role) {
          // API yanıtında rol yoksa veya boşsa sessionStorage'dan al
          if (!userData.role) {
            userData.role = user.role;
            console.log('Role taken from sessionStorage:', user.role);
          }
        }
        
        setProfile(userData);
        setEditedName(userData.name);
        
        // Konsola profil bilgilerini yazdır
        console.log('Final profile data with role:', userData);
      } catch (error) {
        setError('Profil bilgileri alınamadı. Lütfen tekrar giriş yapın.');
        console.error('Profil bilgileri alınırken hata oluştu:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (newPassword !== confirmPassword) {
      setError('Yeni şifreler eşleşmiyor');
      return;
    }

    try {
      const result = await authService.changePassword(currentPassword, newPassword);
      console.log('Password change result:', result);
      setSuccessMessage('Şifre başarıyla değiştirildi');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
    } catch (err: any) {
      console.error('Password change error:', err);
      setError(err.response?.data?.message || 'Şifre değiştirme işlemi başarısız oldu');
    }
  };

  const handleProfileUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    try {
      const updatedProfile = await authService.updateProfile({ name: editedName });
      setSuccessMessage('Profil başarıyla güncellendi');
      setProfile(prevProfile => ({ ...prevProfile, name: editedName }));
      
      // Güncellenmiş kullanıcı bilgisini sessionStorage'da güncelle
      const user = JSON.parse(sessionStorage.getItem('user') || '{}');
      user.name = editedName;
      sessionStorage.setItem('user', JSON.stringify(user));
    } catch (err: any) {
      setError(err.message || 'Profil güncelleme işlemi başarısız oldu');
    }
  };

  if (!profile) {
    return <div className="text-center mt-5">Profil yükleniyor...</div>;
  }

  const UserInitials = ({ name }: { name: string }) => {
    const initials = name.split(' ').map(n => n[0]).join('').toUpperCase();
    return (
      <div className="user-initials">
        <span>{initials}</span>
      </div>
    );
  };

  const getRoleIcon = (role) => {
    if (!role) return <FaUserTie />;
    
    const roleLower = role.toLowerCase();
    if (roleLower === 'admin') return <FaCrown />;
    if (roleLower === 'participant') return <FaUserAlt />;
    return <FaUserTie />;
  };

  const getRoleName = (role) => {
    if (!role) return "Standart Kullanıcı";
    
    const roleLower = role.toLowerCase();
    if (roleLower === 'admin') return "Yönetici";
    if (roleLower === 'participant') return "Katılımcı";
    if (roleLower === 'user') return "Kullanıcı";
    return role;
  };

  const getRoleBadgeClass = (role) => {
    if (!role) return "role-user";
    
    const roleLower = role.toLowerCase();
    if (roleLower === 'admin') return "role-admin";
    if (roleLower === 'participant') return "role-participant";
    return "role-user";
  };

  return (
    <Container className="profile-container">
      <style>
        {`
          body {
            background: linear-gradient(to bottom right, #1a1a2e, #16213e, #0f3460);
            color: #e0e0e0;
            min-height: 100vh;
          }
          .profile-container {
            max-width: 1200px;
            padding: 2rem;
          }
          .glass-card {
            background: rgba(255, 255, 255, 0.1);
           //backgroundImage: 'radial-gradient(circle at top right, rgba(255, 255, 255, 0.2) 0%, transparent 70%)',
            backdrop-filter: blur(10px);
            border-radius: 15px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            overflow: hidden;
          }
          .profile-sidebar {
            background: linear-gradient(to bottom, #4a0e8f, #3a0ca3);
            padding: 2rem;
          }
          .user-initials {
            width: 128px;
            height: 128px;
            background-color: #fff;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 3rem;
            font-weight: bold;
            color: #4a0e8f;
            margin: 0 auto 1rem;
          }
          .nav-link {
            color: #d8d8d8;
            transition: all 0.3s ease;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;
          }
          .nav-link:hover, .nav-link.active {
            background-color: rgba(255, 255, 255, 0.1);
            color: white;
          }
          .form-control, .form-select {
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #4fd1c5;
            color: white;
            font-size: 1.25rem;
          }
          .form-control:focus, .form-select:focus {
            background-color: transparent;
            color: white;
            box-shadow: none;
            border-color: #4fd1c5;
          }
          .btn-primary {
          background-color: #4fd1c5;
         
            border: none;
         color: #1a1a2e; 
            font-weight: bold;
          }
          .btn-primary:hover {
           background-color: #38b2ac;
           
          }
          .info-card {
            background-color: rgba(255, 255, 255, 0.1);
            padding: 1rem;
            border-radius: 0.5rem;
            margin-bottom: 1rem;
            transition: all 0.3s ease;
            position: relative;
            overflow: hidden;
          }
          .info-card:hover {
            background-color: rgba(255, 255, 255, 0.15);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
            transform: translateY(-2px);
          }
          .info-card-label {
            color: #a0aec0;
            font-weight: 500;
            font-size: 0.85rem;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-bottom: 0.25rem;
          }
          .info-card-value {
            color: white;
            font-size: 1.25rem;
            margin-top: 0.25rem;
            display: flex;
            align-items: center;
            position: relative;
          }
          .edit-button {
            opacity: 0;
            transition: opacity 0.3s ease;
            position: absolute;
            right: 0;
            background: rgba(255, 255, 255, 0.15);
            border: none;
            color: white;
            border-radius: 4px;
            display: flex;
            align-items: center;
            gap: 4px;
            padding: 0.5rem 0.75rem;
          }
          .info-card:hover .edit-button {
            opacity: 1;
          }
          @media (max-width: 768px) {
            .edit-button {
              padding: 0.35rem;
              top: 0.5rem;
              right: 0.5rem;
            }
            .edit-button span {
              display: none;
            }
            .info-card-value {
              padding-right: 2rem;
              flex-wrap: wrap;
              word-break: break-word;
            }
          }
          .user-info-icon {
            width: 32px;
            height: 32px;
            min-width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 0.75rem;
            background: rgba(79, 209, 197, 0.2);
            border-radius: 8px;
            color: #4fd1c5;
          }
          .role-badge {
            display: inline-flex;
            align-items: center;
            gap: 0.5rem;
            padding: 0.35rem 0.75rem;
            border-radius: 50px;
            font-size: 0.85rem;
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            margin-left: auto;
          }
          .role-admin {
            background: linear-gradient(135deg, rgba(233, 30, 99, 0.2), rgba(233, 30, 99, 0.3));
            color: #e91e63;
            border: 1px solid rgba(233, 30, 99, 0.3);
          }
          .role-user {
            background: linear-gradient(135deg, rgba(33, 150, 243, 0.2), rgba(33, 150, 243, 0.3));
            color: #2196f3;
            border: 1px solid rgba(33, 150, 243, 0.3);
          }
          .role-participant {
            background: linear-gradient(135deg, rgba(76, 175, 80, 0.2), rgba(76, 175, 80, 0.3));
            color: #4caf50;
            border: 1px solid rgba(76, 175, 80, 0.3);
          }
        `}
      </style>
      <Row className="glass-card">
        <Col md={4} className="profile-sidebar">
          <UserInitials name={profile.name} />
          <h2 className="text-center mb-4 text-white">{profile.name}</h2>
          <Nav className="flex-column">
            {[
              { key: 'info', icon: FaUser, label: 'Profil' },
              { key: 'security', icon: FaLock, label: 'Güvenlik' },
              // { key: 'notifications', icon: FaBell, label: 'Bildirimler' },
              // { key: 'settings', icon: FaCog, label: 'Ayarlar' }
            ].map(({ key, icon: Icon, label }) => (
              <Nav.Link
                key={key}
                active={activeTab === key}
                onClick={() => setActiveTab(key)}
                className="nav-link"
              >
                <div>
                  <Icon className="me-2" /> {label}
                </div>
                <FaChevronRight className={activeTab === key ? 'opacity-100' : 'opacity-0'} />
              </Nav.Link>
            ))}
          </Nav>
        </Col>
        <Col md={8} className="p-4">
          {activeTab === 'info' && (
            <>
              <h3 className="text-3xl font-bold mb-4 text-white">Profil Bilgileri</h3>
              <div className="info-card">
                <div className="info-card-label">Ad Soyad</div>
                <div className="info-card-value">
                  {isEditing ? (
                    <Form onSubmit={handleProfileUpdate} className="w-100">
                      <Form.Control
                        type="text"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                      />
                      <Button variant="primary" type="submit" className="mt-2">
                        Kaydet
                      </Button>
                      <Button variant="secondary" onClick={() => setIsEditing(false)} className="mt-2 ms-2">
                        İptal
                      </Button>
                    </Form>
                  ) : (
                    <>
                      <div className="user-info-icon">
                        <FaUser />
                      </div>
                      {profile.name}
                      <Button variant="secondary" onClick={() => setIsEditing(true)} className="edit-button">
                        <FaEdit />
                        <span>Düzenle</span>
                      </Button>
                    </>
                  )}
                </div>
              </div>
              <div className="info-card">
                <div className="info-card-label">Kullanıcı Rolü</div>
                <div className="info-card-value">
                  <div className="user-info-icon">
                    {getRoleIcon(profile.role)}
                  </div>
                  {getRoleName(profile.role)}
                  <div className={`role-badge ${getRoleBadgeClass(profile.role)}`}>
                    {getRoleIcon(profile.role)}
                    <span>{profile.role || "User"}</span>
                  </div>
                </div>
              </div>
              <div className="info-card">
                <div className="info-card-label">E-posta</div>
                <div className="info-card-value">
                  <div className="user-info-icon">
                    <FaEnvelope />
                  </div>
                  {profile.email}
                </div>
              </div>
              <div className="info-card">
                <div className="info-card-label">E-posta Durumu</div>
                <div className="info-card-value">
                  <div className="user-info-icon">
                    {profile.email_verified === 1 ? (
                      <FaCheckCircle className="text-success" />
                    ) : (
                      <FaTimesCircle className="text-danger" />
                    )}
                  </div>
                  {profile.email_verified === 1 ? "Doğrulanmış" : "Doğrulanmamış"}
                </div>
              </div>
              <div className="info-card">
                <div className="info-card-label">Kayıt Tarihi</div>
                <div className="info-card-value">
                  <div className="user-info-icon">
                    <FaCalendarAlt />
                  </div>
                  {profile.created_at ? new Date(profile.created_at).toLocaleDateString('tr-TR') : 'Bilgi mevcut değil'}
                </div>
              </div>
              {profile.lastVerificationDate && (
                <div className="info-card">
                  <div className="info-card-label">Son Doğrulama Tarihi</div>
                  <div className="info-card-value">
                    <div className="user-info-icon">
                      <FaCalendarAlt />
                    </div>
                    {new Date(profile.lastVerificationDate).toLocaleDateString()}
                  </div>
                </div>
              )}
            </>
          )}
          {activeTab === 'security' && (
            <>
              <h3 className="text-3xl font-bold mb-4 text-white">Güvenlik</h3>
              <Form onSubmit={handlePasswordChange}>
                <div className="info-card">
                  <Form.Group controlId="currentPassword">
                    <Form.Label className="info-card-label">Mevcut Şifre</Form.Label>
                    <Form.Control
                      type="password"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="info-card">
                  <Form.Group controlId="newPassword">
                    <Form.Label className="info-card-label">Yeni Şifre</Form.Label>
                    <Form.Control
                      type="password"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <div className="info-card">
                  <Form.Group controlId="confirmPassword">
                    <Form.Label className="info-card-label">Yeni Şifre (Tekrar)</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                  </Form.Group>
                </div>
                <Button variant="primary" type="submit" className="w-100">
                  Şifre Değiştir
                </Button>
              </Form>
            </>
          )}
          {activeTab === 'notifications' && (
            <>
              <h3 className="text-3xl font-bold mb-4 text-white">Bildirim Ayarları</h3>
              <Form>
                <div className="info-card">
                  <Form.Check 
                    type="switch"
                    id="email-notifications"
                    label="E-posta Bildirimleri"
                    className="mb-3"
                  />
                </div>
                <div className="info-card">
                  <Form.Check 
                    type="switch"
                    id="push-notifications"
                    label="Push Bildirimleri"
                    className="mb-3"
                  />
                </div>
                <div className="info-card">
                  <Form.Check 
                    type="switch"
                    id="sms-notifications"
                    label="SMS Bildirimleri"
                    className="mb-3"
                  />
                </div>
                <Button variant="primary" type="submit" className="w-100">
                  Bildirimleri Kaydet
                </Button>
              </Form>
            </>
          )}
          {activeTab === 'settings' && (
            <>
              <h3 className="text-3xl font-bold mb-4 text-white">Hesap Ayarları</h3>
              <Form>
                <div className="info-card">
                  <Form.Group controlId="language">
                    <Form.Label className="info-card-label">Dil</Form.Label>
                    <Form.Select>
                      <option>Türkçe</option>
                      <option>English</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="info-card">
                  <Form.Group controlId="timezone">
                    <Form.Label className="info-card-label">Zaman Dilimi</Form.Label>
                    <Form.Select>
                      <option>Istanbul (GMT+3)</option>
                      <option>London (GMT+0)</option>
                      <option>New York (GMT-5)</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <Button variant="primary" type="submit" className="w-100">
                  Ayarları Kaydet
                </Button>
              </Form>
            </>
          )}
        </Col>
      </Row>
      {error && (
        <Alert 
          variant="danger" 
          className="mt-3" 
          onClose={() => setShowError(false)} 
          dismissible
          show={showError}
        >
          {error}
        </Alert>
      )}
      
      {successMessage && (
        <Alert 
          variant="success" 
          className="mt-3" 
          onClose={() => setShowSuccess(false)} 
          dismissible
          show={showSuccess}
        >
          {successMessage}
        </Alert>
      )}
        </Container>
  );
};

export default Profile;