import React, { useState, useEffect } from 'react';
import { 
  Container, Box, Typography, Paper, Grid, 
  Card, CardContent, Divider, Button, Avatar,
  Tabs, Tab, CircularProgress, Chip, IconButton,
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Collapse, Badge,
  Dialog, DialogTitle, DialogContent, DialogActions,
  TextField, Autocomplete, Alert, Breadcrumbs,
  Link, Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import {
  Person as PersonIcon,
  Event as EventIcon,
  QuestionAnswer as QuestionIcon,
  Group as GroupIcon,
  ExpandMore as ExpandMoreIcon,
  ExpandLess as ExpandLessIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  FilterList as FilterListIcon
} from '@mui/icons-material';
import dashboardService from '../../services/dashboardService';
import { useTheme } from '@mui/material/styles';

const RelationalDashboard = () => {
  const theme = useTheme();
  
  // Ana durumlar
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Veri durumları
  const [users, setUsers] = useState([]);
  const [conferences, setConferences] = useState([]);
  const [sessions, setSessions] = useState([]);
  const [questions, setQuestions] = useState([]);
  
  // Seçim durumları
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedConference, setSelectedConference] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  
  // UI durumları
  const [view, setView] = useState('users'); // 'users', 'conferences', 'sessions', 'questions'
  const [searchQuery, setSearchQuery] = useState('');
  const [filterRole, setFilterRole] = useState('all');
  
  // InfoBox durumu
  const [showInfo, setShowInfo] = useState(true);
  
  // Verileri yükleme
  useEffect(() => {
    const loadInitialData = async () => {
      setLoading(true);
      try {
        const usersData = await dashboardService.getAllUsers();
        setUsers(usersData);
        
        // Tüm konferansları da başlangıçta yükleyelim
        const conferencesData = await dashboardService.getAllConferences();
        setConferences(conferencesData);
        
        setLoading(false);
      } catch (err) {
        console.error('Veri yüklenirken hata oluştu:', err);
        setError('Veriler yüklenirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
        setLoading(false);
      }
    };
    
    loadInitialData();
  }, []);
  
  // Kullanıcı seçildiğinde, o kullanıcının konferanslarını filtrele
  useEffect(() => {
    if (selectedUser) {
      // Eğer kullanıcı tarafından oluşturulan konferanslar varsa filtreleyelim
      const userConferences = conferences.filter(
        conf => conf.createdBy && conf.createdBy.id === selectedUser.id
      );
      
      // Görünümü konferanslara taşı
      setView('conferences');
    } else {
      // Kullanıcı seçimi kaldırıldığında görünümü kullanıcılara geri döndür
      setView('users');
    }
  }, [selectedUser, conferences]);
  
  // Konferans seçildiğinde, o konferansın oturumlarını yükle
  useEffect(() => {
    const loadSessionsForConference = async () => {
      if (selectedConference) {
        setLoading(true);
        try {
          // Burada normalde seçilen konferansa ait oturumları çekerdik
          // Ancak şimdilik tüm oturumları çekip filtreliyoruz
          const allSessions = await dashboardService.getAllSessions();
          const filteredSessions = allSessions.filter(
            session => session.conferenceId === selectedConference.id
          );
          setSessions(filteredSessions);
          
          // Görünümü oturumlara taşı
          setView('sessions');
          setLoading(false);
        } catch (err) {
          console.error('Oturumlar yüklenirken hata oluştu:', err);
          setError('Oturumlar yüklenirken bir hata oluştu.');
          setLoading(false);
        }
      }
    };
    
    if (selectedConference) {
      loadSessionsForConference();
    }
  }, [selectedConference]);
  
  // Oturum seçildiğinde, o oturumun sorularını yükle
  useEffect(() => {
    const loadQuestionsForSession = async () => {
      if (selectedSession) {
        setLoading(true);
        try {
          // Burada normalde seçilen oturuma ait soruları çekerdik
          // Ancak şimdilik tüm soruları çekip filtreliyoruz
          const allQuestions = await dashboardService.getAllQuestions();
          const filteredQuestions = allQuestions.filter(
            question => question.sessionId === selectedSession.id
          );
          setQuestions(filteredQuestions);
          
          // Görünümü sorulara taşı
          setView('questions');
          setLoading(false);
        } catch (err) {
          console.error('Sorular yüklenirken hata oluştu:', err);
          setError('Sorular yüklenirken bir hata oluştu.');
          setLoading(false);
        }
      }
    };
    
    if (selectedSession) {
      loadQuestionsForSession();
    }
  }, [selectedSession]);
  
  // Geri düğme işleyicisi
  const handleBack = () => {
    if (view === 'questions') {
      setSelectedSession(null);
      setView('sessions');
    } else if (view === 'sessions') {
      setSelectedConference(null);
      setView('conferences');
    } else if (view === 'conferences') {
      setSelectedUser(null);
      setView('users');
    }
  };
  
  // Filtreleme fonksiyonları
  const getFilteredUsers = () => {
    return users.filter(user => {
      const matchesSearch = 
        user.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        user.email?.toLowerCase().includes(searchQuery.toLowerCase());
      
      const matchesRole = 
        filterRole === 'all' || 
        user.role?.toLowerCase() === filterRole.toLowerCase();
      
      return matchesSearch && matchesRole;
    });
  };
  
  // Kullanıcı listesini render et
  const renderUsersList = () => {
    const filteredUsers = getFilteredUsers();
    
    return (
      <Box mb={3}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography variant="h6" component="h2">
            Kullanıcılar ({filteredUsers.length})
          </Typography>
          
          <Box display="flex" alignItems="center">
            <FormControl size="small" sx={{ minWidth: 120, mr: 1 }}>
              <InputLabel>Rol</InputLabel>
              <Select
                value={filterRole}
                label="Rol"
                onChange={(e) => setFilterRole(e.target.value)}
                size="small"
              >
                <MenuItem value="all">Tümü</MenuItem>
                <MenuItem value="admin">Yönetici</MenuItem>
                <MenuItem value="user">Kullanıcı</MenuItem>
                <MenuItem value="moderator">Moderatör</MenuItem>
              </Select>
            </FormControl>
            
            <TextField
              placeholder="Ara..."
              size="small"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{
                startAdornment: <SearchIcon fontSize="small" color="action" sx={{ mr: 1 }} />
              }}
            />
          </Box>
        </Box>
        
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Ad Soyad</TableCell>
                <TableCell>E-posta</TableCell>
                <TableCell>Rol</TableCell>
                <TableCell>Kayıt Tarihi</TableCell>
                <TableCell>Konferanslar</TableCell>
                <TableCell>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredUsers.map(user => {
                // Kullanıcının konferans sayısını hesapla
                const userConferenceCount = conferences.filter(
                  conf => conf.createdBy && conf.createdBy.id === user.id
                ).length;
                
                return (
                  <TableRow 
                    key={user.id}
                    hover
                    onClick={() => setSelectedUser(user)}
                    sx={{ cursor: 'pointer' }}
                  >
                    <TableCell>{user.id}</TableCell>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Avatar 
                          sx={{ width: 32, height: 32, mr: 1 }}
                          src={user.picture}
                        >
                          {user.name?.charAt(0)}
                        </Avatar>
                        {user.name}
                      </Box>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                      <Chip 
                        label={user.role}
                        color={
                          user.role === 'Admin' ? "primary" : 
                          user.role === 'Moderator' ? "secondary" : "default"
                        }
                        size="small"
                      />
                    </TableCell>
                    <TableCell>
                      {new Date(user.createdAt).toLocaleDateString('tr-TR')}
                    </TableCell>
                    <TableCell>
                      <Badge 
                        badgeContent={userConferenceCount} 
                        color="primary"
                        max={99}
                      >
                        <EventIcon />
                      </Badge>
                    </TableCell>
                    <TableCell>
                      <Button 
                        variant="outlined" 
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedUser(user);
                        }}
                      >
                        Konferansları Gör
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              
              {filteredUsers.length === 0 && (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <Typography variant="body2" color="textSecondary">
                      Arama kriterlerinize uygun kullanıcı bulunamadı.
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Konferanslar listesini render et
  const renderConferencesList = () => {
    // Seçilen kullanıcının konferanslarını filtrele
    const userConferences = selectedUser ? 
      conferences.filter(conf => conf.createdBy && conf.createdBy.id === selectedUser.id) :
      conferences;
    
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBack}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          >
            Kullanıcılara Dön
          </Button>
          
          <Typography variant="h6" component="h2">
            {selectedUser ? `${selectedUser.name} - Konferanslar (${userConferences.length})` : 'Tüm Konferanslar'}
          </Typography>
        </Box>
        
        {selectedUser && (
          <Box mb={3}>
            <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: theme.palette.background.paper }}>
              <Box display="flex" alignItems="center">
                <Avatar 
                  sx={{ width: 48, height: 48, mr: 2 }}
                  src={selectedUser.picture}
                >
                  {selectedUser.name?.charAt(0)}
                </Avatar>
                
                <Box>
                  <Typography variant="h6">{selectedUser.name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {selectedUser.email} • {selectedUser.role}
                  </Typography>
                </Box>
              </Box>
            </Paper>
          </Box>
        )}
        
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Konferans Adı</TableCell>
                <TableCell>Tarih</TableCell>
                <TableCell>Konum</TableCell>
                <TableCell>Oturumlar</TableCell>
                <TableCell>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userConferences.map(conference => (
                <TableRow 
                  key={conference.id}
                  hover
                  onClick={() => setSelectedConference(conference)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{conference.id}</TableCell>
                  <TableCell>{conference.name}</TableCell>
                  <TableCell>
                    {new Date(conference.date).toLocaleDateString('tr-TR')}
                    {conference.isDateRange && conference.endDate && (
                      <> - {new Date(conference.endDate).toLocaleDateString('tr-TR')}</>
                    )}
                  </TableCell>
                  <TableCell>{conference.location}</TableCell>
                  <TableCell>
                    <Badge 
                      badgeContent={conference.sessionCount || 0} 
                      color="secondary"
                      max={99}
                    >
                      <QuestionIcon />
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedConference(conference);
                      }}
                    >
                      Oturumları Gör
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              
              {userConferences.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body2" color="textSecondary">
                      {selectedUser ? 
                        `${selectedUser.name} kullanıcısına ait konferans bulunamadı.` : 
                        'Konferans bulunamadı.'
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Oturumlar listesini render et
  const renderSessionsList = () => {
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBack}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          >
            Konferanslara Dön
          </Button>
          
          <Typography variant="h6" component="h2">
            {selectedConference ? 
              `${selectedConference.name} - Oturumlar (${sessions.length})` : 
              'Tüm Oturumlar'
            }
          </Typography>
        </Box>
        
        {selectedConference && (
          <Box mb={3}>
            <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: theme.palette.background.paper }}>
              <Box>
                <Typography variant="h6">{selectedConference.name}</Typography>
                <Typography variant="body2" color="textSecondary">
                  {new Date(selectedConference.date).toLocaleDateString('tr-TR')} 
                  {selectedConference.isDateRange && selectedConference.endDate && (
                    <> - {new Date(selectedConference.endDate).toLocaleDateString('tr-TR')}</>
                  )} • {selectedConference.location}
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
        
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Oturum Adı</TableCell>
                <TableCell>Açıklama</TableCell>
                <TableCell>Sona Erme Tarihi</TableCell>
                <TableCell>Sorular</TableCell>
                <TableCell>İşlemler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sessions.map(session => (
                <TableRow 
                  key={session.id}
                  hover
                  onClick={() => setSelectedSession(session)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{session.id}</TableCell>
                  <TableCell>{session.name}</TableCell>
                  <TableCell>{session.description?.substring(0, 50)}...</TableCell>
                  <TableCell>
                    {session.expirationDate ? 
                      new Date(session.expirationDate).toLocaleDateString('tr-TR') : 
                      'Belirlenmemiş'
                    }
                  </TableCell>
                  <TableCell>
                    <Badge 
                      badgeContent={session.questionCount || 0} 
                      color="error"
                      max={99}
                    >
                      <QuestionIcon />
                    </Badge>
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="outlined" 
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        setSelectedSession(session);
                      }}
                    >
                      Soruları Gör
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              
              {sessions.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body2" color="textSecondary">
                      {selectedConference ? 
                        `${selectedConference.name} konferansına ait oturum bulunamadı.` : 
                        'Oturum bulunamadı.'
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Sorular listesini render et
  const renderQuestionsList = () => {
    return (
      <Box>
        <Box display="flex" alignItems="center" mb={2}>
          <Button 
            startIcon={<ArrowBackIcon />} 
            onClick={handleBack}
            variant="outlined"
            size="small"
            sx={{ mr: 2 }}
          >
            Oturumlara Dön
          </Button>
          
          <Typography variant="h6" component="h2">
            {selectedSession ? 
              `${selectedSession.name} - Sorular (${questions.length})` : 
              'Tüm Sorular'
            }
          </Typography>
        </Box>
        
        {selectedSession && (
          <Box mb={3}>
            <Paper elevation={1} sx={{ p: 2, mb: 3, bgcolor: theme.palette.background.paper }}>
              <Box>
                <Typography variant="h6">{selectedSession.name}</Typography>
                <Typography variant="body2">
                  {selectedSession.description}
                </Typography>
                <Typography variant="body2" color="textSecondary" mt={1}>
                  Konferans: {selectedSession.conferenceName}
                </Typography>
              </Box>
            </Paper>
          </Box>
        )}
        
        <TableContainer component={Paper} elevation={2}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Soru</TableCell>
                <TableCell>Soran</TableCell>
                <TableCell>Durum</TableCell>
                <TableCell>Yanıtlandı</TableCell>
                <TableCell>Oluşturulma</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map(question => (
                <TableRow 
                  key={question.id}
                  hover
                  sx={{
                    cursor: 'pointer',
                    bgcolor: question.status === 'rejected' ? 
                      'rgba(239, 83, 80, 0.08)' : 
                      question.isAnswered ? 'rgba(102, 187, 106, 0.08)' : 'inherit'
                  }}
                >
                  <TableCell>{question.id}</TableCell>
                  <TableCell>
                    {question.onlyRequestToSpeak ? 
                      <Chip size="small" label="Sadece Söz İsteği" color="info" sx={{ mr: 1 }} /> : 
                      null
                    }
                    {question.text}
                  </TableCell>
                  <TableCell>{question.askerName}</TableCell>
                  <TableCell>
                    <Chip 
                      label={
                        question.status === 'pending' ? 'Beklemede' :
                        question.status === 'approved' ? 'Onaylandı' :
                        question.status === 'rejected' ? 'Reddedildi' : 
                        question.status
                      }
                      color={
                        question.status === 'pending' ? 'warning' :
                        question.status === 'approved' ? 'success' :
                        question.status === 'rejected' ? 'error' : 
                        'default'
                      }
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {question.isAnswered ? 
                      <Chip label="Yanıtlandı" color="success" size="small" /> : 
                      <Chip label="Yanıtlanmadı" color="default" size="small" />
                    }
                  </TableCell>
                  <TableCell>
                    {new Date(question.createdAt).toLocaleDateString('tr-TR')}
                  </TableCell>
                </TableRow>
              ))}
              
              {questions.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    <Typography variant="body2" color="textSecondary">
                      {selectedSession ? 
                        `${selectedSession.name} oturumuna ait soru bulunamadı.` : 
                        'Soru bulunamadı.'
                      }
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    );
  };
  
  // Breadcrumbs bileşenini render et
  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 3 }}>
        <Link 
          color={view === 'users' ? 'text.primary' : 'inherit'}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            setSelectedUser(null);
            setSelectedConference(null);
            setSelectedSession(null);
            setView('users');
          }}
          underline={view === 'users' ? 'none' : 'hover'}
          fontWeight={view === 'users' ? 'bold' : 'normal'}
        >
          Kullanıcılar
        </Link>
        
        {(view === 'conferences' || view === 'sessions' || view === 'questions') && (
          <Link
            color={view === 'conferences' ? 'text.primary' : 'inherit'}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedConference(null);
              setSelectedSession(null);
              setView('conferences');
            }}
            underline={view === 'conferences' ? 'none' : 'hover'}
            fontWeight={view === 'conferences' ? 'bold' : 'normal'}
          >
            {selectedUser ? `${selectedUser.name} - Konferanslar` : 'Konferanslar'}
          </Link>
        )}
        
        {(view === 'sessions' || view === 'questions') && (
          <Link
            color={view === 'sessions' ? 'text.primary' : 'inherit'}
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setSelectedSession(null);
              setView('sessions');
            }}
            underline={view === 'sessions' ? 'none' : 'hover'}
            fontWeight={view === 'sessions' ? 'bold' : 'normal'}
          >
            {selectedConference ? `${selectedConference.name} - Oturumlar` : 'Oturumlar'}
          </Link>
        )}
        
        {view === 'questions' && (
          <Typography color="text.primary">
            {selectedSession ? `${selectedSession.name} - Sorular` : 'Sorular'}
          </Typography>
        )}
      </Breadcrumbs>
    );
  };
  
  // Ana render metodu
  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      <Typography variant="h4" component="h1" gutterBottom>
        İlişkisel Dashboard
      </Typography>
      
      {showInfo && (
        <Alert 
          severity="info" 
          sx={{ mb: 3 }} 
          onClose={() => setShowInfo(false)}
        >
          <Typography variant="subtitle1" fontWeight="bold">Yeni İlişkisel Dashboard!</Typography>
          <Typography variant="body2">
            Bu yeni dashboard sayesinde kullanıcılardan konferanslara, oturumlara ve sorulara kadar 
            tüm sistemi hiyerarşik bir şekilde inceleyebilirsiniz. Bir kullanıcı seçerek başlayın 
            ve ardından o kullanıcının etkinliklerini, oturumlarını ve sorularını görüntüleyin.
          </Typography>
        </Alert>
      )}
      
      {renderBreadcrumbs()}
      
      {error && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {error}
        </Alert>
      )}
      
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
          <CircularProgress />
        </Box>
      ) : (
        <>
          {view === 'users' && renderUsersList()}
          {view === 'conferences' && renderConferencesList()}
          {view === 'sessions' && renderSessionsList()}
          {view === 'questions' && renderQuestionsList()}
        </>
      )}
    </Container>
  );
};

export default RelationalDashboard; 