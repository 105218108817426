import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Button, Spinner, Alert } from 'react-bootstrap';
import { FaEye, FaEdit, FaTrash } from 'react-icons/fa';
import dashboardService from '../../services/dashboardService';

interface Conference {
  id: number;
  conf_name: string;
  date: string;
  end_date: string | null;
  is_date_range: boolean;
  location: string | null;
  url: string | null;
  created_at: string;
  user_id: number;
  username?: string;
  session_count?: number;
}

const ConferenceManagement: React.FC = () => {
  const [conferences, setConferences] = useState<Conference[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConferences = async () => {
      try {
        setLoading(true);
        
        // Gerçek API'den veri alma
        try {
          const conferencesData = await dashboardService.getAllConferences();
          setConferences(conferencesData);
        } catch (error) {
          console.error("API'den konferanslar alınamadı, demo veriler kullanılıyor", error);
          // Demo veriler
          setConferences([
            { 
              id: 1, 
              conf_name: 'React Konferansı 2024', 
              date: '2024-05-15', 
              end_date: '2024-05-17', 
              is_date_range: true, 
              location: 'İstanbul', 
              url: 'https://reactkonferans.com', 
              created_at: '2024-02-20T10:00:00Z',
              user_id: 1,
              username: 'Mustafa Kemal',
              session_count: 5
            },
            { 
              id: 2, 
              conf_name: 'Yazılım Geliştirme Zirvesi', 
              date: '2024-06-10', 
              end_date: null, 
              is_date_range: false, 
              location: 'Ankara', 
              url: null, 
              created_at: '2024-03-05T14:30:00Z',
              user_id: 1,
              username: 'Mustafa Kemal',
              session_count: 3
            },
            { 
              id: 3, 
              conf_name: 'Web Teknolojileri Çalıştayı', 
              date: '2024-07-20', 
              end_date: '2024-07-22', 
              is_date_range: true, 
              location: 'Online', 
              url: 'https://webteknolojileri.com', 
              created_at: '2024-03-15T09:15:00Z',
              user_id: 2,
              username: 'Ali Yılmaz',
              session_count: 8
            }
          ]);
        }
      } catch (err) {
        setError('Konferanslar yüklenirken bir hata oluştu.');
        console.error('Konferans veri yükleme hatası:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchConferences();
  }, []);

  const handleViewConference = (conferenceId: number) => {
    // Konferans detayları sayfasına yönlendir
    alert(`Konferans ID: ${conferenceId} detayları görüntülenecek`);
  };

  const handleEditConference = (conferenceId: number) => {
    // Konferans düzenleme işlevini başlat
    alert(`Konferans ID: ${conferenceId} düzenlenecek`);
  };

  const handleDeleteConference = (conferenceId: number) => {
    // Konferans silme onayı iste
    if (window.confirm('Bu konferansı silmek istediğinize emin misiniz?')) {
      // Silme API çağrısı burada yapılacak
      alert(`Konferans ID: ${conferenceId} silindi`);
      // Başarılı silme durumunda listeyi güncelle
      setConferences(conferences.filter(conf => conf.id !== conferenceId));
    }
  };

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <div>
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h5 className="mb-0">Konferans Yönetimi</h5>
              <Button variant="primary" size="sm">
                Yeni Konferans Ekle
              </Button>
            </Card.Header>
            <Card.Body>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Konferans Adı</th>
                    <th>Tarih</th>
                    <th>Konum</th>
                    <th>Oluşturan</th>
                    <th>Oturum Sayısı</th>
                    <th>Oluşturulma Tarihi</th>
                    <th>İşlemler</th>
                  </tr>
                </thead>
                <tbody>
                  {conferences.map(conference => (
                    <tr key={conference.id}>
                      <td>{conference.id}</td>
                      <td>{conference.conf_name}</td>
                      <td>
                        {new Date(conference.date).toLocaleDateString('tr-TR')}
                        {conference.is_date_range && conference.end_date && (
                          <> - {new Date(conference.end_date).toLocaleDateString('tr-TR')}</>
                        )}
                      </td>
                      <td>
                        {conference.location || 'Belirtilmemiş'}
                        {conference.location === 'Online' && (
                          <Badge bg="info" className="ms-2">Online</Badge>
                        )}
                      </td>
                      <td>{conference.username || `Kullanıcı ID: ${conference.user_id}`}</td>
                      <td>
                        <Badge bg="secondary">{conference.session_count || 0}</Badge>
                      </td>
                      <td>{new Date(conference.created_at).toLocaleDateString('tr-TR')}</td>
                      <td>
                        <Button 
                          variant="outline-info" 
                          size="sm" 
                          className="me-1"
                          onClick={() => handleViewConference(conference.id)}
                        >
                          <FaEye />
                        </Button>
                        <Button 
                          variant="outline-primary" 
                          size="sm" 
                          className="me-1"
                          onClick={() => handleEditConference(conference.id)}
                        >
                          <FaEdit />
                        </Button>
                        <Button 
                          variant="outline-danger" 
                          size="sm"
                          onClick={() => handleDeleteConference(conference.id)}
                        >
                          <FaTrash />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      
      {/* Konferans İstatistikleri */}
      <Row>
        <Col md={4} className="mb-4">
          <Card className="text-center h-100">
            <Card.Header>Toplam Konferans</Card.Header>
            <Card.Body>
              <h2>{conferences.length}</h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="text-center h-100">
            <Card.Header>Aktif Konferanslar</Card.Header>
            <Card.Body>
              <h2>
                {conferences.filter(conf => 
                  new Date(conf.is_date_range && conf.end_date ? conf.end_date : conf.date) >= new Date()
                ).length}
              </h2>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mb-4">
          <Card className="text-center h-100">
            <Card.Header>Toplam Oturum</Card.Header>
            <Card.Body>
              <h2>
                {conferences.reduce((total, conf) => total + (conf.session_count || 0), 0)}
              </h2>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ConferenceManagement; 