import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Table, Badge, Spinner, Alert } from 'react-bootstrap';
import { FaUsers, FaChalkboardTeacher, FaCalendarAlt, FaQuestionCircle } from 'react-icons/fa';
import dashboardService from '../../services/dashboardService';

interface Statistics {
  totalUsers: number;
  totalConferences: number;
  totalSessions: number;
  totalQuestions: number;
  activeUsers: number;
  pendingQuestions: number;
}

interface LogEntry {
  id: number;
  userId: number | null;
  userName: string;
  action: string;
  timestamp: string;
  status: string;
}

const Dashboard: React.FC = () => {
  const [statistics, setStatistics] = useState<Statistics | null>(null);
  const [recentLogs, setRecentLogs] = useState<LogEntry[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Gerçek API'den veri alma
        try {
          const statsData = await dashboardService.getStatistics();
          setStatistics(statsData);
        } catch (error) {
          console.error("API'den istatistikler alınamadı, demo veriler kullanılıyor", error);
          // Demo veriler
          setStatistics({
            totalUsers: 25,
            totalConferences: 8,
            totalSessions: 32,
            totalQuestions: 156,
            activeUsers: 18,
            pendingQuestions: 12
          });
        }
        
        // Sistem loglarını alma
        try {
          const logsData = await dashboardService.getSystemLogs(5);
          setRecentLogs(logsData);
        } catch (error) {
          console.error("API'den loglar alınamadı, demo veriler kullanılıyor", error);
          // Demo log verileri
          setRecentLogs([
            { 
              id: 1, 
              userId: 1, 
              userName: "Admin Kullanıcı", 
              action: "Admin paneline giriş yaptı", 
              timestamp: new Date().toISOString(), 
              status: "Başarılı" 
            },
            { 
              id: 2, 
              userId: 2, 
              userName: "Test Kullanıcı", 
              action: "Yeni soru ekledi", 
              timestamp: new Date().toISOString(), 
              status: "İşlendi" 
            },
            { 
              id: 3, 
              userId: 3, 
              userName: "Moderatör", 
              action: "Soru onayladı", 
              timestamp: new Date().toISOString(), 
              status: "Başarılı" 
            }
          ]);
        }
      } catch (err) {
        setError('Veriler yüklenirken bir hata oluştu.');
        console.error('Dashboard veri yükleme hatası:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <div>
      {statistics && (
        <>
          <Row className="stats-cards">
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.totalUsers}</h2>
                      <Card.Text>Toplam Kullanıcı</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaUsers />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.totalConferences}</h2>
                      <Card.Text>Toplam Konferans</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaCalendarAlt />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.totalSessions}</h2>
                      <Card.Text>Toplam Oturum</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaChalkboardTeacher />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          
          <Row className="stats-cards">
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.totalQuestions}</h2>
                      <Card.Text>Toplam Soru</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaQuestionCircle />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.activeUsers}</h2>
                      <Card.Text>Aktif Kullanıcı</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaUsers />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-4">
              <Card className="stat-card">
                <Card.Body>
                  <div className="d-flex justify-content-between">
                    <div>
                      <h2>{statistics.pendingQuestions}</h2>
                      <Card.Text>Bekleyen Soru</Card.Text>
                    </div>
                    <div className="stat-icon">
                      <FaQuestionCircle />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      
      {/* Sistem Logları */}
      <Card className="mb-4">
        <Card.Header>
          <h5>Son Etkinlikler</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive hover>
            <thead>
              <tr>
                <th>Tarih</th>
                <th>Kullanıcı</th>
                <th>İşlem</th>
                <th>Durum</th>
              </tr>
            </thead>
            <tbody>
              {recentLogs.map((log) => (
                <tr key={log.id}>
                  <td>{new Date(log.timestamp).toLocaleDateString('tr-TR')}</td>
                  <td>{log.userName || 'Bilinmeyen Kullanıcı'}</td>
                  <td>{log.action}</td>
                  <td>
                    <Badge bg={
                      log.status === 'Başarılı' ? 'success' :
                      log.status === 'İşlendi' ? 'info' :
                      log.status === 'Hata' ? 'danger' : 'secondary'
                    }>
                      {log.status}
                    </Badge>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Dashboard; 