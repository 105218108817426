import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { FaHome, FaBolt, FaList, FaPlus, FaUser, FaAngleDown, FaSignOutAlt, FaTimes, FaChartBar, FaNetworkWired, FaTachometerAlt, FaProjectDiagram } from 'react-icons/fa';
import authService from '../services/authService';
import logo_short from '../assets/logo_short.svg'; // Logo'yu import edin
import {logotext as reactLogo} from '../assets/logo_text.svg';
import logo from '../assets/logo_text.svg';
import styles from '../styles/Sidebar.module.css';

// Not: Bu bileşen sidebarın içeriğini ve açma/kapama mantığını yönetir.
// Hamburger menüyü gizlemek/göstermek için ana layout bileşenine isExpanded durumunu bildirmek gerekir.
// Önerilen yöntem: Ana bileşende, isExpanded değeri true olduğunda hamburger menüyü gizleyin.

const Sidebar = ({ isExpanded, toggleSidebar, handleLogout, user, isMobileView, onUserUpdate }) => {
  const [activeItem, setActiveItem] = useState('Ana Sayfa');
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const navigate = useNavigate();
  const nodeRef = useRef(null);
  const profileMenuRef = useRef(null);

  // iOS kontrolü
  useEffect(() => {
    // iOS cihaz tespiti
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(isIOSDevice);
  }, []);

  // Sidebar kapandığında açık profil menüsünü kapatalım
  useEffect(() => {
    if (!isExpanded) {
      setIsProfileMenuOpen(false);
    }
  }, [isExpanded]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    authService.setAuthHeader();
  }, []);

  // Eğer user null ise ve token varsa, user bilgisini yeniden almayı dene
  useEffect(() => {
    if (!user && sessionStorage.getItem('token')) {
      const currentUser = authService.getCurrentUser();
      if (currentUser) {
        console.log("Sidebar: User bilgisi yeniden alındı", currentUser);
        // Parent component'e user bilgisini güncellemesi için bildirim
        if (onUserUpdate) {
          onUserUpdate(currentUser);
        }
      }
    }
  }, [user, onUserUpdate]);

  useEffect(() => {
    const setVH = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    };

    setVH();
    window.addEventListener('resize', setVH);

    return () => window.removeEventListener('resize', setVH);
  }, []);

  const toggleProfileMenu = () => {
    setIsProfileMenuOpen(!isProfileMenuOpen);
  };

  const handleMenuItemClick = (itemName, path) => {
    setActiveItem(itemName);
    navigate(path);
    setIsProfileMenuOpen(false);
    
    // Mobil görünümde, menü öğesine tıklandığında sidebar'ı kapatalım
    if (isMobileView) {
      toggleSidebar(false);
    }
  };

  const menuItems = [
    { name: 'Ana Sayfa', icon: <FaHome />, to: '/admin' },
    ...(user?.role?.toLowerCase() === 'admin' ? [
      { name: 'Admin Dashboard', icon: <FaTachometerAlt />, to: '/admin-dashboard' },
      { name: 'İlişkisel Dashboard', icon: <FaProjectDiagram />, to: '/relational-dashboard' }
    ] : []),
    { name: 'Hızlı Etkinlik Oluştur', icon: <FaBolt />, to: '/quickConference' },
    { name: 'Tüm Etkinlikleri Göster', icon: <FaList />, to: '/conferenceList' },
    { name: 'Yeni Etkinlik Oluştur', icon: <FaPlus />, to: '/newConference' },
  ];

  return (
    <>
      <div
        className={`${styles.sidebar} ${!isExpanded ? styles.collapsed : ''} ${isIOS ? 'glass-effect' : ''}`}
        style={{
          width: isExpanded ? '280px' : '60px',
          transform: isMobileView && !isExpanded ? 'translateX(-100%)' : 'translateX(0)',
        }}
        onMouseEnter={() => !isMobileView && toggleSidebar(true)}
        onMouseLeave={() => !isMobileView && toggleSidebar(false)}
      >
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', alignItems: 'center', flex: 1, minWidth: 0 }}>
            <img src={logo_short} alt="Logo" style={{ width: '40px', height: '40px', marginRight: '10px', flexShrink: 0 }} />
            {isExpanded && <img src={logo} alt="Logo" style={{ width: '100px', height: '40px', marginRight: '10px' }} />}
          </div>
          {isMobileView && isExpanded && (
            <button
              onClick={() => toggleSidebar(false)}
              style={{
                background: 'none',
                border: 'none',
                color: '#fff',
                cursor: 'pointer',
                padding: '5px',
                marginLeft: '10px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexShrink: 0,
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
              }}
            >
              <FaTimes size={18} />
            </button>
          )}
        </div>

        <nav style={{ flex: 1, overflowY: 'auto', overflowX: 'hidden' }}>
          {menuItems.map((item) => (
            <div
              key={item.name}
              onClick={() => handleMenuItemClick(item.name, item.to)}
              className={`${styles.menuItem} ${activeItem === item.name ? styles.active : ''} ${isIOS ? 'glass-effect' : ''}`}
            >
              <span style={{ marginRight: '10px', fontSize: '20px', flexShrink: 0 }}>{item.icon}</span>
              {isExpanded && <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{item.name}</span>}
            </div>
          ))}
        </nav>

        {user && (
          <div ref={profileMenuRef} style={{
            display: 'flex',
            flexDirection: 'column',
            borderTop: '1px solid #333',
            marginTop: 'auto',
            position: 'relative',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
          }}>
            {/* Debug bilgisini ekleyelim */}
            {console.log("Sidebar user bilgisi:", user)}
            <div
              onClick={toggleProfileMenu}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: isExpanded ? '10px' : '10px 0',
                cursor: 'pointer',
                justifyContent: isExpanded ? 'flex-start' : 'center',
                overflow: 'hidden',
              }}
            >
              <div 
                className={`${styles.profileAvatar} ${isIOS ? 'glass-effect' : ''}`}
                style={{
                  margin: isExpanded ? '0 10px 0 0' : '0 auto',
                  flexShrink: 0
                }}
              >
                {user?.name ? user.name.charAt(0) : '?'}
              </div>
              {isExpanded && (
                <>
                  <div style={{ flex: 1, minWidth: 0, maxWidth: 'calc(100% - 80px)' }}>
                    <div style={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis' }}>{user?.name || 'Kullanıcı'}</div>
                    <div style={{ fontSize: '0.8em', opacity: 0.7, overflow: 'hidden', textOverflow: 'ellipsis' }}>{user?.email || 'kullanici@email.com'}</div>
                  </div>
                  <FaAngleDown style={{ transition: 'transform 0.3s', transform: isProfileMenuOpen ? 'rotate(180deg)' : 'rotate(0)', flexShrink: 0 }} />
                </>
              )}
            </div>

            <CSSTransition
              in={isProfileMenuOpen && isExpanded}
              timeout={300}
              classNames="menu"
              unmountOnExit
              nodeRef={nodeRef}
            >
              <div ref={nodeRef} className={`${styles.profileMenu} ${isIOS ? 'glass-effect' : ''}`}>
                <div
                  onClick={() => handleMenuItemClick('Profile', '/profile')}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <FaUser style={{ marginRight: '10px', flexShrink: 0 }} />
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Profili Görüntüle</span>
                </div>
                <div
                  onClick={() => {
                    handleLogout();
                    setIsProfileMenuOpen(false);
                  }}
                  style={{
                    padding: '10px',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    overflow: 'hidden',
                  }}
                >
                  <FaSignOutAlt style={{ marginRight: '10px', flexShrink: 0 }} />
                  <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>Çıkış Yap</span>
                </div>
              </div>
            </CSSTransition>
          </div>
        )}
      </div>
      {isMobileView && isExpanded && (
        <div
          onClick={() => toggleSidebar(false)}
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100vw',
            height: '100vh',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            zIndex: 999,
          }}
        />
      )}
    </>
  );
};

export default Sidebar;