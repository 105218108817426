import api from './api.js';
import { jwtDecode } from 'jwt-decode';

const authService = {
  register: async (name, email, password) => {
    try {
      const response = await api.post('/users/register', { name, email, password });
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 409) {
        throw new Error('Bu e-posta adresi zaten kullanılıyor. Lütfen başka bir e-posta adresi deneyin veya giriş yapın.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else if (error.response) {
        throw new Error(error.response.data.message || 'Kayıt işlemi başarısız oldu. Lütfen bilgilerinizi kontrol edin.');
      } else if (error.request) {
        throw new Error('Sunucudan yanıt alınamadı. Lütfen internet bağlantınızı kontrol edin.');
      } else {
        throw new Error('Beklenmeyen bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },
  
  login: async (email, password, rememberMe) => {
    try {
      console.log('Sending login request:', { email, password, rememberMe });
      const response = await api.post('/users/login', { email, password, rememberMe });
      console.log('Login response:', response.data);
      if (response.data.token) {
        const userData = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          role: response.data.role
        };
        const storage = rememberMe ? localStorage : sessionStorage;
        storage.setItem('token', response.data.token);
        storage.setItem('user', JSON.stringify(userData));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        console.log('Token and user saved:', response.data.token, userData);
        return { token: response.data.token, user: userData };
      } else {
        console.error('Login response does not contain token');
        throw new Error('Sistem hatası: Giriş bilgileri eksik.');
      }
    } catch (error) {
      console.error('Login error:', error.response ? error.response.data : error.message);
      if (error.response && error.response.data && error.response.data.message === 'Email or password is incorrect') {
        throw new Error('E-posta veya şifre hatalı. Lütfen bilgilerinizi kontrol edin.');
      } else if (error.response && error.response.data && error.response.data.needsVerification) {
        throw error; // Doğrulama hatalarını olduğu gibi ilet, çünkü bunlar özel işlenir
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('Giriş yapılırken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },

  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    delete api.defaults.headers.common['Authorization'];
  },

  getCurrentUser: () => {
    const userDataString = localStorage.getItem('user') || sessionStorage.getItem('user');
    if (!userDataString) return null;
    try {
      const userData = JSON.parse(userDataString);
      
      // Veri yapısı tutarlılığını sağlayalım
      if (userData.user) {
        // Eğer {user: {...}} şeklindeyse
        return userData.user;
      } else if (userData.email || userData.name || userData.id) {
        // Eğer direkt kullanıcı objesi ise
        return userData;
      } else {
        console.error('Geçersiz kullanıcı veri formatı:', userData);
        return null;
      }
    } catch (error) {
      console.error('Error parsing user data:', error);
      return null;
    }
  },
  
  setAuthHeader: () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      console.log('No token found in storage');
    }
  },

  getProfileInfo: async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));

      console.log('Token from storage:', token); // Debugging için
      console.log('User from storage:', user); // Debugging için

      if (!token || !user) {
        throw new Error('No token or user found');
      }

      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      
      const response = await api.get(`/users/profile/${user.id}`);
      return response.data;
    } catch (error) {
      console.error('Error in getProfileInfo:', error.response?.data || error.message);
      throw error;
    }
  },

  updateProfile: async (profileData) => {
    try {
      const user = JSON.parse(sessionStorage.getItem('user') || '{}');
      const response = await api.put(`/users/profile/${user.id}`, profileData);
      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error('Profil güncellenirken bir hata oluştu');
      }
    } catch (error) {
      throw error;
    }
  },

  forgotPassword: async (email) => {
    try {
      console.log('Sending forgot password request for email:', email);
      const response = await api.post('/users/forgot-password', { email });
      console.log('Forgot password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in forgotPassword:', error);
      console.error('Forgot password error details:', {
        message: error.message,
        status: error.response?.status,
        data: error.response?.data
      });
      
      if (error.response && error.response.status === 404) {
        throw new Error('Bu e-posta adresi ile kayıtlı bir hesap bulunamadı.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('Şifre sıfırlama isteği gönderilemedi. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },

  validateResetToken: async (token) => {
    try {
      console.log('Validating reset token:', token);
      const fullUrl = `/users/validate-reset-token/${token}`;
      console.log('Full validate token URL:', fullUrl);
      const response = await api.get(fullUrl);
      console.log('Validate token response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error validating reset token:', error);
      console.error('Validate token error details:', {
        token: token,
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
      
      if (error.response && error.response.status === 404) {
        throw new Error('Geçersiz veya süresi dolmuş şifre sıfırlama bağlantısı. Lütfen yeni bir şifre sıfırlama isteği gönderin.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('Şifre sıfırlama bağlantısı doğrulanamadı. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },

  resetPassword: async (token, newPassword) => {
    try {
      console.log('Resetting password with token:', token?.substring(0, 5) + '...');
      console.log('Request payload:', { token: token?.substring(0, 5) + '...', passwordLength: newPassword?.length });
      const response = await api.post('/users/reset-password', { token, newPassword });
      console.log('Reset password response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error resetting password:', error);
      console.error('Reset password error details:', {
        token: token?.substring(0, 5) + '...',
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
      
      if (error.response && error.response.status === 404) {
        throw new Error('Geçersiz veya süresi dolmuş şifre sıfırlama bağlantısı. Lütfen yeni bir şifre sıfırlama isteği gönderin.');
      } else if (error.response && error.response.status === 400) {
        throw new Error('Şifre gereksinimlerini karşılamıyor. Şifreniz en az 8 karakter uzunluğunda olmalıdır.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('Şifreniz değiştirilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },

  changePassword: async (currentPassword, newPassword) => {
    try {
      const user = authService.getCurrentUser();
      if (!user || !user.id) {
        throw new Error('User information not found');
      }

      const response = await api.put(`/users/${user.id}/change-password`, { currentPassword, newPassword });
      console.log('Password change response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error changing password:', error.response?.data || error.message);
      throw error;
    }
  },

  googleLogin: async (credential) => {
    try {
      const response = await api.post('/auth/google', { token: credential });
      if (response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        console.log('Google login token saved:', response.data.token);
      }
      return response.data;
    } catch (error) {
      console.error('Google login error:', error);
      throw error;
    }
  },

  googleRegister: async (credential) => {
    try {
      const response = await api.post('/auth/google-register', { credential });
      if (response.data.token && response.data.user) {
        sessionStorage.setItem('token', response.data.token);
        sessionStorage.setItem('user', JSON.stringify(response.data.user));
        api.defaults.headers.common['Authorization'] = `Bearer ${response.data.token}`;
        return response.data;
      } else {
        throw new Error('Google ile kayıt başarısız oldu');
      }
    } catch (error) {
      console.error('Google kayıt hatası:', error);
      throw error;
    }
  },

  checkAuthStatus: () => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const user = JSON.parse(localStorage.getItem('user') || sessionStorage.getItem('user'));
    console.log('Current auth status - Token:', token, 'User:', user);
    return { token, user };
  },

  // E-posta doğrulama fonksiyonları
  verifyEmail: async (token) => {
    try {
      console.log('Verifying email with token:', token);
      const response = await api.get(`/users/verify-email/${token}`);
      console.log('Email verification response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error verifying email:', error);
      console.error('Error details:', error.response?.data, error.message);
      
      // Eğer doğrulama yaptıysa ama 404 döndüyse, başarılı sayalım
      if (error.response?.status === 404 && error.response?.data?.message?.includes('not found')) {
        console.log('Token not found, but this may be because email was already verified');
        return { message: 'E-posta adresiniz başarıyla doğrulandı. Artık giriş yapabilirsiniz.' };
      }
      
      if (error.response && error.response.status === 404) {
        throw new Error('Geçersiz veya süresi dolmuş e-posta doğrulama bağlantısı. Lütfen yeni bir doğrulama e-postası isteyin.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('E-posta doğrulama işlemi sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  },

  resendVerificationEmail: async (email) => {
    try {
      const response = await api.post('/users/resend-verification', { email });
      return response.data;
    } catch (error) {
      console.error('Error resending verification email:', error.response?.data || error.message);
      
      if (error.response && error.response.status === 404) {
        throw new Error('Bu e-posta adresi ile kayıtlı bir hesap bulunamadı veya hesabınız zaten doğrulanmış.');
      } else if (error.userFriendlyMessage) {
        throw new Error(error.userFriendlyMessage);
      } else {
        throw new Error('Doğrulama e-postası gönderilirken bir hata oluştu. Lütfen daha sonra tekrar deneyin.');
      }
    }
  }
};

export default authService;