import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Form, Modal, Alert, Spinner, Row, Col } from 'react-bootstrap'
import axios from 'axios';
// import { ReactComponent as Logo } from '../assets/logo.svg';
import { FaMicrophone, FaPaperPlane, FaHandPaper } from 'react-icons/fa';
import { AlignLeft } from 'lucide-react';
import socketService from '../services/socketService';

// Logo dosyası bulunamadığı için geçici olarak sade bir çözüm
const Logo = () => <div style={{ width: '100px', height: '30px', background: '#0E2C5E', color: 'white', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '4px' }}>InterEdu</div>;

interface Speaker {
  id: string;
  name: string;
}

interface Session {
  id: string;
  name: string;
  description: string;
  speakers: Speaker[];
}

const ParticipantPanel: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [session, setSession] = useState<Session | null>(null);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [question, setQuestion] = useState('');
  const [name, setName] = useState('');
  const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);
  const [readQuestion, setReadQuestion] = useState(false);
  const [showSummary, setShowSummary] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortedSpeakers, setSortedSpeakers] = useState<Speaker[]>([]);
  const [showSpeakRequestConfirm, setShowSpeakRequestConfirm] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);
  const [charCount, setCharCount] = useState(0);

  // Success mesajını 3 saniye sonra otomatik kapat
  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, [success]);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(`/api/sessions/validate/${token}`);
        if (response.data && response.data.id) {
          setSession(response.data);
          // Konuşmacıları sırala
          if (response.data.speakers) {
            const sorted = [...response.data.speakers].sort((a, b) => {
              const nameComparison = a.name.localeCompare(b.name);
              if (nameComparison === 0) {
                return parseInt(a.id) - parseInt(b.id);
              }
              return nameComparison;
            });
            setSortedSpeakers(sorted);
          }
        } else {
          setError('Geçersiz oturum verisi alındı.');
        }
      } catch (error) {
        console.error('Oturum verisi alınırken hata oluştu:', error);
        setError('Oturum bilgileri alınamadı. Lütfen daha sonra tekrar deneyin.');
      } finally {
        setIsLoading(false);
      }
    };

    if (token) {
      fetchSessionData();
    }
  }, [token]);

  useEffect(() => {
    if (session?.id) {
      const socket = socketService.connect(session.id);
      
      return () => {
        socketService.disconnect();
      };
    }
  }, [session?.id]);

  const handleSpeakerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedSpeakers(prevSelected => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter(speaker => speaker !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (name.length < 3 && !isAnonymous) {
      setNameError("İsim en az 3 karakter olmalıdır.");
      return;
    }
    setNameError(null);
    setShowSummary(true);
  };

  const handleConfirmSubmit = async (onlyRequestToSpeak = false) => {
    try {
      const payload = {
        sessionId: session?.id,
        name: isAnonymous ? 'Anonim' : name,
        question: onlyRequestToSpeak ? 'Söz isteme talebi' : question,
        speakerIds: selectedSpeakers,
        readByParticipant: readQuestion,
        onlyRequestToSpeak
      };

      console.log('Sending payload:', payload);

      const response = await axios.post('/api/questions', payload);

      if (response.status === 201) {
        setShowSummary(false);
        setQuestion('');
        setSelectedSpeakers([]);
        setReadQuestion(false);
        setSuccess(onlyRequestToSpeak ? 'Söz isteğiniz başarıyla gönderildi!' : 'Sorunuz başarıyla gönderildi!');
        
        // Soruyu socket üzerinden yayınla
        socketService.emit('newQuestion', response.data);
      } else {
        throw new Error('Beklenmeyen bir yanıt alındı');
      }
    } catch (error) {
      console.error('Soru/İstek gönderilirken hata:', error);
      if (axios.isAxiosError(error) && error.response) {
        const errorMessage = error.response.data.message || error.message;
        setError(`Soru/İstek gönderilirken bir hata oluştu: ${errorMessage}`);
      } else {
        setError('Soru/İstek gönderilirken bilinmeyen bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };
  const handleSpeakRequest = () => {
    if (name.length < 3 && !isAnonymous) {
      setNameError("İsim en az 3 karakter olmalıdır.");
      return;
    }
    setNameError(null);
    setReadQuestion(false);
    setShowSpeakRequestConfirm(true);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 50);
    setName(value);
    setNameError(null);
  };

  const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value.slice(0, 500);
    setQuestion(value);
    setCharCount(value.length);
  };

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  if (!session) {
    return (
      <Alert variant="warning" className="m-3">
        Oturum bulunamadı. Lütfen geçerli bir bağlantı kullandığınızdan emin olun.
      </Alert>
    );
  }

  return (
    <>
      <style>
        {`
          .conference-card {
            max-width: 28rem;
            min-width: 300px;
            width: 100%;
            margin: 1rem;
          }
  
          .button-container {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 10px;
          }
  
          .action-button {
            flex: 1 1 calc(50% - 5px);
            min-width: 120px;
          }
  
          @media (max-width: 576px) {
            .action-button {
              flex: 1 1 100%;
            }
          }

          /* Yeni eklenen stil kuralları */
          .question-summary-modal .modal-content {
            border-radius: 15px;
            border: none;
            background-color: #f8f9fa;
            overflow: hidden;
          }

          .summary-card {
            border-radius: 12px;
            transition: all 0.3s ease;
            background-color: white;
          }

          .summary-card:hover {
            transform: translateY(-3px);
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.08) !important;
          }

          .card-icon-container {
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: rgba(13, 110, 253, 0.1);
            border-radius: 10px;
          }

          .summary-value {
            font-size: 1rem;
            color: #495057;
            margin-bottom: 0;
            padding-left: 3.5rem;
          }

          .question-text {
            font-style: italic;
            line-height: 1.5;
          }

          .summary-cards {
            padding: 0.5rem;
          }

          /* Mobil uyumluluk için ek stiller */
          @media (max-width: 768px) {
            .question-summary-modal .modal-content {
              border-radius: 10px;
              margin: 0.5rem;
            }
            
            .card-icon-container {
              width: 36px;
              height: 36px;
              min-width: 36px;
            }
            
            .summary-card h5 {
              font-size: 1rem;
            }
            
            .summary-value {
              font-size: 0.9rem;
              padding-left: 0;
              margin-top: 0.5rem;
            }
            
            .modal-header, .modal-footer {
              padding: 0.75rem 1rem;
            }
            
            .modal-body {
              padding: 0.5rem;
            }
          }
        `}
      </style>
      <div className="admin-panel d-flex align-items-center justify-content-center min-vh-100">
        <Card className="conference-card">
          <Card.Header className="conference-card-header text-center">
            <h2 className="font-weight-bold mt-2">{session.name}</h2>
          </Card.Header>
          <Card.Body>
            {success && (
              <Alert variant="success" onClose={() => setSuccess(null)} dismissible>
                {success}
              </Alert>
            )}
            <Form onSubmit={handleFormSubmit}>
              <Form.Group controlId="name" className="mb-3">
                <Form.Label>İsim (Opsiyonel)</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="İsminizi girin (en fazla 50 karakter)"
                  value={name}
                  onChange={handleNameChange}
                  disabled={isAnonymous}
                  isInvalid={!!nameError}
                  maxLength={50}
                />
                <Form.Control.Feedback type="invalid">
                  {nameError}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="anonymous" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Anonim olarak katıl"
                  checked={isAnonymous}
                  onChange={() => {
                    setIsAnonymous(!isAnonymous);
                    if (!isAnonymous) {
                      setReadQuestion(false);
                    }
                  }}
                />
              </Form.Group>
              <Form.Group controlId="question" className="mb-3">
                <Form.Label>Sorunuz</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={5}
                  placeholder="Sorunuzu buraya yazın (en fazla 500 karakter)"
                  value={question}
                  onChange={handleQuestionChange}
                  required
                  maxLength={500}
                />
                <div className="text-end mt-1">
                  <small className="text-white">{charCount}/500</small>
                </div>
              </Form.Group>
  
              <div className="d-flex align-items-center mb-3">
                <Form.Check
                  type="checkbox"
                  id="readQuestion"
                  checked={readQuestion}
                  onChange={() => setReadQuestion(!readQuestion)}
                  disabled={isAnonymous}
                />
                <label htmlFor="readQuestion" className="ms-2 d-flex align-items-center">
                  <FaMicrophone className="me-2" />
                  Soruyu kendim okumak istiyorum
                </label>
              </div>
  
              <hr />
  
              {sortedSpeakers.length > 0 && (
                <Form.Group controlId="speaker" className="mb-3">
                  <Form.Label>Konuşmacı(lar)</Form.Label>
                  {sortedSpeakers.map((speaker) => (
                    <Form.Check
                      key={speaker.id}
                      type="checkbox"
                      label={speaker.name}
                      value={speaker.id}
                      checked={selectedSpeakers.includes(speaker.id)}
                      onChange={handleSpeakerChange}
                    />
                  ))}
                </Form.Group>
              )}
              <div className="button-container">
                <Button variant="primary" type="submit" className="action-button">
                  <FaPaperPlane className="me-2" />
                  Soru Gönder
                </Button>
                <Button 
                  variant="outline-secondary" 
                  onClick={handleSpeakRequest} 
                  disabled={isAnonymous}
                  className="action-button"
                >
                  <FaHandPaper className="me-2" />
                  Sadece Söz İste
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
  
        <Modal show={showSummary} onHide={() => setShowSummary(false)} size="lg" centered className="question-summary-modal">
          <Modal.Header closeButton className="border-0 pb-0">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <Logo />
              <h4 className="mb-0 text-primary fw-bold">Soru Özeti</h4>
            </div>
          </Modal.Header>
          <Modal.Body className="pt-2">
            <div className="summary-cards">
              <Row className="g-3">
                <Col md={6}>
                  <Card className="summary-card h-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <div className="card-icon-container me-3">
                          <FaHandPaper className="text-primary" />
                        </div>
                        <h5 className="mb-0">Katılımcı</h5>
                      </div>
                      <p className="summary-value">{isAnonymous ? 'Anonim' : name}</p>
                    </Card.Body>
                  </Card>
                </Col>
                
                {sortedSpeakers.length > 0 && selectedSpeakers.length > 0 && (
                <Col md={6}>
                  <Card className="summary-card h-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <div className="card-icon-container me-3">
                          <FaMicrophone className="text-primary" />
                        </div>
                        <h5 className="mb-0">Konuşmacı</h5>
                      </div>
                      <p className="summary-value">
                        {selectedSpeakers.map(id => sortedSpeakers.find(s => s.id === id)?.name).join(', ')}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
                )}
                
                <Col md={12}>
                  <Card className="summary-card h-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <div className="card-icon-container me-3">
                          <FaPaperPlane className="text-primary" />
                        </div>
                        <h5 className="mb-0">Soru</h5>
                      </div>
                      <p className="summary-value question-text">{question}</p>
                    </Card.Body>
                  </Card>
                </Col>
                
                <Col md={6}>
                  <Card className="summary-card h-100 border-0 shadow-sm">
                    <Card.Body>
                      <div className="d-flex align-items-center mb-3">
                        <div className="card-icon-container me-3">
                          <AlignLeft className="text-primary" size={18} />
                        </div>
                        <h5 className="mb-0">Okuma Tercihi</h5>
                      </div>
                      <p className="summary-value">
                        {readQuestion ? 'Soruyu kendim okumak istiyorum' : 'Soruyu moderatör okuyabilir'}
                      </p>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0 pt-0">
            <Button variant="outline-secondary" onClick={() => setShowSummary(false)}>
              İptal
            </Button>
            <Button variant="primary" onClick={() => handleConfirmSubmit(false)}>
              Onayla ve Gönder
            </Button>
          </Modal.Footer>
        </Modal>
  
        <Modal show={showSpeakRequestConfirm} onHide={() => setShowSpeakRequestConfirm(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Söz İsteme Onayı</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Sadece söz istemek istediğinizden emin misiniz?
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowSpeakRequestConfirm(false)}>
              İptal
            </Button>
            <Button variant="primary" onClick={() => {
              handleConfirmSubmit(true);
              setShowSpeakRequestConfirm(false);
            }}>
              Onayla
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ParticipantPanel;