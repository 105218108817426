import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link, useLocation } from 'react-router-dom';
import authService from '../services/authService';

const ResetPasswordPage = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [tokenStatus, setTokenStatus] = useState({
    loading: true,
    valid: false,
    message: 'Token kontrol ediliyor...'
  });
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: false,
    message: ''
  });

  useEffect(() => {
    console.log('ResetPasswordPage yüklendi, URL:', window.location.href);
    console.log('Token değeri:', token);
    console.log('Tam URL yolu:', location.pathname);
    
    // Token kontrolü
    if (!token) {
      console.error('Token bulunamadı!');
      setTokenStatus({
        loading: false,
        valid: false,
        message: 'Geçersiz token. Şifre sıfırlama bağlantınız hatalı.'
      });
      return;
    }

    const validateToken = async () => {
      try {
        console.log('Validating reset token:', token);
        await authService.validateResetToken(token);
        console.log('Token başarıyla doğrulandı');
        setTokenStatus({
          loading: false,
          valid: true,
          message: 'Token geçerli. Yeni şifrenizi belirleyebilirsiniz.'
        });
      } catch (error) {
        console.error('Error validating token:', error);
        console.error('Token doğrulama hatası ayrıntıları:', {
          token: token,
          errorMessage: error.message
        });
        setTokenStatus({
          loading: false,
          valid: false,
          message: error.message || 'Geçersiz veya süresi dolmuş token. Şifre sıfırlama bağlantınız artık geçerli değil.'
        });
      }
    };

    validateToken();
  }, [token, location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Şifre kontrolü
    if (!newPassword || !confirmPassword) {
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: 'Tüm alanları doldurun.'
      });
      return;
    }

    if (newPassword.length < 8) {
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: 'Şifre en az 8 karakter uzunluğunda olmalıdır.'
      });
      return;
    }

    if (newPassword !== confirmPassword) {
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: 'Şifreler eşleşmiyor.'
      });
      return;
    }

    try {
      setStatus({
        loading: true,
        success: false,
        error: false,
        message: 'Şifreniz değiştiriliyor...'
      });
      
      console.log('Şifre sıfırlama isteği gönderiliyor...');
      await authService.resetPassword(token, newPassword);
      console.log('Şifre sıfırlama başarılı');
      
      setStatus({
        loading: false,
        success: true,
        error: false,
        message: 'Şifreniz başarıyla değiştirildi. Giriş sayfasına yönlendiriliyorsunuz...'
      });

      // 3 saniye sonra giriş sayfasına yönlendir
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      console.error('Şifre sıfırlama hatası:', error);
      setStatus({
        loading: false,
        success: false,
        error: true,
        message: error.message || 'Şifre değiştirme işlemi sırasında bir hata oluştu.'
      });
    }
  };

  const containerStyle = {
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#0E2C5E',
    color: 'white',
    fontFamily: 'Arial, sans-serif'
  };

  const cardStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    backdropFilter: 'blur(10px)',
    borderRadius: '15px',
    padding: '30px',
    maxWidth: '500px',
    width: '90%',
    boxShadow: '0 4px 30px rgba(0, 0, 0, 0.3)',
    border: '1px solid rgba(255, 255, 255, 0.2)'
  };

  const inputStyle = {
    width: '100%',
    padding: '12px',
    marginTop: '10px',
    marginBottom: '20px',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: '5px',
    color: 'white',
    fontSize: '16px'
  };

  const buttonStyle = {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    color: 'white',
    border: 'none',
    padding: '12px 25px',
    borderRadius: '5px',
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '10px',
    transition: 'background-color 0.3s',
    width: '100%'
  };

  const linkStyle = {
    color: 'rgba(255, 255, 255, 0.7)',
    textDecoration: 'none',
    marginTop: '20px',
    display: 'inline-block'
  };

  const statusMessageStyle = {
    padding: '10px',
    marginTop: '15px',
    borderRadius: '5px',
    backgroundColor: status.error 
      ? 'rgba(255, 0, 0, 0.2)' 
      : status.success 
        ? 'rgba(0, 255, 0, 0.2)' 
        : tokenStatus.valid === false
          ? 'rgba(255, 0, 0, 0.2)'
          : 'transparent'
  };

  return (
    <div style={containerStyle}>
      <div style={cardStyle}>
        <h1>Şifre Sıfırlama</h1>
        
        {tokenStatus.loading ? (
          <div style={statusMessageStyle}>
            <p>{tokenStatus.message}</p>
          </div>
        ) : !tokenStatus.valid ? (
          <div>
            <div style={statusMessageStyle}>
              <p>{tokenStatus.message}</p>
            </div>
            <div style={{ marginTop: '20px', textAlign: 'center' }}>
              <Link to="/forgot-password" style={linkStyle}>Yeni şifre sıfırlama bağlantısı talep et</Link>
            </div>
          </div>
        ) : (
          <div>
            <p>Lütfen yeni şifrenizi belirleyin:</p>
            
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="newPassword">Yeni Şifre</label>
                <input
                  type="password"
                  id="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  style={inputStyle}
                  placeholder="Yeni şifreniz (en az 8 karakter)"
                  disabled={status.loading || status.success}
                />
              </div>
              
              <div>
                <label htmlFor="confirmPassword">Şifre Tekrar</label>
                <input
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  style={inputStyle}
                  placeholder="Şifrenizi tekrar girin"
                  disabled={status.loading || status.success}
                />
              </div>
              
              {status.message && (
                <div style={statusMessageStyle}>
                  <p>{status.message}</p>
                </div>
              )}
              
              <button 
                type="submit" 
                style={buttonStyle}
                disabled={status.loading || status.success}
              >
                {status.loading ? 'İşleniyor...' : 'Şifremi Değiştir'}
              </button>
            </form>
            
            {!status.success && (
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <Link to="/login" style={linkStyle}>Giriş sayfasına dön</Link>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ResetPasswordPage; 