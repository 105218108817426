import axios from 'axios';

const api = axios.create({
//baseURL: 'http://localhost:3000/api',
baseURL:'https://interedu.app/api',

  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// API Base URL'yi konsola yazdır
console.log('API Base URL:', api.defaults.baseURL);

// Kullanıcı dostu hata mesajları
const getErrorMessage = (error) => {
  if (!error.response) {
    return 'Sunucuya bağlanılamadı. Lütfen internet bağlantınızı kontrol edin.';
  }

  const { status, data } = error.response;
  
  // Eğer API kendi hata mesajı döndürmüşse, onu kullan
  if (data && data.message) {
    return data.message;
  }
  
  // Genel HTTP durum kodları için kullanıcı dostu mesajlar
  const statusMessages = {
    400: 'Geçersiz istek. Lütfen bilgilerinizi kontrol edip tekrar deneyin.',
    401: 'Oturum süreniz dolmuş. Lütfen tekrar giriş yapın.',
    403: 'Bu işlem için gerekli yetkiniz bulunmuyor.',
    404: 'İstediğiniz kaynak bulunamadı.',
    409: 'İşlem sırasında bir çakışma oluştu. Lütfen tekrar deneyin.',
    422: 'Girdiğiniz bilgiler doğrulanamadı. Lütfen kontrol edip tekrar deneyin.',
    429: 'Çok fazla istek gönderdiniz. Lütfen kısa bir süre bekleyip tekrar deneyin.',
    500: 'Sunucu hatası. Lütfen daha sonra tekrar deneyin.',
    502: 'Sunucu şu anda yanıt vermiyor. Lütfen daha sonra tekrar deneyin.',
    503: 'Servis şu anda kullanılamıyor. Lütfen daha sonra tekrar deneyin.',
    504: 'Sunucu yanıt vermedi. Lütfen internet bağlantınızı kontrol edin.'
  };
  
  return statusMessages[status] || `Bir hata oluştu (${status}). Lütfen daha sonra tekrar deneyin.`;
};

api.interceptors.request.use((config) => {
  // Her istekten önce URL'yi loglayalım
  console.log('Request URL:', `${api.defaults.baseURL}${config.url}`);
  
  const token = sessionStorage.getItem('token');
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
    console.log('Sending token in request:', token); // Debugging için
  } else {
    console.log('No token found in sessionStorage'); // Debugging için
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  console.error('API error:', error.response); // Debugging için
  
  // Şifre sıfırlama hatalarında daha detaylı bilgi
  if (error.config && (error.config.url.includes('/reset-password') || 
                      error.config.url.includes('/validate-reset-token') || 
                      error.config.url.includes('/forgot-password'))) {
    console.error('Şifre sıfırlama hatası:', {
      url: error.config.url,
      method: error.config.method,
      status: error.response?.status,
      data: error.response?.data
    });
  }
  
  // Kullanıcı dostu hata mesajı oluştur ve hata nesnesine ekle
  if (error.response) {
    error.userFriendlyMessage = getErrorMessage(error);
  }
  
  // Kimlik doğrulama hatalarını ele al
  if (error.response && error.response.status === 401) {
    console.log('Authentication error - redirecting to login');
    // Sessionları temizle
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    
    // Kullanıcıyı giriş sayfasına yönlendir
    if (!window.location.pathname.includes('/login')) {
      window.location.href = '/login';
    }
  }
  
  return Promise.reject(error);
});

export default api;