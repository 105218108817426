import React, { useState, useRef, useEffect } from 'react';
import { FaMicrophone, FaHandPaper, FaTrash } from "react-icons/fa";
import { motion, useAnimation } from 'framer-motion';
import { openQuestionInNewWindow } from '../utils/SingleQuestionDisplay';
import * as COLORS from '../../styles/colors';

interface Speaker {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface Question {
  id: number;
  text: string;
  asker: string;
  status: string;
  wantsToAsk: boolean;
  onlyTakeFloor: boolean;
  speakers: Speaker[];
}

interface QuestionCardProps {
  question: Question;
  onStatusChange: (questionId: number, newStatus: string) => void;
  onEdit: (question: Question) => void;
  onView: (question: Question) => void;
  onDelete: (questionId: number) => void;
  isSpeaker?: boolean;
}

const QuestionCard: React.FC<QuestionCardProps> = ({ question, onStatusChange, onEdit, onView, onDelete, isSpeaker = false }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [localStatus, setLocalStatus] = useState(question.status);
  const [swipeProgress, setSwipeProgress] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  const cardRef = useRef<HTMLDivElement>(null);
  const controls = useAnimation();

  useEffect(() => {
    const checkScreenSize = () => {
      setIsLargeScreen(window.innerWidth >= 768); // 768px'i büyük ekran olarak kabul ediyoruz
    };

    // iOS cihaz tespiti
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                       (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(isIOSDevice);

    checkScreenSize(); // İlk yükleme kontrolü
    window.addEventListener('resize', checkScreenSize);

    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (cardRef.current && !cardRef.current.contains(event.target as Node)) {
        setIsFlipped(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (question && question.status) {
      setLocalStatus(question.status);
    }
  }, [question]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Cevaplandı':
      case 'Onaylandı':
        return '#10B981';
      case 'Reddedildi':
        return '#EF4444';
      case 'Beklemede':
        return '#F59E0B';
      default:
        return '#3B82F6';
    }
  };

  const getStatusShadow = (status: string) => {
    const color = getStatusColor(status);
    return `0 4px 20px ${color}40, 0 7px 10px -5px ${color}33`;
  };

  const truncateText = (text: string, maxLength: number) => {
    if (text.length <= maxLength) return text;
    return text.slice(0, maxLength) + '...';
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete(question.id);
  };

  const getCardFaceBackground = () => {
    if (isIOS) {
      // iOS için alternatif arka plan
      return {
        background: `linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))`,
        boxShadow: `0 4px 20px rgba(0, 0, 0, 0.25), 0 7px 10px -5px rgba(0, 0, 0, 0.2)`,
        border: '1px solid rgba(255, 255, 255, 0.2)',
      };
    }
    
    return {
      backdropFilter: 'blur(10px)',
      WebkitBackdropFilter: 'blur(10px)', // Safari için
      backgroundImage: 'radial-gradient(circle at top right, rgba(255, 255, 255, 0.2) 0%, transparent 70%)',
      border: '1px solid rgba(255, 255, 255, 0.2)',
      boxShadow: getStatusShadow(question.status),
    };
  };

  const getCardBackBackground = () => {
    if (isIOS) {
      // iOS için alternatif arka plan
      return {
        background: `linear-gradient(180deg, rgba(60, 60, 80, 0.8), rgba(40, 40, 60, 0.8))`,
        boxShadow: `0 4px 20px rgba(0, 0, 0, 0.25), 0 7px 10px -5px rgba(0, 0, 0, 0.2)`,
        border: '1px solid rgba(255, 255, 255, 0.2)',
      };
    }
    
    return {
      backdropFilter: 'blur(30px)',
      WebkitBackdropFilter: 'blur(30px)', // Safari için
      background: 'radial-gradient(circle at center, rgba(255, 255, 255, 0.2), transparent)',
    };
  };

  const cardStyle = {
    width: '100%',
    maxWidth: '700px',
    height: '550px',
    perspective: '1000px',
    margin: '0 auto',
    fontFamily: "'Inter', sans-serif",
    cursor: 'pointer',
    minWidth: '280px',
  } as React.CSSProperties;

  const getCardInnerStyle = () => {
    // iOS için düz geçiş efekti
    if (isIOS) {
      return {
        position: 'relative',
        width: '100%',
        height: '100%',
        transition: 'opacity 0.5s ease',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme efekti
    return {
      position: 'relative',
      width: '100%',
      height: '100%',
      transition: 'transform 0.8s',
      transformStyle: 'preserve-3d',
      transform: isFlipped ? 'rotateY(180deg)' : 'rotateY(0)',
    } as React.CSSProperties;
  };

  const getCardFaceStyle = () => {
    const baseStyle = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      padding: '30px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: 'white',
      ...getCardFaceBackground(),
    } as React.CSSProperties;

    // iOS için düz geçiş efekti - opacity ile
    if (isIOS) {
      return {
        ...baseStyle,
        backfaceVisibility: 'visible',
        opacity: isFlipped ? 0 : 1,
        zIndex: isFlipped ? 0 : 1,
        transition: 'opacity 0.5s ease, z-index 0.5s',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme
    return {
      ...baseStyle,
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      transform: 'rotateY(0deg)',
    } as React.CSSProperties;
  };

  const getCardBackStyle = () => {
    const backBase = {
      position: 'absolute',
      width: '100%',
      height: '100%',
      borderRadius: '20px', 
      padding: '30px',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      color: 'white',
      overflowY: 'auto',
      ...getCardBackBackground(),
    } as React.CSSProperties;

    // iOS için düz geçiş efekti - opacity ile
    if (isIOS) {
      return {
        ...backBase,
        backfaceVisibility: 'visible',
        opacity: isFlipped ? 1 : 0,
        zIndex: isFlipped ? 1 : 0,
        transition: 'opacity 0.5s ease, z-index 0.5s',
      } as React.CSSProperties;
    }
    
    // Diğer platformlar için 3D çevirme
    return {
      ...backBase,
      backfaceVisibility: 'hidden',
      WebkitBackfaceVisibility: 'hidden',
      transform: 'rotateY(180deg)',
    } as React.CSSProperties;
  };

  const baseButtonStyle = {
    padding: '15px 20px',
    borderRadius: '10px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    fontWeight: '600',
    fontSize: '1rem',
    color: '#E5E7EB',
  } as React.CSSProperties;

  const buttonStyle = {
    ...baseButtonStyle,
    backgroundColor: 'rgba(59, 130, 246, 0.8)',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    ...(isIOS ? { background: 'rgba(59, 130, 246, 0.9)' } : { backdropFilter: 'blur(5px)' }),
  } as React.CSSProperties;

  const buttonStyle2 = {
    ...baseButtonStyle,
    backgroundColor: isIOS ? 'rgba(50, 50, 70, 0.9)' : 'rgba(255, 255, 255, 0.2)',
    ...(isIOS ? {} : { backdropFilter: 'blur(35px)' }),
    border: '1px solid rgba(255, 255, 255, 0.3)',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties;

  const statusStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '8px 15px',
    borderRadius: '20px',
    fontSize: '0.9rem',
    fontWeight: '600',
    color: '#cdd6eb',
    background: getStatusColor(question.status),
  } as React.CSSProperties;

  const progressBarStyle = {
    position: 'absolute',
    bottom: '10px',
    left: '5px',
    width: '98%',
    height: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderBottomLeftRadius: '30px',
    borderBottomRightRadius: '30px',
    overflow: 'hidden',
    opacity: isDragging ? 1 : 0,
    transition: 'opacity 0.3s ease',
  } as React.CSSProperties;

  const progressStyle = {
    height: '100%',
    width: `${Math.abs(swipeProgress)}%`,
    backgroundColor: swipeProgress > 0 ? '#10B981' : '#EF4444',
    transition: 'width 0.1s ease-out',
  } as React.CSSProperties;

  const switchContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: '20px',
    marginBottom: '5px',
  } as React.CSSProperties;

  const sliderStyle = {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '220px',
    height: '50px',
    backgroundColor: '#f0f0f0',
    borderRadius: '25px',
    padding: '5px',
    marginTop: '10px',
    boxShadow: 'inset 0 2px 4px rgba(0, 0, 0, 0.1)',
  } as React.CSSProperties;

  const sliderThumbStyle = {
    width: '77px',
    height: '40px',
    borderRadius: '30px',
    backgroundColor: getStatusColor(localStatus),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    transform: localStatus === 'Onaylandı' || localStatus === 'Cevaplandı'
      ? 'translateX(55px)' 
      : localStatus === 'Reddedildi'
        ? 'translateX(-55px)'
        : 'translateX(0)',
    zIndex: 100,
  } as React.CSSProperties;

  const sliderTextStyle = {
    position: 'absolute',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    transition: 'opacity 0.3s ease',
  } as React.CSSProperties;

  const deleteIconStyle = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    fontSize: '1.2rem',
    color: 'white',
    cursor: 'pointer',
    transition: 'color 0.3s ease',
  } as React.CSSProperties;

  return (
    <motion.div 
      style={cardStyle} 
      ref={cardRef}
      drag="x"
      dragConstraints={{ left: 0, right: 0 }}
      dragElastic={0.2}
      onDragStart={() => setIsDragging(true)}
      onDragEnd={(event, info) => {
        if (Math.abs(info.offset.x) > 100) {
          const newStatus = info.offset.x > 0 
            ? (isSpeaker ? 'Cevaplandı' : 'Onaylandı') 
            : 'Reddedildi';
          
          setLocalStatus(newStatus);
          onStatusChange(question.id, newStatus);
        }
        controls.start({ x: 0 });
        setSwipeProgress(0);
        setIsDragging(false);
      }}
      onDrag={(event, info) => {
        const progress = (info.offset.x / 100) * 100;
        setSwipeProgress(Math.max(-100, Math.min(100, progress)));
      }}
      animate={controls}
      whileDrag={{
        scale: 1.05,
        boxShadow: getStatusShadow(question.status)
      }}
      onClick={() => setIsFlipped(!isFlipped)}
    >
      <div style={getCardInnerStyle()}>
        <div style={getCardFaceStyle()}>
          <div>
            <h3 style={{ margin: '35px 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }} className="truncate">
              {question.asker.length > 50 ? question.asker.slice(0, 25) + '...' : question.asker}
            </h3>
            {!question.onlyTakeFloor && (
              <p style={{ fontSize: '1.1rem', color: '#D1D5DB', maxHeight: '300px', overflow: 'auto', lineHeight: '1.6' }}>
                {truncateText(question.text, 500) || 'Soru metni yok'}
              </p>
            )}
            {question.onlyTakeFloor && (
              <p style={{ fontSize: '2rem', color: '#D1D5DB', textAlign: 'center', marginTop: '80px' }}>
                <FaHandPaper /> Söz İstiyor
              </p>
            )}
          </div>
          {question.wantsToAsk && 
            <FaMicrophone style={{ position: 'absolute', bottom: '100px', right: '-30px', fontSize: '18em', color: '#aaa6a638', zIndex: '-1' }} />
          }
          <div style={statusStyle}>{question.status}</div>
          {isLargeScreen && (
            <button 
              style={buttonStyle2}
              onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = '#fff'; (e.currentTarget).style.color = 'black';}} 
              onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = 'rgba(255, 255, 255, 0.2)'; (e.currentTarget).style.color = '#E5E7EB';}}
              onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.BACKGROUND; (e.currentTarget).style.color = COLORS.BUTTON.SECONDARY.TEXT;}}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); openQuestionInNewWindow(question); }}
            >
              Soruyu Görüntüle
            </button>
          )}
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
        <div style={getCardBackStyle()}>
          <h3 style={{ margin: '0 0 15px 0', color: '#E5E7EB', fontSize: '1.5rem' }}>Soru Detayları</h3>
          <FaTrash
            style={deleteIconStyle}
            onClick={handleDelete}
            onMouseOver={(e) => e.currentTarget.style.color = '#DC2626'}
            onMouseOut={(e) => e.currentTarget.style.color = 'white'}
          />
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Soru Sahibi:</strong> {question.asker}</p>
          <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}><strong>Durum:</strong> {question.status}</p>
          {question.speakers && Array.isArray(question.speakers) && question.speakers.length > 0 && (
            <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}>
              <strong>Konuşmacı(lar):</strong> {
                question.speakers
                  .filter(s => s && s.name)
                  .map(s => s.name)
                  .join(', ')
              }
            </p>
          )}
          {question.wantsToAsk && <p style={{ color: '#D1D5DB', fontSize: '1.1rem' }}>🎤 Soruyu kendisi sormak istiyor</p>}
          <div style={switchContainerStyle}>
            <div style={sliderStyle} onClick={(e) => {
              e.stopPropagation();
              let newStatus;
              
              if (localStatus === 'Beklemede') {
                newStatus = isSpeaker ? 'Cevaplandı' : 'Onaylandı';
              } else if (localStatus === 'Onaylandı' || localStatus === 'Cevaplandı') {
                newStatus = 'Reddedildi';
              } else if (localStatus === 'Reddedildi') {
                newStatus = isSpeaker ? 'Cevaplandı' : 'Onaylandı';
              }
              
              setLocalStatus(newStatus);
              onStatusChange(question.id, newStatus);
            }}>
              <span style={{...sliderTextStyle, left: '20px', opacity: localStatus === 'Reddedildi' ? 1 : 0.5, color: '#EF4444', cursor: 'pointer'} as React.CSSProperties}
                onClick={(e) => {
                  e.stopPropagation();
                  const newStatus = 'Reddedildi';
                  setLocalStatus(newStatus);
                  onStatusChange(question.id, newStatus);
                }}
              >Reddet</span>
              <div style={sliderThumbStyle}>
                {localStatus === 'Beklemede' ? '?' : (localStatus === 'Onaylandı' || localStatus === 'Cevaplandı') ? '✓' : '✗'}
              </div>
              <span style={{...sliderTextStyle, right: '20px', opacity: (localStatus === 'Onaylandı' || localStatus === 'Cevaplandı') ? 1 : 0.5, color: '#10B981', cursor: 'pointer'} as React.CSSProperties}
                onClick={(e) => {
                  e.stopPropagation();
                  const newStatus = isSpeaker ? 'Cevaplandı' : 'Onaylandı';
                  setLocalStatus(newStatus);
                  onStatusChange(question.id, newStatus);
                }}
              >Onayla</span>
            </div>
          </div>
          <button 
            style={{...buttonStyle, marginTop: '20px', backgroundColor: COLORS.BUTTON.PRIMARY.ACTIVE, color: '#E5E7EB'}}
            
            onMouseEnter={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.HOVER; (e.currentTarget).style.color = COLORS.BUTTON.PRIMARY.TEXT;}} 
            onMouseLeave={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.BUTTON.PRIMARY.ACTIVE; (e.currentTarget).style.color = COLORS.BUTTON.PRIMARY.TEXT;}}
            onMouseDown={(e: React.MouseEvent<HTMLButtonElement>) => {(e.currentTarget).style.backgroundColor = COLORS.COLORS.PRIMARY.DARKER; (e.currentTarget).style.color = COLORS.BUTTON.SECONDARY.TEXT;}}
            onClick={(e: React.MouseEvent<HTMLButtonElement>) => { e.stopPropagation(); onEdit(question); }}
          >
            Düzenle
          </button>
          <div style={progressBarStyle}>
            <div style={progressStyle}></div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default QuestionCard;