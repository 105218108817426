import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Card, Button, Form, Alert } from 'react-bootstrap';
import Session from './Session';
import ConfirmationModal from './ConfirmationModal';
import { FaPlus, FaSave, FaEdit, FaPencilAlt } from 'react-icons/fa';
import './styles/Session.css';
import './styles/Styles.css';
import conferenceService from '../services/conferenceService';
import sessionService from '../services/sessionService';
import { CSSTransition } from 'react-transition-group';

// Tip tanımlamaları
interface Speaker {
  id: string;
  name: string;
  email: string;
  role: string;
}

interface SessionType {
  id: string;
  name: string;
  description?: string;
  conference_id: number;
  speakers: Speaker[];
  expiration_date?: string | null;
  auto_approve_questions?: boolean;
  qrCode?: string;
  password?: string;
}

interface Conference {
  id: string;
  name: string;
  date: string;
  isDateRange: boolean;
  endDate: string | null;
  location: string;
  sessions: SessionType[];
  sessionCount?: number;
}

interface DeleteInfo {
  type: string;
  id: string;
}

interface FormErrors {
  name?: string;
  date?: string;
  endDate?: string;
  location?: string;
  [key: string]: string | undefined;
}

const AdminPanel = () => {
  const { id } = useParams<{id?: string}>();
  const navigate = useNavigate();
  const [conference, setConference] = useState<Conference | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState<DeleteInfo | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [errors, setErrors] = useState<FormErrors>({});
  const [error, setError] = useState<string | null>(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [isIOS, setIsIOS] = useState(false);
  
  useEffect(() => {
    // iOS cihaz tespiti
    const isIOSDevice = /iPad|iPhone|iPod/.test(navigator.userAgent) || 
                        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);
    setIsIOS(isIOSDevice);
  }, []);
  
  useEffect(() => {
    if (id) {
      loadConference(id);
    } else {
      // Yeni Etkinlik ekleniyor
      setIsEditing(true);
      setIsSaved(false);
      setShowEditForm(true);
      setConference({
        id: '',
        name: '',
        date: '',
        isDateRange: false,
        endDate: null,
        location: '',
        sessions: [],
      });
    }
  }, [id]);

  const loadConference = async (conferenceId: string) => {
    setIsLoading(true);
    try {
      const conferenceResponse = await conferenceService.getConference(conferenceId);
      const sessionsResponse = await sessionService.getSessionsByConference(conferenceId);
  
      const formattedConference: Conference = {
        ...conferenceResponse.data,
        date: formatDate(conferenceResponse.data.date),
        endDate: conferenceResponse.data.endDate && conferenceResponse.data.endDate !== "0" ? formatDate(conferenceResponse.data.endDate) : null,
        isDateRange: conferenceResponse.data.endDate && conferenceResponse.data.endDate !== "0",
        sessions: sessionsResponse.data || [],
        sessionCount: sessionsResponse.data ? sessionsResponse.data.length : 0
      };
      
      setConference(formattedConference);
      setIsSaved(true);
      setIsEditing(false);
    } catch (error) {
      console.error('Error loading conference:', error);
      alert('Etkinlik yüklenirken bir hata oluştu. Lütfen tekrar deneyin.');
    } finally {
      setIsLoading(false);
    }
  };
  const formatDate = (dateString: string | null) => {
    if (!dateString || dateString === "0") return '';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '';
    const offset = date.getTimezoneOffset();
    const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
    return adjustedDate.toISOString().split('T')[0];
  };

  const isValidDate = (dateString: string) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/; // YYYY-MM-DD formatını kontrol eder
    if (!regex.test(dateString)) {
      return false; // Format geçerli değilse
    }
    const [year, month, day] = dateString.split('-').map(Number);
    if (year > 9999 || year < 1000) {
      return false; // Yıl dört haneli değilse
    }
    const date = new Date(year, month - 1, day);
    return date.getFullYear() === year && date.getMonth() + 1 === month && date.getDate() === day;
  };
  

  const handleConferenceChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = (e.target as HTMLInputElement).checked;
    
    setConference(prevConference => {
      if (!prevConference) return null;
      return {
        ...prevConference,
        [name]: type === 'checkbox' ? checked : (value || null)
      };
    });
    // Clear the error for the field being changed
    setErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
  };

  const validateForm = () => {
    const newErrors: FormErrors = {};
    if (!conference?.name?.trim()) {
      newErrors.name = 'Etkinlik adı gereklidir.';
    }
    if (!conference?.date || !isValidDate(conference.date)) {
      newErrors.date = 'Geçerli bir tarih giriniz. (Örn: dd-MM-YYYY)';
    }
    if (conference?.isDateRange && (!conference.endDate || !isValidDate(conference.endDate))) {
      newErrors.endDate = 'Geçerli bir bitiş tarihi giriniz. (Örn: Örn: dd-MM-YYYY)';
    }
    if (!conference?.location?.trim()) {
      newErrors.location = 'Konum gereklidir.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSaveOrEdit = async () => {
    if (isEditing && conference) {
      if (!validateForm()) {
        return;
      }
      setIsLoading(true);
      setError(null);
      try {
        let conferenceToSave = {
          name: conference.name,
          date: conference.date,
          isDateRange: conference.isDateRange,
          endDate: conference.isDateRange ? conference.endDate : null,
          location: conference.location,
        };
  
        console.log('Saving conference:', conferenceToSave);
  
        let savedConference;
        if (!isSaved) {
          savedConference = await conferenceService.createNewConference(conferenceToSave);
        } else {
          savedConference = await conferenceService.updateConference(conference.id, conferenceToSave);
        }
        
        console.log('Saved conference:', savedConference);
  
        if (savedConference && savedConference.conference) {
          const updatedConference: Conference = {
            ...conference,
            ...savedConference.conference,
            sessions: savedConference.conference.sessions || [],
          };
          
          setConference(updatedConference);
          setIsSaved(true);
          setIsEditing(false);
          setErrors({});
          setShowEditForm(false);
          setShowSuccessAlert(true);
          setTimeout(() => setShowSuccessAlert(false), 3000);
        } else {
          throw new Error('Invalid response from server');
        }
      } catch (error) {
        console.error('Error saving conference:', error);
        if (error.response) {
          console.error('Error response:', error.response.data);
          setError(`Etkinlik kaydedilirken bir hata oluştu: ${error.response.data.message || 'Bilinmeyen hata'}`);
        } else if (error.request) {
          setError('Sunucudan yanıt alınamadı. Lütfen internet bağlantınızı kontrol edin.');
        } else {
          setError(`Bir hata oluştu: ${error.message}`);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsEditing(true);
      setShowEditForm(true);
    }
  };

  const addSession = async () => {
    if (!conference || !conference.id) {
      console.error('Conference or conference ID is missing');
      alert('Etkinlik bilgisi eksik. Lütfen sayfayı yenileyin ve tekrar deneyin.');
      return;
    }

    const sessionNumber = (conference.sessions || []).length + 1;
    const newSession = {
      conference_id: parseInt(conference.id, 10),
      name: `${sessionNumber}. Oturum`,
      description: '',
      expiration_date: null,
      auto_approve_questions: false,
    };

    try {
      console.log('Sending new session data:', newSession);
      const response = await sessionService.createSession(newSession);
      console.log('Server response:', response);

      if (!response || !response.session) {
        throw new Error('Invalid response from server');
      }

      const createdSession = response.session;
      
      setConference(prevConference => {
        if (!prevConference) return null;
        return {
          ...prevConference,
          sessions: [...(prevConference.sessions || []), {
            ...createdSession,
            speakers: [],
            qrCode: `https://conferencex.com/session/${createdSession.id}`,
            password: Math.floor(10000 + Math.random() * 90000).toString(),
          }],
        };
      });

      // alert('Oturum başarıyla eklendi.');
    } catch (error) {
      console.error('Error creating session:', error);
      if (error.response) {
        console.error('Error response:', JSON.stringify(error.response.data));
        alert(`Oturum eklenirken bir hata oluştu: ${error.response.data.message}`);
      } else if (error instanceof Error) {
        alert(`Oturum eklenirken bir hata oluştu: ${error.message}`);
      } else {
        alert('Oturum eklenirken bilinmeyen bir hata oluştu. Lütfen tekrar deneyin.');
      }
    }
  };

  const updateSession = async (sessionId: string, updatedData: any) => {
    if (!conference) return;
  
    try {
      const response = await sessionService.updateSession(sessionId, updatedData);
      const updatedSession = response.data?.session;
  
      if (!updatedSession || typeof updatedSession !== 'object') {
        console.error('Updated session data is missing or invalid in the response');
        throw new Error('Invalid response from server');
      }
  
      setConference(prevConference => {
        if (!prevConference) return null;
        return {
          ...prevConference,
          sessions: prevConference.sessions.map(session =>
            session.id === sessionId 
              ? { 
                  ...session, 
                  ...updatedSession,
                  speakers: session.speakers // Mevcut konuşmacıları koru
                }
              : session
          )
        };
      });
    } catch (error) {
      console.error('Error updating session:', error);
      throw error; // Hatayı yukarı fırlat, böylece çağıran fonksiyon da ele alabilir
    }
  };
  const deleteSession = async (sessionId: string) => {
    if (!conference) return;

    try {
      await sessionService.deleteSession(sessionId);
      setConference(prevConference => {
        if (!prevConference) return null;
        return {
          ...prevConference,
          sessions: prevConference.sessions.filter(session => session.id !== sessionId)
        };
      });
    } catch (error) {
      console.error('Error deleting session:', error);
      alert('Oturum silinirken bir hata oluştu. Lütfen tekrar deneyin.');
    }
  };

  const confirmDeleteSession = (sessionId: string) => {
    setDeleteInfo({ type: 'session', id: sessionId });
    setShowModal(true);
  };

  const handleDelete = () => {
    if (deleteInfo && deleteInfo.type === 'session') {
      deleteSession(deleteInfo.id);
    }
    setShowModal(false);
    setDeleteInfo(null);
  };

  const getConferenceDetails = () => {
    if (!conference) return '';

    const { date, isDateRange, endDate, location } = conference;
    const details: string[] = [];
    if (date) {
      if (isDateRange && endDate) {
        details.push(`${new Date(date).toLocaleDateString()} - ${new Date(endDate).toLocaleDateString()}`);
      } else {
        details.push(new Date(date).toLocaleDateString());
      }
    }
    if (location) {
      details.push(location);
    }
    return details.join(' | ');
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!conference) {
    return <div>No conference data available.</div>;
  }

  return (
     <div className="d-flex align-items-center justify-content-center min-vh-100">
  
      <Card className="w-100 conference-card" style={{ maxWidth: '50rem' }}>
        <Card.Header className="text-center conference-card-header">
          <h2 className="font-weight-bold mt-3">{conference.name || 'Yeni Etkinlik'}</h2>
          <div className="conference-details">{getConferenceDetails()}</div>
        </Card.Header>
        <Card.Body>
          {showSuccessAlert && (
            <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
              Etkinlik başarıyla kaydedildi!
            </Alert>
          )}
          
          <CSSTransition
            in={showEditForm}
            timeout={300}
            classNames="fade"
            unmountOnExit
          >
            <Form>
              <Form.Group controlId="conferenceName" className="mb-3">
                <Form.Label>Etkinlik Adı</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Etkinlik adını girin"
                  name="name"
                  value={conference.name || ''}
                  onChange={handleConferenceChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.name}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="conferenceDate" className="mb-3">
                <Form.Label>Tarih</Form.Label>
                <Form.Control
                  type="date"
                  name="date"
                  value={conference.date || ''}
                  onChange={handleConferenceChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.date}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.date}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="isDateRange" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="Tarih aralığı belirle"
                  name="isDateRange"
                  checked={conference.isDateRange || false}
                  onChange={handleConferenceChange}
                  disabled={!isEditing}
                />
              </Form.Group>
              {conference.isDateRange && (
                <Form.Group controlId="conferenceEndDate" className="mb-3">
                  <Form.Label>Bitiş Tarihi</Form.Label>
                  <Form.Control
                    type="date"
                    name="endDate"
                    value={conference.endDate || ''}
                    onChange={handleConferenceChange}
                    disabled={!isEditing}
                    isInvalid={!!errors.endDate}
                  />
                  <Form.Control.Feedback type="invalid">{errors.endDate}</Form.Control.Feedback>
                </Form.Group>
              )}
              <Form.Group controlId="conferenceLocation" className="mb-3">
                <Form.Label>Konum</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Etkinlik konumunu girin"
                  name="location"
                  value={conference.location || ''}
                  onChange={handleConferenceChange}
                  disabled={!isEditing}
                  isInvalid={!!errors.location}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.location}</Form.Control.Feedback>
              </Form.Group>
            </Form>
          </CSSTransition>
          
          <Button 
            onClick={handleSaveOrEdit} 
            disabled={isLoading}
            className={`mt-3 ${isIOS ? "ios-btn" : ""}`}
          >
            {isEditing ? (
              <><FaSave /> {isSaved ? 'Değişiklikleri Kaydet' : 'Kaydet'}</>
            ) : (
              <><FaEdit /> Düzenle</>
            )}
          </Button>
          
          <div className="mt-4">
  <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mb-3">
    <h3 className="mb-3 mb-md-0">Oturumlar</h3>
    <Button onClick={addSession} disabled={!isSaved} className={isIOS ? "ios-btn" : ""}>
      <FaPlus /> Oturum Ekle
    </Button>
  </div>
  {conference.sessions && conference.sessions.length > 0 ? (
    conference.sessions.map((session, index) => (
      session && session.id ? (
        <Session
          key={session.id}
          session={session}
          updateSession={updateSession}
          deleteSession={confirmDeleteSession}
          zIndex={1000 - index * 1}
        />
      ) : null
    ))
  ) : (
    <p>Henüz oturum bulunmamaktadır.</p>
  )}
</div>
        </Card.Body>
      </Card>

      <ConfirmationModal
        show={showModal}
        onHide={() => setShowModal(false)}
        onConfirm={handleDelete}
        title="Onay"
        body="Bu işlemi gerçekleştirmek istediğinize emin misiniz?"
      />
    </div>
  );
};

export default AdminPanel;