import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Form, Button, Table, Badge, Spinner, Alert, Modal } from 'react-bootstrap';
import dashboardService from '../../services/dashboardService';

interface User {
  id: string;
  name: string;
  email: string;
  role: string;
  createdAt: string;
}

const UserManagement: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [newUserData, setNewUserData] = useState({ name: '', email: '', password: '', role: 'user' });
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [editUserData, setEditUserData] = useState({ name: '', email: '', role: '' });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setLoading(true);
        // Gerçek API'den veri alma
        try {
          const userData = await dashboardService.getAllUsers();
          setUsers(userData);
        } catch (error) {
          console.error("API'den kullanıcılar alınamadı, demo veriler kullanılıyor", error);
          // Demo veriler
          setUsers([
            { id: '1', name: 'Admin Kullanıcı', email: 'admin@example.com', role: 'admin', createdAt: '2023-01-01' },
            { id: '2', name: 'Ali Yılmaz', email: 'ali@example.com', role: 'user', createdAt: '2023-02-15' },
            { id: '3', name: 'Ayşe Kaya', email: 'ayse@example.com', role: 'moderator', createdAt: '2023-03-10' }
          ]);
        }
      } catch (err) {
        setError('Kullanıcılar yüklenirken bir hata oluştu.');
        console.error('Kullanıcı veri yükleme hatası:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCreateUser = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      // API'ye yeni kullanıcı ekleme
      try {
        const newUser = await dashboardService.createUser(newUserData);
        setUsers([...users, newUser]);
        setNewUserData({ name: '', email: '', password: '', role: 'user' });
        alert('Kullanıcı başarıyla oluşturuldu.');
      } catch (error) {
        console.error('Kullanıcı oluşturma API hatası:', error);
        alert('Kullanıcı oluşturulurken bir hata oluştu.');
      }
    } catch (err) {
      console.error('Kullanıcı oluşturma hatası:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (user: User) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    if (!userToDelete) return;
    
    try {
      setLoading(true);
      // Kullanıcı silme API çağrısı
      try {
        await dashboardService.deleteUser(userToDelete.id);
        setUsers(users.filter(user => user.id !== userToDelete.id));
        alert('Kullanıcı başarıyla silindi.');
      } catch (error) {
        console.error('Kullanıcı silme API hatası:', error);
        alert('Kullanıcı silinirken bir hata oluştu.');
      }
    } catch (err) {
      console.error('Kullanıcı silme hatası:', err);
    } finally {
      setShowDeleteModal(false);
      setUserToDelete(null);
      setLoading(false);
    }
  };

  const handleEditClick = (user: User) => {
    setUserToEdit(user);
    setEditUserData({
      name: user.name,
      email: user.email,
      role: user.role
    });
    setShowEditModal(true);
  };

  const handleEditConfirm = async () => {
    if (!userToEdit) return;
    
    try {
      setLoading(true);
      // Kullanıcı güncelleme API çağrısı
      try {
        const updatedUser = await dashboardService.updateUser(userToEdit.id, editUserData);
        setUsers(users.map(user => user.id === userToEdit.id ? {...user, ...updatedUser} : user));
        alert('Kullanıcı başarıyla güncellendi.');
      } catch (error) {
        console.error('Kullanıcı güncelleme API hatası:', error);
        alert('Kullanıcı güncellenirken bir hata oluştu.');
      }
    } catch (err) {
      console.error('Kullanıcı güncelleme hatası:', err);
    } finally {
      setShowEditModal(false);
      setUserToEdit(null);
      setLoading(false);
    }
  };

  if (loading && users.length === 0) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh" }}>
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Yükleniyor...</span>
        </Spinner>
      </div>
    );
  }

  if (error && users.length === 0) {
    return (
      <Alert variant="danger" className="m-3">
        {error}
      </Alert>
    );
  }

  return (
    <div>
      {/* Yeni Kullanıcı Formu */}
      <Row className="mb-4">
        <Col>
          <Card>
            <Card.Header>
              <h5>Yeni Kullanıcı Ekle</h5>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleCreateUser}>
                <Row>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Ad Soyad</Form.Label>
                      <Form.Control 
                        type="text" 
                        value={newUserData.name}
                        onChange={(e) => setNewUserData({...newUserData, name: e.target.value})}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>E-posta</Form.Label>
                      <Form.Control 
                        type="email" 
                        value={newUserData.email}
                        onChange={(e) => setNewUserData({...newUserData, email: e.target.value})}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group className="mb-3">
                      <Form.Label>Şifre</Form.Label>
                      <Form.Control 
                        type="password" 
                        value={newUserData.password}
                        onChange={(e) => setNewUserData({...newUserData, password: e.target.value})}
                        required
                      />
                    </Form.Group>
                  </Col>
                  <Col md={2}>
                    <Form.Group className="mb-3">
                      <Form.Label>Rol</Form.Label>
                      <Form.Select 
                        value={newUserData.role}
                        onChange={(e) => setNewUserData({...newUserData, role: e.target.value})}
                      >
                        <option value="user">Kullanıcı</option>
                        <option value="moderator">Moderatör</option>
                        <option value="speaker">Konuşmacı</option>
                        <option value="admin">Admin</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col md={1} className="d-flex align-items-end">
                    <Button variant="primary" type="submit" className="mb-3">
                      Ekle
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Kullanıcı Listesi */}
      <Card>
        <Card.Header>
          <h5>Kullanıcı Listesi</h5>
        </Card.Header>
        <Card.Body>
          <Table responsive hover>
            <thead>
              <tr>
                <th>ID</th>
                <th>Ad Soyad</th>
                <th>E-posta</th>
                <th>Rol</th>
                <th>Kayıt Tarihi</th>
                <th>İşlemler</th>
              </tr>
            </thead>
            <tbody>
              {users.map(user => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>
                    <Badge bg={
                      user.role === 'admin' ? 'danger' :
                      user.role === 'moderator' ? 'primary' :
                      user.role === 'speaker' ? 'info' : 'secondary'
                    }>
                      {user.role}
                    </Badge>
                  </td>
                  <td>{new Date(user.createdAt).toLocaleDateString('tr-TR')}</td>
                  <td>
                    <Button 
                      variant="outline-primary" 
                      size="sm" 
                      className="me-1"
                      onClick={() => handleEditClick(user)}
                    >
                      Düzenle
                    </Button>
                    <Button 
                      variant="outline-danger" 
                      size="sm"
                      onClick={() => handleDeleteClick(user)}
                    >
                      Sil
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>

      {/* Silme Onay Modalı */}
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcıyı Sil</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><strong>{userToDelete?.name}</strong> adlı kullanıcıyı silmek istediğinize emin misiniz?</p>
          <p>Bu işlem geri alınamaz.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            İptal
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Sil
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Düzenleme Modalı */}
      <Modal
        show={showEditModal}
        onHide={() => setShowEditModal(false)}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Kullanıcıyı Düzenle</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Ad Soyad</Form.Label>
              <Form.Control 
                type="text" 
                value={editUserData.name}
                onChange={(e) => setEditUserData({...editUserData, name: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>E-posta</Form.Label>
              <Form.Control 
                type="email" 
                value={editUserData.email}
                onChange={(e) => setEditUserData({...editUserData, email: e.target.value})}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Rol</Form.Label>
              <Form.Select 
                value={editUserData.role}
                onChange={(e) => setEditUserData({...editUserData, role: e.target.value})}
              >
                <option value="user">Kullanıcı</option>
                <option value="moderator">Moderatör</option>
                <option value="speaker">Konuşmacı</option>
                <option value="admin">Admin</option>
              </Form.Select>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            İptal
          </Button>
          <Button variant="primary" onClick={handleEditConfirm}>
            Kaydet
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserManagement; 